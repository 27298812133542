import React,{ useRef , useState } from 'react';
import {
  Block,
  BlockTitle,
  f7,
  Link,
  NavLeft,
  NavRight,
  NavTitle,
  Navbar,
  Page,
} from 'framework7-react';

import Dom7 from 'dom7';
import ReactDOM from 'react-dom';

import '../css/grid.css'

import haccpUtils from '../js/haccp-utils.js';

const ImportantHygienicCtrlList = () => {
  var $$ = Dom7
  const storage_key = "#USER#important-hygienic-ctrl"
  const storage_key_listSelectId = "#USER#important-list-select-id"
  const storage = haccpUtils.getStorage();
  const actions = useRef(null);
  const onPageBeforeRemove = () => {
    actions.current.destroy();
  };
  const onPageInit = () => {
    actions.current = f7.actions.create({});
    haccpUtils.request(f7, {
      url: '/important_hygienic_ctrl',
      method: "GET",
      contentType: "application/json",
      crossDomain: true,
      headers: {Authorization: "Bearer ..."},
      data: {},
      dataType: "json",
      success: function (body, status, xhr){
        storage.setItem(storage_key, body.data.importantCtrl)
      },
      error: function (xhr, status, message) {
        console.log("error...");
      },      
      complete: function (xhr, status) {
        console.log(xhr, status);
        renderList()
      },
      statusCode: {
        401: function(xhr) {
          console.log(xhr)
        }
      },
    });
  };

  const renderList = () => {
    const importantCtrl = storage.getItemObject(storage_key_listSelectId)
    const child = importantCtrl.child;
    // console.log("child",child);
    const hyw_word = (code) => {return code ==  "1" ? "良" : code ==  "2" ? "否" : "未"};
    const hyw_color = (code) => {return code ==  "1" ? "blue" : code ==  "2" ? "red" : "black"};
    const block_data = (
      <Block className="no-padding">
        <BlockTitle>{child.work_year + "年" + child.work_month + "月　実施記録一覧"}</BlockTitle>
        <div class="card data-table">
          <table>
            <thead>
              <tr>
                <th>分類</th>
                <th class="category-title">{child.header_1_1}</th>
                <th class="category-title">{child.header_1_2}</th>
                <th class="category-title">{child.header_1_3}</th>
                <th class="category-title">{child.header_1_4}</th>
                <th class="category-title">{child.header_1_5}</th>
                <th class="table-daily_check" rowspan="2">日々<br />チェック</th>
                <th class="table-memo" rowspan="2">特記事項</th>
                <th class="table-admin-memo" rowspan="2">確認者</th>
              </tr>
              <tr>
                <th class="menu-title"><span>メニュー</span></th>
                <th class="menu">{child.header_2_1}</th>
                <th class="menu">{child.header_2_2}</th>
                <th class="menu">{child.header_2_3}</th>
                <th class="menu">{child.header_2_4}</th>
                <th class="menu">{child.header_2_5}</th>
              </tr>
            </thead>
            <tbody>
              {child.details.sort((o1, o2) => (o1.work_day - o2.work_day > 0 ? 1 : -1)).map(detail=>
                <tr>
                  <td>{detail.work_day}日</td>
                  <td style = {{color: hyw_color(detail.hygienic_ctrl_work_1)}}>{hyw_word(detail.hygienic_ctrl_work_1)}</td>
                  <td style = {{color: hyw_color(detail.hygienic_ctrl_work_2)}}>{hyw_word(detail.hygienic_ctrl_work_2)}</td>
                  <td style = {{color: hyw_color(detail.hygienic_ctrl_work_3)}}>{hyw_word(detail.hygienic_ctrl_work_3)}</td>
                  <td style = {{color: hyw_color(detail.hygienic_ctrl_work_4)}}>{hyw_word(detail.hygienic_ctrl_work_4)}</td>
                  <td style = {{color: hyw_color(detail.hygienic_ctrl_work_5)}}>{hyw_word(detail.hygienic_ctrl_work_5)}</td>
                  <td>{detail.daily_check}</td>
                  <td>{detail.memo}</td>
                  <td>{detail.admin_memo}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Block>
    )
    haccpUtils.lazyRender("#page重要衛生管理記録実施一覧.page-current form", block_data)
  }
  return (
    <Page id="page重要衛生管理記録実施一覧" onPageBeforeRemove={onPageBeforeRemove} onPageInit={onPageInit} >
      <Navbar className="important">
        <NavLeft>
          <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" />
        </NavLeft>
        <NavTitle style = {{fontSize: "20px"}}>重要実施記録一覧</NavTitle>
        <NavRight>
          <Link href="/home/" view=".view-main" panelClose iconF7="house"></Link>
        </NavRight>
      </Navbar>
      <form></form>
    </Page>
  );
};
export default ImportantHygienicCtrlList;