import React,{ useRef } from 'react';
import {
  Page,
  Navbar,
  NavLeft,
  NavRight,
  NavTitle,
  Block,
  BlockTitle,
  List,
  ListItem,
  f7,
  Row,
  Col,
  AccordionContent, 
  Button,
  ListInput,
  Icon,
  Fab,
  Link,
  Treeview,
  TreeviewItem,
  View,
} from 'framework7-react';

import Dom7 from 'dom7';
import ReactDOM from 'react-dom';
import haccpUtils from '../js/haccp-utils.js';

import '../css/sortable.css';
import '../css/grid.css'

const GeneralCtrlPlanTemplateList = () => {
  var $$ = Dom7
  const storage = haccpUtils.getStorage();
  const storage_key = "#USER#general-ctrl-plan-template"

  const actions = useRef(null);
  const onPageBeforeRemove = () => {
    actions.current.destroy();
  };
  const onPageInit = () => {
    haccpUtils.request(f7, {
      url: '/general_ctrl_plan_templates',
      method: "GET",
      contentType: "application/json",
      crossDomain: true,
      headers: {Authorization: "Bearer ..."},
      data: {},
      dataType: "json",
      success: function (body, status, xhr){
        storage.setItem(storage_key, body.data.generalTemplate)
      },
      error: function (xhr, status, message) {
        console.log("error...");
      },      
      complete: function (xhr, status) {
        console.log(xhr, status);
        renderList()
      },
      statusCode: {
        401: function(xhr) {
          console.log(xhr)
        }
      },
    });
    actions.current = f7.actions.create({});
  };

  const renderList = () => {
    const generalTemplate = storage.getItemObject(storage_key)
    console.log("----generalTemplate-----")
    console.log(generalTemplate)
    console.log("--------------------")
    const block_data = (
      <Block className="no-padding">
        {generalTemplate.map(item =>
          <List>
            {item.children.map(child =>
              <ListItem
                link="#"
                // 今回は、部門を意識しないため表示からも削除
                // header={item.shop_name + ">" + item.department_name + ">" + child.create_dttm}
                header={item.shop_name + ">" + child.create_dttm}
                title={"計画Ver. " + child.plan_version + " テンプレVer. " + child.temp_version}
                onClick={popupOpenEdit({id: child.id, shop_id: child.shop_id, details: child.details})}
                popupOpen="#haccp-popup"
                iconF7="doc_on_clipboard"
                after="詳細"
              >
              </ListItem>
            )}
          </List>
        )}
      </Block>
    )
    haccpUtils.lazyRender("#page一般衛生管理計画テンプレート.page-current form", block_data)
  }
  const storeList = (storeData) => {
    haccpUtils.request(f7, {
      url: '/general_ctrl_plan_template',
      method: "POST",
      contentType: "application/json",
      crossDomain: true,
      headers: {Authorization: "Bearer ..."},
      data: storeData, // TODO 変更中 (変更済) OK
      dataType: "json",
      success: function (body, status, xhr){
        console.log("success...");
        storage.setItem(storage_key, body.data.generalTemplate)
      },
      error: function (xhr, status, message) {
        console.log("error...");
      },      
      complete: function (xhr, status) {
        console.log(xhr, status);
        renderList()
      },
      statusCode: {
        401: function(xhr) {
          console.log(xhr)
        }
      },
    });
  }
  
  const popupUpdate = (params) => {
    return () => {
      var storedData = haccpUtils.convertFormToData(f7, '#haccp-popup form');
      if (storedData) {
        // TODO 更新したい
        // haccpUtils.update??????(f7, storedData)

        var databaseData = storage.getItemObject(storage_key)

        console.log("------あああああああ-----------")
        console.log(storage_key)
        console.log(databaseData)



        var isNew = true

        databaseData[0].children.forEach((child, index) => {
          if(child.id == params.id) {
            isNew = false
            // for( let key in storedData) {
            //   databaseData[0].children[index][key] = storedData[key]
            // }

            // 正規化
            storedData.details=storedData.details.map(detail => {
              // もし、配列だったら、文字列にする
              if (detail.time_element_id && Array.isArray(detail.time_element_id)) {
                detail.time_element_id = detail.time_element_id.join(",")
              }
              return detail;
            })

            databaseData[0].children[index].details = storedData.details
          }
        });
        if(isNew) {
          databaseData[0].children.push(storedData)
          console.log("---isNew------")
        }



        console.log("------databaseData-----------")
        console.log("------ポスト前-----------")
        console.log(databaseData[0].children)
        storeList({template: databaseData[0]})
        storage.setItem(storage_key, databaseData)

        f7.popup.close("#haccp-popup");
      } else {
         alert('Yet there is no stored data for this form. Please try to change any field')
      }
      // 一覧の再描画
      renderList()
    }
  }

  const popupOpen = (popupElements, params) => {
    // 正規化
    params.details = params.details.map(detail => {
      if (detail.time_element_id && typeof detail.time_element_id === 'string') {
        detail.time_element_id = detail.time_element_id.split(",")
      }
      return detail;
    })

    console.log("------popupOpen-----------")
    console.log(popupElements)
    console.log(params)

    var popup = $$("#haccp-popup")
    var view=(
      <View>
        <Page>
          <Navbar title={popupElements.title}>
            <NavRight>
              <Link popupClose iconF7="xmark"></Link>
            </NavRight>
          </Navbar>
          <form></form>
        </Page>
      </View>
    )
    ReactDOM.render(view, popup[0])

    var form=$$("#haccp-popup form")

    const timeElementOptions = storage.getItemObject("#USER#timeElementOptions");
    const itemOptions = storage.getItemObject("#USER#itemOptions");
    const shopOptions = storage.getItemObject("#USER#shopOptions");
    var block = (
      <Block>
        <input type="hidden" name="id" value={params.id} />
        <input type="hidden" name="department_id" value={params.department_id} />
        <input type="hidden" name="plan_version" value={params.plan_version} />
        <input type="hidden" name="temp_version" value={params.temp_version} />

        {(params["id"] == "?") ? (
          <div>
            <p>作成する店舗を選んで下さい</p>
            <List>
              <ListItem title="店舗"
                smartSelect
                smartSelectParams={{
                  openIn: 'popup',
                  popupCloseLinkText: '❌',
                }}
              >
                <select name="shop_id">
                  {shopOptions.map(option=> 
                    <option value={option.value}>{option.text}</option>
                  )}
                </select>
              </ListItem>
            </List>
          </div>
        ) : (
          <div>
          </div>
        )}

      <List sortable sortableOpposite>
        {params.details.map((detail, idx)=>
          <Block>
                <input type="hidden" name={"details["+idx+"].id"} value={detail.id} />
                <input type="hidden" name={"details["+idx+"].item_id"} value={detail.item_id} />
                <input type="hidden" name={"details["+idx+"].item_no"} value={detail.item_no} />
                <input type="hidden" name={"details["+idx+"].item_name"} value={detail.item_name} />
            <List noHairlinesMd>
              <ListItem
                title={"項目" + (idx+1)}
                smartSelect
                smartSelectParams={{
                  openIn: 'popup',
                  popupCloseLinkText: '❌',
                }}
              >
                <select name={"details["+idx+"].item_id"} defaultValue={detail.item_id}>
                  {itemOptions.map(option=> 
                    <option value={option.value}>{option.text}</option>
                  )}
                </select>
              </ListItem>
              <ListItem accordionItem title={"項目" + (idx+1) + "詳細"}>
                <AccordionContent>
                  <ListItem
                    title="いつ"
                    smartSelect
                    smartSelectParams={{
                      openIn: 'popup',
                      searchbar: true,
                      searchbarPlaceholder: '検索キー',
                      popupCloseLinkText: '❌',
                    }}
                  >
                    <select name={"details["+idx+"].time_element_id"} multiple defaultValue={detail.time_element_id}>
                    {timeElementOptions.map(option=> 
                      <option value={option.value}>{option.text}</option>
                    )}
                    </select>
                  </ListItem>
                  <ListInput
                    outline
                    label="その他"
                    type="textarea"
                    name={"details["+idx+"].other_description"}
                    placeholder=""
                    defaultValue={detail.other_description}
                    maxlength="350"
                    required
                  >
                  </ListInput>
                  <ListInput
                    outline
                    label="どのように"
                    type="textarea"
                    name={"details["+idx+"].method_element"}
                    placeholder=""
                    defaultValue={detail.method_element}
                    maxlength="400"
                    required
                  >
                  </ListInput>
                  <ListInput
                    outline
                    label="問題があったとき"
                    type="textarea"
                    name={"details["+idx+"].problem_occured"}
                    placeholder=""
                    defaultValue={detail.problem_occured}
                    placeholder=""
                    maxlength="400"
                    required
                  >
                  </ListInput>
                </AccordionContent>
              </ListItem>
            </List>
          </Block>
        )}
        <p>{popupElements.message}</p>
        <Row>
          <Col>
          <Button onClick={popupElements.submit(params)} fill>{popupElements.button}</Button>
            </Col>
        </Row>
      </List>
    </Block>


    )
    ReactDOM.render(block, form[0])

  }

  const popupOpenEdit = (params) => {
    console.log("popupOpenEdit")
    console.log(params)

    return () => {
      var databaseData = storage.getItemObject(storage_key)
      var updateData = databaseData.filter((children) => {return children.shop_id == params.shop_id})

      var children = updateData[0].children.filter((child) => {return child.id == params.id})

      console.log("popupOpenEdit:open")
      console.log([params, children])

      return popupOpen(
        {
          title: "一般衛生管理テンプレート及び計画を編集する",
          message: "一般衛生管理テンプレート及び計画の修正を完了します。",
          button: "修正する",
          submit: () => {
            return popupUpdate(children[0])
          }
        },
        params
      )
    }
  }

  const popupOpenNew = () => {
    console.log("popupOpenNew")
    const defaultGeneralTempleteValid = storage.getItemObject("#USER#defaultGeneralTempleteValid")
    console.log(defaultGeneralTempleteValid)
    const params = {
      id: "?",
      details: defaultGeneralTempleteValid
    }

    return popupOpen(
      {
        title: "一般衛生管理計画テンプレートを作成する",
        message: "一般衛生管理テンプレート及び計画の登録を完了します。",
        button: "登録",
        submit: (params) => {
          return popupUpdate(params)
        },
      }, params
    )
  }

  return (
    <Page id="page一般衛生管理計画テンプレート" onPageBeforeRemove={onPageBeforeRemove} onPageInit={onPageInit} >
      <Fab position="right-top" slot="fixed" color="teal">
        <Button fill popupOpen="#haccp-popup" onClick={popupOpenNew}>
          <Icon ios="f7:plus_square_on_square" aurora="f7:fiber_new" md="material:fiber_new" />
        </Button>
      </Fab>
      <Navbar className="general"> 
        <NavLeft>
          <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" />
        </NavLeft>
        <NavTitle style = {{fontSize: "20px"}}>一般衛生管理計画ﾃﾝﾌﾟﾚｰﾄ</NavTitle>
        <NavRight>
          <Link href="/home/" view=".view-main" panelClose iconF7="house" color="red"></Link>
        </NavRight>
      </Navbar>
      <BlockTitle>一覧</BlockTitle>
      <form></form>
    </Page>
  );
};
export default GeneralCtrlPlanTemplateList;



