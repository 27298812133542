import React, { useRef } from 'react';
import {
  Page,
  Navbar,
  Block,
  List,
  f7,
  ListItem,
  ListButton,
  ListInput,
  Treeview,
  TreeviewItem,
  NavLeft,
  NavTitle,
  Link,
} from 'framework7-react';

import Dom7 from 'dom7';
import ReactDOM from 'react-dom';

import '../css/sortable.css';
import '../css/grid.css'

import haccpUtils from '../js/haccp-utils.js';

const ShopMstMaintenance = () => {
  var $$ = Dom7

  const storage = haccpUtils.getStorage();
  const actions = useRef(null);

  const storage_key = "#USER#shop-mst-maintenance"

  const onPageBeforeRemove = () => {
    actions.current.destroy();
  };
  const onPageInit = () => {
    actions.current = f7.actions.create({});
    searchAll()
  };

  const searchAll = () => {
    haccpUtils.request(f7, {
      url: '/select_shop_Data',
      method: "GET",
      contentType: "application/json",
      crossDomain: true,
      headers: {Authorization: "Bearer ..."},
      data: {},
      dataType: "json",
      success: function (body, status, xhr){
        renderInit(body.data.selectShopData)
        storage.setItem(storage_key, body.data.selectShopData)
      },
      error: function (xhr, status, message) {
        console.log("error...");
      },      
      complete: function (xhr, status) {
        console.log(xhr, status);
      },
      statusCode: {
        401: function(xhr) {
          console.log(xhr)
        }
      },
    });
  }

  const renderInit = (shopMstMaintenance) => {
    const block_data = (
      <div id="shopSearchResult" class="card data-table search-data">
        <form>
          <table>
            <thead>
              <tr>
                <th class=""></th>
                <th class="font13 width120">店舗名</th>
                <th class="font13 width120">所属アカウント数</th>
                <th class="font13 width120">計画・記録登録数</th>
                <th class="font13 width120">店舗作成日時</th>
                {/* 
                <th class="font13 width120">備考</th>
                <th class="font13 width120">作成者</th>
                <th class="font13 width120">更新日時</th>
                <th class="font13 width120">更新者</th>
                <th class="font13 width30">削除フラグ</th>
                <th class="font13 width30">一般管理テンプレート登録数</th>
                <th class="font13 width30">一般管理記録数</th>
                <th class="font13 width30">一般管理計画数</th>
                <th class="font13 width30">重要管理テンプレート登録数</th>
                <th class="font13 width30">重要管理記録数</th>
                <th class="font13 width30">重要管理計画数</th>
                  */}
              </tr>
            </thead>
            <tbody>
            {shopMstMaintenance.filter((shop) => {
              var visible = false
              var storedData = f7.form.convertToData('#shopMstMaintenance form');
              console.log(shop.create_dttm)
              console.log(storedData.create_from_date)
              console.log(storedData.create_to_date)
              var target = new Date()
              console.log("----------")
              if(shop.create_dttm != null && shop.create_dttm != "") {
                target = haccpUtils.getToday("yyyymmdd", new Date(shop.create_dttm.split(' ')[0]))
                console.log(target)
              }
              var from = ""
              if(storedData.create_from_date != null && storedData.create_from_date != "") {
                from = haccpUtils.getToday("yyyymmdd", new Date(storedData.create_from_date))
                console.log(from)
              }
              var to = ""
              if(storedData.create_to_date != null && storedData.create_to_date != "") {
                to = haccpUtils.getToday("yyyymmdd", new Date(storedData.create_to_date))
                console.log(to)
              }
              console.log("----------")
              // 条件を満たしていればtrueを返す
              console.log(storedData)
              if (storedData.name.length == 0 || (storedData.name.length > 0 && ~shop.name.indexOf(storedData.name))) {
                if (storedData.account_check.length == 0 || storedData.account_check == "0" || (storedData.account_check == "1" && shop.account_count > 0) || (storedData.account_check == "2" && shop.account_count == 0)) {
                  if (storedData.create_from_date.length == 0 || storedData.create_from_date == null || storedData.create_from_date == "" || from <= target ) {
                    if (storedData.create_to_date.length == 0 || storedData.create_to_date == null || storedData.create_to_date == "" || to >= target ) {
                      // 管理アカウントは削除禁止
                      if(shop.id != 1 && shop.id != 2) {
                        visible = true
                      }
                    }
                  }
                }
              }
              return visible
            }).map((shop, idx)=>
              <tr style = {{color: (shop.account_count > 0) ? "red" : ""}}>
                <td class="">
                  {/* <input type="checkbox" name="checkShopID" value={shop.id}/> */}
                  {/* <i class="icon icon-checkbox"></i> */}
                  <input type="checkbox" name={"shops["+idx+"].checked"} value={true}/>
                  <input type="hidden" name={"shops["+idx+"].account_count"} value={shop.account_count}/>
                  <input type="hidden" name={"shops["+idx+"].tran_count"} value={shop.tran_count}/>
                  <input type="hidden" name={"shops["+idx+"].id"} value={shop.id}/>
                </td>
                <td class="width120">{shop.name}</td>
                <td class="width120">{shop.account_count}</td>
                <td class="width30">{shop.tran_count}</td>
                <td class="width120">{shop.create_dttm}</td>
                {/* 
                <td class="width120">{shop.remark}</td>
                <td class="width120">{shop.create_acct_id}</td>
                <td class="width120">{shop.upd_dttm}</td>
                <td class="width120">{shop.upd_acct_id}</td>
                <td class="width120">{shop.del_flg}</td>
                <td class="width30">{shop.general_ctrl_temp_count}</td>
                <td class="width30">{shop.general_hygienic_ctrl_count}</td>
                <td class="width30">{shop.general_hygienic_ctrl_plan_count}</td>
                <td class="width30">{shop.important_ctrl_temp_count}</td>
                <td class="width30">{shop.important_hygienic_ctrl_count}</td>
                <td class="width30">{shop.important_hygienic_ctrl_plan_count}</td>
                  */}
              </tr>
            )}
            </tbody>
          </table>
          <List>
            <ListButton className="danger"
              title="削除する"
              onClick={()=> {
                var storedData = haccpUtils.convertFormToData(f7, "#shopSearchResult form")
                console.log(storedData)
                var account_exist_count = 0
                var not_account_exist_count = 0
                var checkedShops = storedData.shops
                .filter((shop)=>shop.checked[0])
                .map((shop)=>{
                    if(shop.account_count > 0){
                      account_exist_count++
                    }else{
                      not_account_exist_count++
                    }
                    return shop
                }) 

                if(checkedShops.length > 0) {
                  console.log(storedData)
                  console.log(checkedShops)
                  f7.dialog.create({
                    text: (account_exist_count > 0) ? '<p style="color: red">本当に削除しますか？</p>' : '本当に削除しますか?',
                    title: '<p style="color: red">アカウント有店舗:' + account_exist_count + '件</p><p>アカウント無店舗:' + not_account_exist_count + '件</p>',
                    buttons: [
                      {
                        text: 'はい',
                        onClick: function () {
                          deleteProc(checkedShops);
                        },
                      },
                      {
                        text: 'いいえ',
                      },
                    ],
                  }).open();
                }
              }}
            />
          </List>
        </form>
      </div>
    )
    var block = $$("#shopMstMaintenance .mst-list")
    ReactDOM.render(block_data, block[0])

    var blankList = {}
    shopMstMaintenance.map((shop, idx) => {
      blankList["shops["+idx+"].checked"] = []
    })
    console.log("blankList")
    console.log(blankList)
    f7.form.fillFromData("#shopSearchResult form",blankList)
  }

  const deleteProc = (shops) => {
    console.log(shops)
    if(shops.length) {
      console.log("OK!")
      haccpUtils.request(f7, {
        url: '/delete_shop_data',
        method: "POST",
        contentType: "application/json",
        crossDomain: true,
        headers: {Authorization: "Bearer ..."},
        data: {shops: shops},
        dataType: "json",
        success: function (body, status, xhr){
          console.log("success...");
          console.log(body.data.result);
          if(body.data.result == true){
            searchAll()
          }
        },
        error: function (xhr, status, message) {
          console.log("error...");
        },      
        complete: function (xhr, status) {
          console.log(xhr, status);
        },
        statusCode: {
          401: function(xhr) {
            console.log(xhr)
          }
        },
      });

      // storage.setItem(storage_key, databaseData)
    }
  }

  return (
    <Page onPageBeforeRemove={onPageBeforeRemove} onPageInit={onPageInit} >
      <Navbar className="mst">
        <NavLeft>
          <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" />
        </NavLeft>
        <NavTitle style = {{fontSize: "20px"}}>店舗マスタメンテ</NavTitle>
        {/* <NavRight>
          <Link href="/home/" view=".view-main" panelClose iconF7="house"></Link>
        </NavRight> */}
      </Navbar>
      <Block id="shopMstMaintenance">
        <form onSubmit={haccpUtils.handleSubmitCancel}>
          <Treeview>
            <TreeviewItem className="font13" label="検索" iconF7="search">
              <List noHairlinesMd className="p-top">
                <ListInput
                  outline
                  className="font13 search-title search-title-input"
                  label="店舗名"
                  type="text"
                  name="name"
                  placeholder="店舗名"
                  maxlength="255"
                ></ListInput>
                <ListInput
                  outline
                  className="font13 search-title search-title-select"
                  label="所属アカウント有無"
                  type="select"
                  name="account_check"
                  defaultValue="2"
                >
                  <option value="0">全て</option>
                  <option value="1">所属アカウント有</option>
                  <option value="2">所属アカウント無</option>
                </ListInput>
                <ListInput
                  outline
                  className="font13 search-title search-title-date"
                  label="登録日付（開始）"
                  type="date"
                  name="create_from_date"
                  placeholder=""
                ></ListInput>
                <ListInput
                  outline
                  className="font13 search-title search-title-date"
                  label="登録日付（終了）"
                  type="date"
                  name="create_to_date"
                  placeholder=""
                ></ListInput>
              </List>
              <List>
                <ListButton
                  title="検索する"
                  onClick={()=> {
                    const selectShopData = storage.getItemObject(storage_key)
                    renderInit(selectShopData)
                  }}
                />
              </List>
            </TreeviewItem>
          </Treeview>
        </form>
        <Block strong className="no-padding mst-list"></Block>
      </Block>
    </Page>
  );
};
export default ShopMstMaintenance;
