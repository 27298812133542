import React, { useRef } from 'react';
import {
  Page,
  Navbar,
  Block,
  List,
  f7,
  Row,
  Col,
  Button,
  ListInput,
  NavRight,
  Link,
  ListButton,
  ListItem,
  View,
  ListItemRow,
  ListItemCell,
} from 'framework7-react';

import Dom7, { click } from 'dom7';
import ReactDOM from 'react-dom';

import '../css/sortable.css';
import '../css/grid.css'

import haccpUtils from '../js/haccp-utils.js';

const UserMst = () => {
  var $$ = Dom7

  const storage = haccpUtils.getStorage();
  const actions = useRef(null);

  const storage_key = "#USER#user-mst"

  const onPageBeforeRemove = () => {
    actions.current.destroy();
  };
  const onPageInit = () => {
    actions.current = f7.actions.create({});
    haccpUtils.request(f7, {
      url: '/user_mst',
      method: "GET",
      contentType: "application/json",
      crossDomain: true,
      headers: {Authorization: "Bearer ..."},
      data: {},
      dataType: "json",
      success: function (body, status, xhr){
        renderInit(body.data.userMst)
        storage.setItem(storage_key, body.data.userMst)
      },
      error: function (xhr, status, message) {
        console.log("error...");
      },      
      complete: function (xhr, status) {
        console.log(xhr, status);
      },
      statusCode: {
        401: function(xhr) {
          console.log(xhr)
        }
      },
    });

    actions.current = f7.actions.create({});
  };

  const moveToHome = () => {$$('a.tab-link[data-tab="#view-home"]').click()}

  const renderInit = (userMst) => {
    let account = haccpUtils.getLoginAccount();

    const shopMst = storage.getItemObject("#USER#shopOptions");
    var shop_name = "";
    shopMst.filter((shop) => { return shop["value"] == account["shop_id"];}).map(shop => {shop_name = shop.text});

    const block_data = (
      <Block>
        {userMst.filter((user) => { return user["id"] == account["user"]["id"];}).map(user =>
          <div>
            <List noHairlinesMd>
              <ListInput
                label="店舗名"
                type="text"
                defaultValue={shop_name}
                readonly={true}
              ></ListInput>
            </List>
            <List noHairlinesMd>
              {/* <ListInput
                label="ユーザID"
                type="text"
                defaultValue={user.id}
                readonly={true}
              ></ListInput> */}
              <ListInput
                label="メールアドレス"
                type="email"
                defaultValue={user.email}
                readonly={true}
              ></ListInput>
              <ListInput
                label="パスワード"
                type="password"
                defaultValue="***************"
                // defaultValue={user.password}
                readonly={true}
              ></ListInput>
              <ListInput
                label="郵便番号"
                type="text"
                defaultValue={user.zip}
                readonly={true}
              ></ListInput>
              <ListInput
                label="住所"
                type="textarea"
                defaultValue={user.addr}
                readonly={true}
              ></ListInput>
              <ListInput
                label="姓"
                type="text"
                defaultValue={user.last_name}
                readonly={true}
              ></ListInput>
              <ListInput
                label="名"
                type="text"
                defaultValue={user.first_name}
                readonly={true}
              ></ListInput>
              <ListInput
                label="姓（カナ）"
                type="text"
                defaultValue={user.last_name_kana}
                readonly={true}
              ></ListInput>
              <ListInput
                label="名（カナ）"
                type="text"
                defaultValue={user.first_name_kana}
                readonly={true}
              ></ListInput>
              <ListInput
                label="年齢"
                type="text"
                defaultValue={user.age}
                readonly={true}
              ></ListInput>
              <ListInput
                label="電話番号"
                type="tel"
                defaultValue={user.tel}
                readonly={true}
              ></ListInput>
              <ListInput
                label="FAX番号"
                type="tel"
                defaultValue={user.fax}
                readonly={true}
              ></ListInput>
              <ListInput
                label="携帯番号"
                type="tel"
                defaultValue={user.mobile}
                readonly={true}
              ></ListInput>
              {/* <ListInput
                label="備考"
                type="textarea"
                defaultValue={user.remark}
                readonly={true}
              ></ListInput> */}
              <ListButton
                popupOpen="#haccp-popup"
                onClick={popupOpen({
                  title: "編集する",
                }, user)}
              >
                編集する
              </ListButton>
              </List>

              <List noHairlinesMd className="user-withdrawal">
                <ListButton
                  onClick={(e)=>{
                    if (!haccpUtils.validateForm($$(e.target).closest("form"), e.target)) {
                      return false;
                    }
    
                    f7.dialog.create({
                      text: "本当に退会してもよろしいですか？<br>"
                      + "退会をすると、全てのデータが永久に削除されます。<br>"
                      + "もし今後使用する可能性が少しでもある場合は、退会せずデータを残しておく事をおすすめします。<br><br>"
                      + "退会後にデータの復旧は出来ません。<br><br>"
                      + '<input id="userWithdrawalCheckbox" type="checkbox">上記に同意して退会する<br>'
                      + "<p id='userWithdrawalCheckboxMessage'></p>",
                      title: "最終確認",
                      buttons: [
                        {
                          close: true,
                          text: "もう少し使う",
                        },
                        {
                          close: false,
                          text: '退会する',
                          onClick: function (dialog, e) {
                            if($$("#userWithdrawalCheckbox")[0].checked){
                              dialog.close()
                              userWithdrawal(user)
                              moveToHome()
                              haccpUtils.logout(f7)
                            }else{
                              $$("#userWithdrawalCheckboxMessage")[0].innerText='退会する場合は、チェックボックスをチェックしてください。'
                              $$("#userWithdrawalCheckbox").on("click", ()=>$$("#userWithdrawalCheckboxMessage")[0].innerText='')
                              // 下の書き方も同じ
                              // $$("#userWithdrawalCheckbox").onClick(()=>$$("#userWithdrawalCheckboxMessage")[0].innerText='')
                            }
                          },
                        },
                      ],
                    }).open();
                  }}
                >
                  退会する
                </ListButton>
                <ListItem className="user-withdrawal-msg" title="退会すると、全てのデータが削除され、当アプリが使用出来なくなります。"></ListItem>
              </List>



            {/* <form>
              <List noHairlinesMd className="user-withdrawal">
                <ListItem className="user-withdrawal-msg" title="退会すると、全てのデータが削除され、当アプリが使用出来なくなります。"></ListItem>
                <ListItem className="terms-checkbox">
                  <ListInput
                      className="terms-checkbox-error-message terms-checkbox-title"
                      type="checkbox"
                      name="terms_checkbox_msg"
                      required
                      validateOnBlur
                      label="上記に同意して退会する"
                  ></ListInput>
                </ListItem>
                <ListButton
                  onClick={(e)=>{
                    if (!haccpUtils.validateForm($$(e.target).closest("form"), e.target)) {
                      return false;
                    }
    
                    f7.dialog.create({
                      text: "本当に退会してもよろしいですか？<br>"
                      + "退会をすると、全てのデータが永久に削除されます。<br>"
                      + "もし今後使用する可能性が少しでもある場合は、退会せずデータを残しておく事をおすすめします。<br><br>"
                      + "退会後にデータの復旧は出来ません。<br>"
                      + "<input id='aaa' type='checkbox'>aaa",
                      title: "最終確認",
                      buttons: [
                        {
                          text: "もう少し使う",
                        },
                        {
                          text: '退会する',
                          onClick: function () {
                            userWithdrawal(user)
                            moveToHome()
                            haccpUtils.logout(f7)
                          },
                        },
                      ],
                    }).open();
                  }}
                >
                  退会する
                </ListButton>
              </List>
            </form> */}
          </div>
        )}
      </Block>
    )
    var block = $$("#userMstList")
    ReactDOM.render(block_data, block[0])
  }

  const popupClose = () => {
    var storedData = f7.form.convertToData('#haccp-popup form');
    if(storedData) {
      var databaseData = storage.getItemObject(storage_key)
      console.log(storedData)

      var isNew = true
      for(let idx in databaseData) {
        if(databaseData[idx].id == storedData.id) {
          databaseData[idx] = storedData
          isNew = false
        }
      }
      if(isNew) {
        databaseData.push(storedData)
      }

      // ローディング状態にする。
      var block = $$("#userMstList")
      ReactDOM.render(<Block>読み込み中...</Block>, block[0])

      haccpUtils.request(f7, {
        url: '/user_mst',
        method: "POST",
        contentType: "application/json",
        crossDomain: true,
        headers: {Authorization: "Bearer ..."},
        data: {userMst: storedData},
        dataType: "json",
        success: function (body, status, xhr){
          console.log("success...");
          storage.setItem(storage_key, body.data.result)
        },
        error: function (xhr, status, message) {
          console.log("error...");
        },      
        complete: function (xhr, status) {
          console.log(xhr, status);
          const result = storage.getItemObject(storage_key)
          renderInit(result)
        },
        statusCode: {
          401: function(xhr) {
            console.log(xhr)
          }
        },
      });

      storage.setItem(storage_key, databaseData)
      f7.popup.close("#haccp-popup");
    } else {
        alert('Yet there is no stored data for this form. Please try to change any field')
    }
  }




  const userWithdrawal = (user) => {
    if(user) {
      console.log(user)
      // ローディング状態にする。
      var block = $$("#userMstList")
      ReactDOM.render(<Block>読み込み中...</Block>, block[0])

      haccpUtils.request(f7, {
        url: '/user_withdrawal',
        method: "POST",
        contentType: "application/json",
        crossDomain: true,
        headers: {Authorization: "Bearer ..."},
        data: {},
        dataType: "json",
        success: function (body, status, xhr){
          console.log("success...");
          // storage.setItem(storage_key, body.data.result)
        },
        error: function (xhr, status, message) {
          console.log("error...");
        },      
        complete: function (xhr, status) {
          console.log(xhr, status);
          // const result = storage.getItemObject(storage_key)
          // renderInit(result)
        },
        statusCode: {
          401: function(xhr) {
            console.log(xhr)
          }
        },
      });

      f7.popup.close("#haccp-popup");
    } else {
        alert('Yet there is no stored data for this form. Please try to change any field')
    }
  }





  const popupOpen = (popupElements, user) => {
    return () => {
      var view = (
        <View>
          <Page>
            <Navbar title={popupElements.title}>
              <NavRight>
                <Link popupClose iconF7="xmark"></Link>
              </NavRight>
            </Navbar>
            <form></form>
          </Page>
        </View>
      )

      var block = (
        <Block>
        <List noHairlinesMd>
          <input type="hidden" name="id" value={user.id} />
          <ListInput
            outline
            label="メール"
            type="email"
            name="email"
            placeholder="メール"
            validate
            data-error-message="入力できるのは数値のみです"
            defaultValue={user.email}
            required
          >
          </ListInput> 

          <ListItemCell> 
            このアドレスは管理のために使用されます。変更すると、確認のため新しいアドレスにメールを送信します。新しいアドレスは確認が完了するまで有効化されません。
          </ListItemCell>

          <ListInput
            outline
            label="郵便番号"
            type="text"
            name="zip"
            placeholder="郵便番号 1060032(- なし)"
            validate
            pattern="[0-9]*"
            data-error-message="入力できるのは数値のみです"
            defaultValue={user.zip}
            maxlength="7"
          ></ListInput>
          <ListInput
            outline
            label="住所"
            type="textarea"
            name="addr"
            placeholder="住所"
            defaultValue={user.addr}
            maxlength="255"
          ></ListInput>
          <ListInput
            outline
            label="姓"
            type="text"
            name="last_name"
            placeholder="姓"
            defaultValue={user.last_name}
            maxlength="100"
          ></ListInput>
          <ListInput
            outline
            label="名"
            type="text"
            name="first_name"
            placeholder="名"
            defaultValue={user.first_name}
            maxlength="100"
          ></ListInput>
          <ListInput
            outline
            label="姓（カナ）"
            type="text"
            name="last_name_kana"
            placeholder="姓（カナ）"
            defaultValue={user.last_name_kana}
            maxlength="100"
          ></ListInput>
          <ListInput
            outline
            label="名（カナ）"
            type="text"
            name="first_name_kana"
            placeholder="名（カナ）"
            defaultValue={user.first_name_kana}
            maxlength="100"
          ></ListInput>
          <ListInput
            outline
            label="年齢"
            type="text"
            name="age"
            placeholder="年齢"
            validate
            pattern="[0-9]*"
            data-error-message="入力できるのは数値のみです"
            defaultValue={user.age}
            maxlength="3"
          ></ListInput>
          <ListInput
            outline
            label="電話番号"
            type="tel"
            name="tel"
            placeholder="電話番号"
            defaultValue={user.tel}
            maxlength="20"
          ></ListInput>
          <ListInput
            outline
            label="FAX番号"
            type="tel"
            name="fax"
            placeholder="FAX番号"
            defaultValue={user.fax}
            maxlength="20"
          ></ListInput>
          <ListInput
            outline
            label="携帯番号"
            type="tel"
            name="mobile"
            placeholder="携帯番号"
            defaultValue={user.mobile}
            maxlength="20"
          ></ListInput>
          {/* <ListInput
            outline
            label="備考"
            type="textarea"
            name="remark"
            placeholder="備考"
            defaultValue={user.remark}
            maxlength="200"
          ></ListInput>       */}
          <ListButton onClick={popupClose}>修正を完了して保存する</ListButton>
        </List>
      </Block>
      )
      ReactDOM.render(view, document.getElementById("haccp-popup"))
      var form = $$("#haccp-popup form")
      ReactDOM.render(block, form[0])
      return null
    }
  }

  return (
    <Page onPageBeforeRemove={onPageBeforeRemove} onPageInit={onPageInit} >
      <Navbar className="mst" title="会員情報" backLink=" "/>
      <Block id="userMstList"></Block>
    </Page>
  );
};
export default UserMst;
