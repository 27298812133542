import React,{ useRef , useState } from 'react';
import {
  Page,
  Navbar,
  Block,
  BlockTitle,
  BlockHeader,
  List,
  ListItem,
  SwipeoutActions,
  f7,
  Row,
  Col,
  AccordionContent, 
  Button,
  ListInput,
  SwipeoutButton,
  Icon,
  Fab,
  div,
  NavRight,
  Link,
  openAlert,
  Treeview,
  TreeviewItem,
  View,
  Popup,

} from 'framework7-react';

const ImportantHygienicCtrlCalendar = () => {
  const calendarInline = useRef(null);
  const popup = useRef(null);
  const onPageBeforeRemove = () => {
    calendarInline.current.destroy();
    if(popup.current) popup.current.destroy();
  };

  const onPageInit = () => {
    actions.current = f7.actions.create({});
    // Inline with custom toolbar
    const $ = f7.$;
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      '8月',
      'September',
      'October',
      'November',
      'December',
    ];
    calendarInline.current = f7.calendar.create({
      containerEl: '#demo-calendar-inline-container',
      value: [new Date()],
      renderToolbar() {
        return `
          <div class="toolbar calendar-custom-toolbar no-shadow">
            <div class="toolbar-inner">
              <div class="">
              </div>
              <div class="center"></div>
              <div class="">
              </div>
            </div>
          </div>
        `.trim();
      },
      // renderToolbar() {
      //   return `
      //     <div class="toolbar calendar-custom-toolbar no-shadow">
      //       <div class="toolbar-inner">
      //         <div class="left">
      //           <a href="#" class="link icon-only"><i class="icon icon-back"></i></a>
      //         </div>
      //         <div class="center"></div>
      //         <div class="right">
      //           <a href="#" class="link icon-only"><i class="icon icon-forward"></i></a>
      //         </div>
      //       </div>
      //     </div>
      //   `.trim();
      // },
      on: {
        init(c) {
          $('.calendar-custom-toolbar .center').text(
            `${c.currentYear}年${monthNames[c.currentMonth]} `,
          );
          // $('.calendar-custom-toolbar .left .link').on('click', () => {
          //   calendarInline.current.prevMonth();
          // });
          // $('.calendar-custom-toolbar .right .link').on('click', () => {
          //   calendarInline.current.nextMonth();
          // });
          $('.calendar-day').on('click', (event) => {
            let targetDay = $(event.currentTarget).attr("data-day");
            let targetDate = $(event.currentTarget).attr("data-date");
            let currentDate = $('.calendar-day.calendar-day-today').attr("data-date");
            if(new Date(currentDate) >= new Date(targetDate)){
                popup.current = f7.popup.create({
                  content: `
                    <div class="popup">
                      <div class="page">
                        <div class="navbar">
                          <div class="navbar-bg"></div>
                          <div class="navbar-inner">
                            <div class="title">${targetDay}日</div>
                            <div class="right"><a href="#" class="link popup-close">Close</a></div>
                          </div>
                        </div>
                        <div class="page-content">
                          <div class="list">	
                          <ul>
                            <li class="">
                              <div class="item-content">
                                <div class="item-inner">
                                  <div class="item-title">①原材料の受入の確認</div>
                                  <div class="item-after"><span>良</span></div>
                                </div>
                              </div>
                            </li>
                            <li class="">
                              <div class="item-content">
                                <div class="item-inner">
                                  <div class="item-title">②庫内温度の確認（冷蔵庫・冷凍庫）</div>
                                  <div class="item-after"><span>16℃、-6℃</span></div>
                                </div>
                              </div>
                            </li>
                            <li class="">
                              <div class="item-content">
                                <div class="item-inner">
                                  <div class="item-title">③-１.交差汚染・二時汚染の防止</div>
                                  <div class="item-after"><span>良</span></div>
                                </div>
                              </div>
                            </li>
                            <li class="">
                              <div class="item-content">
                                <div class="item-inner">
                                  <div class="item-title">③-2.器具等の洗浄・消毒・殺菌</div>
                                  <div class="item-after"><span>否</span></div>
                                </div>
                              </div>
                            </li>
                            <li class="">
                              <div class="item-content">
                                <div class="item-inner">
                                  <div class="item-title">③-3.トイレの洗浄・消毒</div>
                                  <div class="item-after"><span>良</span></div>
                                </div>
                              </div>
                            </li>
                            <li class="">
                              <div class="item-content">
                                <div class="item-inner">
                                  <div class="item-title">④-１.従業員の健康管理等</div>
                                  <div class="item-after"><span>良</span></div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
          
                          </div>
                      </div>
                    </div>
                  `.trim(),
                });  
              // Open it
              popup.current.open();
            }else{
              popup.current = f7.popup.create({
                content: `
                  <div class="popup">
                    <div class="page">
                      <div class="navbar">
                        <div class="navbar-bg"></div>
                        <div class="navbar-inner">
                          <div class="title">${targetDay}日</div>
                          <div class="right"><a href="#" class="link popup-close">Close</a></div>
                        </div>
                      </div>
                      <div class="page-content">
                        <div class="list">	
                        <ul>
                          <li class="">
                            <div class="item-content">
                              <div class="item-inner">
                                <div class="item-title">①原材料の受入の確認：良</div>
                              </div>
                            </div>
                          </li>
                          <li class="">
                            <div class="item-content">
                              <div class="item-inner">
                                <div class="item-title">②庫内温度の確認（冷蔵庫・冷凍庫）：16℃、-6℃</div>
                              </div>
                            </div>
                          </li>
                          <li class="">
                            <div class="item-content">
                              <div class="item-inner">
                                <div class="item-title">③-１.交差汚染・二時汚染の防止：良</div>
                              </div>
                            </div>
                          </li>
                          <li class="">
                            <div class="item-content">
                              <div class="item-inner">
                                <div class="item-title">③-2.器具等の洗浄・消毒・殺菌：否</div>
                              </div>
                            </div>
                          </li>
                          <li class="">
                            <div class="item-content">
                              <div class="item-inner">
                                <div class="item-title">③-3.トイレの洗浄・消毒：良</div>
                              </div>
                            </div>
                          </li>
                          <li class="">
                            <div class="item-content">
                              <div class="item-inner">
                                <div class="item-title">④-１.従業員の健康管理等：良</div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
        
                        </div>
                    </div>
                  </div>
                `.trim(),
              });  
            // Open it
            popup.current.open();
          }

          });
        },
        // monthYearChangeStart(c) {
        //   $('.calendar-custom-toolbar .center').text(
        //     `${c.currentYear}年${monthNames[c.currentMonth]} `,
        //   );
        // },
      },
    });
  };
  return (
<Page onPageBeforeRemove={onPageBeforeRemove} onPageInit={onPageInit} >
  <Navbar title="重要衛生管理記録カレンダー" backLink=" " />
  <BlockTitle>スズカ未来 - 衛生管理部</BlockTitle>
  <Block strong inset className="no-padding">
    <div id="demo-calendar-inline-container" />
  </Block>
</Page>
);
};
export default ImportantHygienicCtrlCalendar;