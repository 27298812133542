import React, { useRef } from 'react';
import {
  Page,
  Navbar,
  Block,
  List,
  f7,
  ListItem,
  ListButton,
  ListInput,
  Treeview,
  TreeviewItem,
  NavLeft,
  NavTitle,
  Link,
} from 'framework7-react';

import Dom7 from 'dom7';
import ReactDOM from 'react-dom';

import '../css/sortable.css';
import '../css/grid.css'

import haccpUtils from '../js/haccp-utils.js';

const UserCountHistory = () => {
  var $$ = Dom7

  const storage = haccpUtils.getStorage();
  const actions = useRef(null);

  const storage_key = "#USER#user-count-history"

  const onPageBeforeRemove = () => {
    actions.current.destroy();
  };
  const onPageInit = () => {
    actions.current = f7.actions.create({});
    searchAll()
    searchAll2()
  };

  const searchAll = () => {
    haccpUtils.request(f7, {
      url: '/user_count',
      method: "GET",
      contentType: "application/json",
      crossDomain: true,
      headers: {Authorization: "Bearer ..."},
      data: {},
      dataType: "json",
      success: function (body, status, xhr){
        renderInit(body.data.userCount)
        storage.setItem(storage_key, body.data.userCount)
      },
      error: function (xhr, status, message) {
        console.log("error...");
      },      
      complete: function (xhr, status) {
        console.log(xhr, status);
      },
      statusCode: {
        401: function(xhr) {
          console.log(xhr)
        }
      },
    });
  }

  const searchAll2 = () => {
    haccpUtils.request(f7, {
      url: '/user_count_history',
      method: "GET",
      contentType: "application/json",
      crossDomain: true,
      headers: {Authorization: "Bearer ..."},
      data: {},
      dataType: "json",
      success: function (body, status, xhr){
        renderInit2(body.data.userCountHistory)
        storage.setItem(storage_key, body.data.userCountHistory)
      },
      error: function (xhr, status, message) {
        console.log("error...");
      },      
      complete: function (xhr, status) {
        console.log(xhr, status);
      },
      statusCode: {
        401: function(xhr) {
          console.log(xhr)
        }
      },
    });
  }

  const renderInit = (userCount) => {
    const block_data = (
      <div class="card data-table">
        {userCount.map((user, idx)=>
          <List>
            <ListItem
                header="登録者数"
                title={user.cnt + "人"}
              >
            </ListItem>
          </List>
        )}
      </div>
    )
    var block = $$("#userCountHistory .mst-count-total")
    ReactDOM.render(block_data, block[0])
  }

  const renderInit2 = (userCountHistory) => {
    const block_data = (
      <div class="card data-table">
        <table>
          <thead>
            <tr>
              <th class="font13 width120">年</th>
              <th class="font13 width120">年間登録者数</th>
              <th class="font13 width120">1月</th>
              <th class="font13 width120">2月</th>
              <th class="font13 width120">3月</th>
              <th class="font13 width120">4月</th>
              <th class="font13 width120">5月</th>
              <th class="font13 width120">6月</th>
              <th class="font13 width120">7月</th>
              <th class="font13 width120">8月</th>
              <th class="font13 width120">9月</th>
              <th class="font13 width120">10月</th>
              <th class="font13 width120">11月</th>
              <th class="font13 width120">12月</th>
            </tr>
          </thead>
          <tbody>
          {userCountHistory.map((user, idx)=>
            <tr>
              <td>{user.target_year}</td>
              <td>{user.yearly_count}</td>
              <td>{user.monthly_count1}</td>
              <td>{user.monthly_count2}</td>
              <td>{user.monthly_count3}</td>
              <td>{user.monthly_count4}</td>
              <td>{user.monthly_count5}</td>
              <td>{user.monthly_count6}</td>
              <td>{user.monthly_count7}</td>
              <td>{user.monthly_count8}</td>
              <td>{user.monthly_count9}</td>
              <td>{user.monthly_count10}</td>
              <td>{user.monthly_count11}</td>
              <td>{user.monthly_count12}</td>
            </tr>
          )}
          </tbody>
        </table>
      </div>
    )
    var block = $$("#userCountHistory .mst-count-list")
    ReactDOM.render(block_data, block[0])
  }


  return (
    <Page onPageBeforeRemove={onPageBeforeRemove} onPageInit={onPageInit} >
      <Navbar className="mst">
        <NavLeft>
          <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" />
        </NavLeft>
        <NavTitle style = {{fontSize: "20px"}}>登録ユーザ数テスト</NavTitle>
      </Navbar>
      <Block id="userCountHistory">
        <Block strong className="no-padding mst-count-total"></Block>
        <Block strong className="no-padding mst-count-list"></Block>
      </Block>
    </Page>
  );
};
export default UserCountHistory;
