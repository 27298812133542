import React, { useRef } from 'react';
import {
  Page,
  Navbar,
  Block,
} from 'framework7-react';

import Dom7 from 'dom7';

import '../css/sortable.css';
import '../css/grid.css'

const termService = () => {
  var $$ = Dom7

  return (
    <Page>

      <Navbar className="mst" title="利用規約" backLink=" "/>
      <Block>

        <div class="page-terms-service">
          <p>利用規約</p>
          <p>この利用規約（以下，「本規約」といいます。）は，株式会社スズカ未来（以下，「当社」といいます。）がこのアプリケーション上で提供するサービス（以下，「本サービス」といいます。）の利用条件を定めるものです。</p>
          <p>登録ユーザーの皆さま（以下，「ユーザー」といいます。）には，本規約に従って，本サービスをご利用いただきます。</p>
          <p></p>
          <p>第１条（保証の否認及び免責）</p>
          <p>１　ユーザーは、本件サービスに入力した情報の全てについて、事故の責任において保管及び管理するものとし、当社は、理由の如何を問わずユーザーのデータの消失ないし破損等について一切の責任を負いません。</p>
          <p>２　当社は、当社による本サービスの提供の中断、停止、終了、利用不能または変更、登録ユーザーが本サービスに送信したメッセージまたは情報の削除または消失、登録ユーザーの登録の抹消、本サービスの利用による登録データの消失または機器の故障もしくは損傷、その他本サービスに関してユーザーが被った損害につき、賠償する責任を一切負わないものとします。</p>
          <p>３　当社は、本サービスがユーザーの特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、及び不具合が生じないことについて、何ら保証するものではありません。</p>
          <p>４　本条に定める当社の責任の制限に関する規定は、当社の故意又は重過失の場合を除きます。なお、当社が損害賠償義務を負う場合であっても、当社は、ユーザーに生じた損害につき直接且つ現実に被った損害を上限として損害賠償義務を負うものとし、間接損害、特別損害、将来の損害及び逸失利益にかかる損害については賠償する責任を負わないものとします。</p>
          <p></p>
          <p>第２条（本サービスの提供の停止等）</p>
          <p>１　当社は，以下のいずれかの事由があると判断した場合，ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。</p>
          <p>　１　本サービスにかかるコンピュータシステムの保守点検または更新を行う場合</p>
          <p>　２　地震，落雷，火災，停電または天災などの不可抗力により，本サービスの提供が困難となった場合</p>
          <p>　３　コンピュータまたは通信回線等が事故により停止した場合</p>
          <p>　４　その他，当社が本サービスの提供が困難と判断した場合</p>
          <p>２　当社は，本サービスの提供の停止または中断により，ユーザーまたは第三者が被ったいかなる不利益または損害についても，一切の責任を負わないものとします。</p>
          <p>３　当社は、当社の判断により、本サービスの内容を変更し、または提供を終了することができます。</p>
        </div>
        
      </Block>
    </Page>
  );
};
export default termService;
