import React,{ useRef } from 'react';
import {
  Block,
  BlockTitle,
  f7,
  Link,
  Navbar,
  NavLeft,
  NavRight,
  NavTitle,
  Page,
} from 'framework7-react';

import Dom7 from 'dom7';
import ReactDOM from 'react-dom';

import '../css/grid.css'

import haccpUtils from '../js/haccp-utils.js';

const GeneralHygienicCtrlList = () => {
  var $$ = Dom7
  const storage_key = "#USER#general-hygienic-ctrl"
  const storage_key_list_select_id = "#USER#general-list-select-id"
  const storage = haccpUtils.getStorage();
  const actions = useRef(null);
  const onPageBeforeRemove = () => {
    actions.current.destroy();
  };
  const onPageInit = () => {
    actions.current = f7.actions.create({});
    haccpUtils.request(f7, {
      url: '/general_hygienic_ctrl',
      method: "GET",
      contentType: "application/json",
      crossDomain: true,
      headers: {Authorization: "Bearer ..."},
      data: {},
      dataType: "json",
      success: function (body, status, xhr){
        storage.setItem(storage_key, body.data.generalCtrl)
      },
      error: function (xhr, status, message) {
        console.log("error...");
      },      
      complete: function (xhr, status) {
        console.log(xhr, status);
        renderList()
      },
      statusCode: {
        401: function(xhr) {
          console.log(xhr)
        }
      },
    });
  };

  const renderList = () => {
    const generalCtrl = storage.getItemObject(storage_key_list_select_id)
    const child = generalCtrl.child;
    // console.log("child",child);
    const hyw_word = (code) => {return code ==  "1" ? "良" : code ==  "2" ? "否" : "未"};
    const hyw_color = (code) => {return code ==  "1" ? "blue" : code ==  "2" ? "red" : "black"};
    const block_data = (
      <Block strong className="no-padding">
        <BlockTitle>{child.work_year + "年" + child.work_month + "月　実施記録一覧"}</BlockTitle>
        <div class="card data-table">
          <table>
            <thead>
              <tr>
                <th>分類</th>
                <th class="item-title">{child.header_1}</th>
                <th class="item-title">{child.header_2}</th>
                <th class="item-title">{child.header_3}</th>
                <th class="item-title">{child.header_4}</th>
                <th class="item-title">{child.header_5}</th>
                <th class="item-title">{child.header_6}</th>
                <th class="item-title">{child.header_7}</th>
                <th class="table-daily_check">日々<br />チェック</th>
                <th class="table-memo">特記事項</th>
                <th class="table-admin-memo">確認者</th>
              </tr>
            </thead>
            <tbody>
              {child.details.sort((o1, o2) => (o1.work_day - o2.work_day > 0 ? 1 : -1)).map(detail=>
                <tr>
                  <td>{detail.work_day}日</td>
                  <td style = {{color: hyw_color(detail.hygienic_ctrl_work_1)}}>{hyw_word(detail.hygienic_ctrl_work_1)}</td>
                  <td style = {{color: hyw_color(detail.hygienic_ctrl_work_2)}}>{hyw_word(detail.hygienic_ctrl_work_2)}</td>
                  <td style = {{color: hyw_color(detail.hygienic_ctrl_work_3)}}>{hyw_word(detail.hygienic_ctrl_work_3)}</td>
                  <td style = {{color: hyw_color(detail.hygienic_ctrl_work_4)}}>{hyw_word(detail.hygienic_ctrl_work_4)}</td>
                  <td style = {{color: hyw_color(detail.hygienic_ctrl_work_5)}}>{hyw_word(detail.hygienic_ctrl_work_5)}</td>
                  <td style = {{color: hyw_color(detail.hygienic_ctrl_work_6)}}>{hyw_word(detail.hygienic_ctrl_work_6)}</td>
                  <td style = {{color: hyw_color(detail.hygienic_ctrl_work_7)}}>{hyw_word(detail.hygienic_ctrl_work_7)}</td>
                  <td>{detail.daily_check}</td>
                  <td>{detail.memo}</td>
                  <td>{detail.admin_memo}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Block>
    )
    haccpUtils.lazyRender("#page一般衛生管理記録実施一覧.page-current form", block_data)
  }



  return (
    <Page id="page一般衛生管理記録実施一覧" onPageBeforeRemove={onPageBeforeRemove} onPageInit={onPageInit} >
      <Navbar className="general">
        <NavLeft>
          <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" />
        </NavLeft>
        <NavTitle style = {{fontSize: "20px"}}>一般実施記録一覧</NavTitle>
        <NavRight>
          <Link href="/home/" view=".view-main" panelClose iconF7="house"></Link>
        </NavRight>
      </Navbar>
      <form></form>
    </Page>
  );
};
export default GeneralHygienicCtrlList;
