import React, { useRef } from 'react';
import {
  Page,
  Navbar,
  Block,
  List,
  f7,
  ListItem,
  ListButton,
  ListInput,
  Treeview,
  TreeviewItem,
  NavLeft,
  NavTitle,
  Link,
} from 'framework7-react';

import Dom7 from 'dom7';
import ReactDOM from 'react-dom';

import '../css/sortable.css';
import '../css/grid.css'

import haccpUtils from '../js/haccp-utils.js';

const UserMstMaintenance = () => {
  var $$ = Dom7

  const storage = haccpUtils.getStorage();
  const actions = useRef(null);

  const storage_key = "#USER#user-mst-maintenance"

  const onPageBeforeRemove = () => {
    actions.current.destroy();
  };
  const onPageInit = () => {
    actions.current = f7.actions.create({});
    searchAll()
  };

  const searchAll = () => {
    haccpUtils.request(f7, {
      url: '/csv_user_information',
      method: "GET",
      contentType: "application/json",
      crossDomain: true,
      headers: {Authorization: "Bearer ..."},
      data: {},
      dataType: "json",
      success: function (body, status, xhr){
        renderInit(body.data.csvUserInformation)
        storage.setItem(storage_key, body.data.csvUserInformation)
      },
      error: function (xhr, status, message) {
        console.log("error...");
      },      
      complete: function (xhr, status) {
        console.log(xhr, status);
      },
      statusCode: {
        401: function(xhr) {
          console.log(xhr)
        }
      },
    });
  }

  const renderInit = (userMstMaintenance) => {
    const block_data = (
      <div id="userSearchResult" class="card data-table search-data">
        <form>
          <table>
            <thead>
              <tr>
                <th class=""></th>
                <th class="font13 width120">メールアドレス</th>
                <th class="font13 width120">パスワード（確認コード）</th>
                <th class="font13 width120">ユーザ作成日時</th>
                <th class="font13 width120">姓</th>
                <th class="font13 width120">名</th>
                <th class="font13 width120">郵便番号</th>
                <th class="font13 width120">住所</th>
                <th class="font13 width120">電話番号</th>
                <th class="font13 width120">姓カナ</th>
                <th class="font13 width120">名カナ</th>
                <th class="font13 width30">店舗ID</th>
                <th class="font13 width120">店舗名</th>
                <th class="font13 width30">ユーザID</th>
                {/* 
                <th class="font13 width120">確認コード</th>
                <th class="font13 width120">年齢</th>
                <th class="font13 width120">ファックス番号</th>
                <th class="font13 width120">携帯番号</th>
                <th class="font13 width120">ユーザ備考</th>
                <th class="font13 width120">ユーザ作成者</th>
                <th class="font13 width120">ユーザ更新者</th>
                <th class="font13 width120">ユーザ更新日時</th>
                <th class="font13 width120">ユーザ削除フラグ</th>
                <th class="font13 width30">アカウントID</th>
                <th class="font13 width120">アカウント備考</th>
                <th class="font13 width120">アカウント作成日時</th>
                <th class="font13 width120">アカウント作成者</th>
                <th class="font13 width120">アカウント更新日時</th>
                <th class="font13 width120">アカウント更新者</th>
                <th class="font13 width120">アカウント削除フラグ</th>
                <th class="font13 width120">店舗備考</th>
                <th class="font13 width120">店舗作成日時</th>
                <th class="font13 width120">店舗作成者</th>
                <th class="font13 width120">店舗更新日時</th>
                <th class="font13 width120">店舗更新者</th>
                <th class="font13 width120">店舗削除フラグ</th>
                <th class="font13 width30">部門ID</th>
                <th class="font13 width120">部門名</th>
                  */}
              </tr>
            </thead>
            <tbody>
            {userMstMaintenance.filter((user) => {
              var visible = false
              var storedData = f7.form.convertToData('#userMstMaintenance form');
              console.log(user.user_create_dttm)
              console.log(storedData.create_from_date)
              console.log(storedData.create_to_date)
              var target = new Date()
              console.log("----------")
              if(user.user_create_dttm != null && user.user_create_dttm != "") {
                target = haccpUtils.getToday("yyyymmdd", new Date(user.user_create_dttm.split(' ')[0]))
                console.log(target)
              }
              var from = ""
              if(storedData.create_from_date != null && storedData.create_from_date != "") {
                from = haccpUtils.getToday("yyyymmdd", new Date(storedData.create_from_date))
                console.log(from)
              }
              var to = ""
              if(storedData.create_to_date != null && storedData.create_to_date != "") {
                to = haccpUtils.getToday("yyyymmdd", new Date(storedData.create_to_date))
                console.log(to)
              }
              console.log("----------")
              // 条件を満たしていればtrueを返す
              console.log(storedData)
              if (storedData.email.length == 0 || (storedData.email.length > 0 && ~user.email.indexOf(storedData.email))) {
                if (storedData.password_setting_flg.length == 0 || storedData.password_setting_flg == "0" || (storedData.password_setting_flg == "1" && user.password_setting_flg == 1) || (storedData.password_setting_flg == "2" && user.password_setting_flg == 0)) {
                  if (storedData.create_from_date.length == 0 || storedData.create_from_date == null || storedData.create_from_date == "" || from <= target ) {
                    if (storedData.create_to_date.length == 0 || storedData.create_to_date == null || storedData.create_to_date == "" || to >= target ) {
                      visible = true
                    }
                  }
                }
              }
              return visible
            }).map((user, idx)=>
              <tr style = {{color: (user.password_setting_flg == 1) ? "red" : ""}}>
                <td class="">
                  {/* <input type="checkbox" name="checkUserID" value={user.user_id}/> */}
                  {/* <i class="icon icon-checkbox"></i> */}
                  <input type="checkbox" name={"users["+idx+"].checked"} value={true}/>
                  <input type="hidden" name={"users["+idx+"].password_setting_flg"} value={user.password_setting_flg}/>
                  <input type="hidden" name={"users["+idx+"].id"} value={user.user_id}/>
                </td>
                <td class="width120">{user.email}</td>
                <td class="width120">
                  {
                    (user.password_setting_flg == 0) ? "未登録" : (user.password_setting_flg == 1) ? "登録済" : "不明"
                  }
                  </td>
                <td class="width120">{user.user_create_dttm}</td>
                <td class="width120">{user.last_name}</td>
                <td class="width120">{user.first_name}</td>
                <td class="width120">{user.zip}</td>
                <td class="width120">{user.addr}</td>
                <td class="width120">{user.tel}</td>
                <td class="width120">{user.last_name_kana}</td>
                <td class="width120">{user.first_name_kana}</td>
                <td class="width30">{user.shop_id}</td>
                <td class="width120">{user.shop_name}</td>
                <td class="width30">{user.user_id}</td>
                {/* 
                <td class="width120">{user.valid_code}</td>
                <td class="width120">{user.age}</td>
                <td class="width120">{user.fax}</td>
                <td class="width120">{user.mobile}</td>
                <td class="width120">{user.user_remark}</td>
                <td class="width120">{user.user_create_acct_id}</td>
                <td class="width120">{user.user_upd_acct_id}</td>
                <td class="width120">{user.user_upd_dttm}</td>
                <td class="width120">{user.user_del_flg}</td>
                <td class="width30">{user.account_id}</td>
                <td class="width120">{user.account_remark}</td>
                <td class="width120">{user.account_create_dttm}</td>
                <td class="width120">{user.account_create_acct_id}</td>
                <td class="width120">{user.account_upd_dttm}</td>
                <td class="width120">{user.account_upd_acct_id}</td>
                <td class="width120">{user.account_del_flg}</td>
                <td class="width120">{user.shop_remark}</td>
                <td class="width120">{user.shop_create_dttm}</td>
                <td class="width120">{user.shop_create_acct_id}</td>
                <td class="width120">{user.shop_upd_dttm}</td>
                <td class="width120">{user.shop_upd_acct_id}</td>
                <td class="width120">{user.shop_del_flg}</td>
                <td class="width30">{user.department_id}</td>
                <td class="width120">{user.department_name}</td>
                  */}
              </tr>
            )}
            </tbody>
          </table>
          <List>
            <ListButton className="danger"
              title="削除する"
              onClick={()=> {
                var storedData = haccpUtils.convertFormToData(f7, "#userSearchResult form")
                console.log(storedData)
                var password_setting_count = 0
                var not_password_setting_count = 0
                var checkedUsers = storedData.users
                .filter((user)=>user.checked[0])
                .map((user)=>{
                    if(user.password_setting_flg == "1"){
                      password_setting_count++
                    }else{
                      not_password_setting_count++
                    }
                    return user
                }) 

                if(checkedUsers.length > 0) {
                  console.log(storedData)
                  console.log(checkedUsers)
                  f7.dialog.create({
                    text: (password_setting_count > 0) ? '<p style="color: red">本当に削除しますか？</p>' : '本当に削除しますか?',
                    title: '<p style="color: red">設定済件数:' + password_setting_count + '件</p><p>未設定件数:' + not_password_setting_count + '件</p>',
                    buttons: [
                      {
                        text: 'はい',
                        onClick: function () {
                          deleteProc(checkedUsers);
                        },
                      },
                      {
                        text: 'いいえ',
                      },
                    ],
                  }).open();

                }
              }}



            />
          </List>
        </form>
      </div>
    )
    var block = $$("#userMstMaintenance .mst-list")
    ReactDOM.render(block_data, block[0])

    var blankList = {}
    userMstMaintenance.map((user, idx) => {
      blankList["users["+idx+"].checked"] = []
    })
    console.log("blankList")
    console.log(blankList)
    f7.form.fillFromData("#userSearchResult form",blankList)
  }

  const deleteProc = (users) => {
    // var storedData = f7.form.convertToData("#userSearchResult form")
    console.log(users)
    if(users.length) {
      console.log("OK!")
      haccpUtils.request(f7, {
        url: '/delete_user_account',
        method: "POST",
        contentType: "application/json",
        crossDomain: true,
        headers: {Authorization: "Bearer ..."},
        data: {users: users},
        dataType: "json",
        success: function (body, status, xhr){
          console.log("success...");
          console.log(body.data.result);
          if(body.data.result == true){
            searchAll()
          }
        },
        error: function (xhr, status, message) {
          console.log("error...");
        },      
        complete: function (xhr, status) {
          console.log(xhr, status);
        },
        statusCode: {
          401: function(xhr) {
            console.log(xhr)
          }
        },
      });

      // storage.setItem(storage_key, databaseData)
    }
  }

  return (
    <Page onPageBeforeRemove={onPageBeforeRemove} onPageInit={onPageInit} >
      <Navbar className="mst">
        <NavLeft>
          <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" />
        </NavLeft>
        <NavTitle style = {{fontSize: "20px"}}>ユーザマスタメンテ</NavTitle>
        {/* <NavRight>
          <Link href="/home/" view=".view-main" panelClose iconF7="house"></Link>
        </NavRight> */}
      </Navbar>
      <Block id="userMstMaintenance">
        <form onSubmit={haccpUtils.handleSubmitCancel}>
          <Treeview>
            <TreeviewItem className="font13" label="検索" iconF7="search">
              <List noHairlinesMd className="p-top">
                <ListInput
                  outline
                  className="font13 search-title search-title-input"
                  label="メールアドレス"
                  type="text"
                  name="email"
                  placeholder="メールアドレス"
                  maxlength="255"
                ></ListInput>
                {/* <ListInput
                  outline
                  className="font13 search-title"
                  label="確認コード"
                  type="text"
                  name="valid_code"
                  placeholder="確認コード"
                  validate
                  pattern="[0-9]*"
                  data-error-message="入力できるのは数値のみです"
                  maxlength="32"
                ></ListInput> */}
                <ListInput
                  outline
                  className="font13 search-title search-title-select"
                  label="パスワード（確認コード）"
                  type="select"
                  name="password_setting_flg"
                  defaultValue="2"
                >
                  <option value="0">全て</option>
                  <option value="1">登録済</option>
                  <option value="2">未登録</option>
                </ListInput>
                <ListInput
                  outline
                  className="font13 search-title search-title-date"
                  label="登録日付（開始）"
                  type="date"
                  name="create_from_date"
                  placeholder=""
                ></ListInput>
                <ListInput
                  outline
                  className="font13 search-title search-title-date"
                  label="登録日付（終了）"
                  type="date"
                  name="create_to_date"
                  placeholder=""
                ></ListInput>
              </List>
              <List>
                <ListButton
                  title="検索する"
                  onClick={()=> {
                    const csvUserInformation = storage.getItemObject(storage_key)
                    renderInit(csvUserInformation)
                  }}
                />
              </List>
            </TreeviewItem>
          </Treeview>
        </form>
        <Block strong className="no-padding mst-list"></Block>
      </Block>
    </Page>
  );
};
export default UserMstMaintenance;
