import React from 'react';
import { Page, Navbar, Block } from 'framework7-react';

const LeftPage1 = () => (
  <Page>
    <Navbar title="Left Page 1" backLink=" " />
    <Block strong>
      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit deserunt vel accusamus voluptatem neque! Laborum, et facilis at. Est dolorem, reprehenderit, sed repellendus at cum incidunt labore vel perferendis excepturi?</p>
      <p>Tempore accusantium quaerat officiis dolores ullam, perferendis labore assumenda. A sint quo necessitatibus temporibus ipsam adipisci et quos aliquid officiis, facilis nulla ut aperiam assumenda magnam blanditiis exercitationem facere non!</p>
      <p>Omnis accusamus harum, vero dolores itaque, asperiores laudantium eligendi ipsa, labore dignissimos unde temporibus eius assumenda, obcaecati fuga et. Et provident at consectetur vel temporibus ea nam, veritatis excepturi obcaecati.</p>
      <p>Aspernatur dolorum nostrum est sapiente deleniti in. Placeat itaque expedita dignissimos, suscipit tenetur necessitatibus sunt excepturi quisquam, doloremque repudiandae mollitia in. Nesciunt quo vel, dolorum magni nihil officia reprehenderit libero.</p>
      <p>Fugit nesciunt nobis at doloribus labore, voluptatem quis, distinctio, saepe illo adipisci qui molestias fugiat ut! Quasi animi possimus temporibus repudiandae quam aspernatur, recusandae libero, reiciendis distinctio cupiditate nesciunt a.</p>
    </Block>
  </Page>
);

export default LeftPage1;
