import React, { useRef } from 'react';
import {
  Page,
  Navbar,
  Block,
  List,
  f7,
  ListItem,
  ListButton,
  ListInput,
  Treeview,
  TreeviewItem,
  NavLeft,
  NavTitle,
  Link,
} from 'framework7-react';

import Dom7 from 'dom7';
import ReactDOM from 'react-dom';

import '../css/sortable.css';
import '../css/grid.css'

import haccpUtils from '../js/haccp-utils.js';

const RecordHistory = () => {
  var $$ = Dom7

  const storage = haccpUtils.getStorage();
  const actions = useRef(null);

  const storage_key = "#USER#record-history"

  const onPageBeforeRemove = () => {
    actions.current.destroy();
  };
  const onPageInit = () => {
    actions.current = f7.actions.create({});
    searchAll()
  };

  const searchAll = () => {
    haccpUtils.request(f7, {
      url: '/record_history',
      method: "GET",
      contentType: "application/json",
      crossDomain: true,
      headers: {Authorization: "Bearer ..."},
      data: {},
      dataType: "json",
      success: function (body, status, xhr){
        renderInit(body.data.recordHistory)
        storage.setItem(storage_key, body.data.recordHistory)
      },
      error: function (xhr, status, message) {
        console.log("error...");
      },      
      complete: function (xhr, status) {
        console.log(xhr, status);
      },
      statusCode: {
        401: function(xhr) {
          console.log(xhr)
        }
      },
    });
  }

  const renderInit = (recordHistory) => {
    const block_data = (
      <div id="userSearchResult" class="card data-table search-data">
        <form>
        <table>
            <thead class="bottom-border-double">
              <tr>
                <th class="font13 font-bold height48">店鋪名</th>
                <th class="font13 font-bold">メールアドレス</th>
                <th class="font13 font-bold">電話番号</th>
                <th class="font13 font-bold">住所</th>
                <th class="font13 font-bold">ユーザー登録日</th>
                <th class="font13 font-bold">記録タイプ</th>
                <th class="font13 font-bold">記録者</th>
                <th class="font13 font-bold">記録日数</th>
                <th class="font13 font-bold">最終記録日</th>
                {/* <th class="font13 font-bold">ロールID</th> */}
                {/* <th class="font13 font-bold">FAX番号</th> */}
                {/* <th class="font13 font-bold">携帯番号</th> */}
                {/* <th class="font13 font-bold">郵便番号</th> */}
                {/* <th class="font13 font-bold">記録者</th> */}
                {/* <th class="font13 font-bold">記録者（姓）</th>
                <th class="font13 font-bold height24">記録者（名）</th> */}
                {/* <th class="font13 font-bold">カナ</th> */}
                {/* <th class="font13 font-bold">カナ（姓）</th>
                <th class="font13 font-bold">カナ（名）</th> */}
                {/* <th class="font13 font-bold">年齢</th> */}
              </tr>
            </thead>
            <tbody>
            {recordHistory.filter((user) => {
              var visible = false
              var storedData = f7.form.convertToData('#recordHistory form');
              var target = new Date()
              console.log("----------")
              if(user.new_record_date != null && user.new_record_date != "") {
                target = haccpUtils.getToday("yyyymmdd", new Date(user.new_record_date.split(' ')[0]))
                console.log("new_record_date", user.new_record_date)
                console.log("target", target)
              }
              var from = ""
              if(storedData.new_record_date_from != null && storedData.new_record_date_from != "") {
                from = haccpUtils.getToday("yyyymmdd", new Date(storedData.new_record_date_from))
                console.log("from", from)
              }
              var to = ""
              if(storedData.new_record_date_to != null && storedData.new_record_date_to != "") {
                to = haccpUtils.getToday("yyyymmdd", new Date(storedData.new_record_date_to))
                console.log("to", to)
              }

              // 条件を満たしていればtrueを返す
              console.log(storedData)
              if ((storedData.user_name.length == 0) || (storedData.user_name.length > 0 && ~user.user_name.indexOf(storedData.user_name)) || (storedData.user_name.length > 0 && ~user.user_name_kana.indexOf(storedData.user_name))) {
                if (storedData.email.length == 0 || (storedData.email.length > 0 && ~user.email.indexOf(storedData.email))) {
                  if (storedData.tel.length == 0 || (storedData.tel.length > 0 && ~user.tel.indexOf(storedData.tel))) {
                    if (storedData.record_count_min == null || storedData.record_count_min == "" || storedData.record_count_min <= user.record_count ) {
                      if (storedData.record_count_max == null || storedData.record_count_max == "" || storedData.record_count_max >= user.record_count ) {
                        if (storedData.new_record_date_from.length == 0 || storedData.new_record_date_from == null || storedData.new_record_date_from == "" || (storedData.new_record_date_from.length > 0 && target.length > 0 && from <= target) ) {
                          if (storedData.new_record_date_to.length == 0 || storedData.new_record_date_to == null || storedData.new_record_date_to == "" || (storedData.new_record_date_to.length > 0 && target.length > 0 && to >= target) ) {
                            visible = true
                          }
                        }
                      }
                    }
                  }
                }
              }
              return visible
            }).map((user, idx)=>
              <tr>
                <td>{user.shop_name}</td>
                <td>{user.email}</td>
                <td>{user.tel}</td>
                <td>{user.addr}</td>
                <td>{user.create_dttm}</td>
                <td>{user.report_type}</td>
                <td>{user.user_name + ((user.user_name_kana) ? '（' + user.user_name_kana + '）' : user.user_name_kana)}</td>
                <td>{user.record_count}</td>
                <td>{user.new_record_date}</td>
                {/* <td>{user.role_id}</td> */}
                {/* <td>{user.zip}</td> */}
                {/* <td>{user.user_name}</td> */}
                {/* <td>{user.last_name}</td> */}
                {/* <td>{user.first_name}</td> */}
                {/* <td>{user.user_name_kana}</td> */}
                {/* <td>{user.last_name_kana}</td> */}
                {/* <td>{user.first_name_kana}</td> */}
                {/* <td>{user.age}</td> */}
                {/* <td>{user.fax}</td> */}
                {/* <td>{user.mobile}</td> */}
              </tr>
            )}
            </tbody>
          </table>

          {/* Ver1 */}
          {/* <table>
            <thead class="bottom-border-double">
              <tr>
                <th></th>
                <th class="font13 font-bold width120 height48" rowspan="2">店鋪名</th>
                <th class="font13 font-bold height24" colspan="2">一般衛生管理記録者</th>
                <th class="font13 font-bold height24" colspan="2">メールアドレス</th>
                <th class="font13 font-bold height24" colspan="2">電話番号</th>
                <th class="font13 font-bold width120 height48" rowspan="2">一般衛生管理実施記録日数</th>
                <th class="font13 font-bold width120 height48" rowspan="2">最終登録日</th>
                <th></th>
                <th class="font13 font-bold height24" colspan="2">重要衛生管理記録者</th>
                <th class="font13 font-bold height24" colspan="2">メールアドレス</th>
                <th class="font13 font-bold height24" colspan="2">電話番号</th>
                <th class="font13 font-bold width120 height48" rowspan="2">重要衛生管理実施記録日数</th>
                <th class="font13 font-bold width120 height48" rowspan="2">最終登録日</th>
              </tr>
              <tr>
                <th class="font13 font-bold width120 height24">名</th>
                <th class="font13 font-bold width120 height24">カナ</th>
                <th class="font13 font-bold width120 height24">名</th>
                <th class="font13 font-bold width120 height24">カナ</th>
              </tr>
            </thead>
            <tbody>
            {recordHistory.filter((user) => {
              var visible = false
              var storedData = f7.form.convertToData('#recordHistory form');
              // 条件を満たしていればtrueを返す
              console.log(storedData)
              if (storedData.shop_name.length == 0 || (storedData.shop_name.length > 0 && ~user.shop_name.indexOf(storedData.shop_name))) {
                if (storedData.g_user_name.length == 0 || (storedData.g_user_name.length > 0 && ~user.g_user_name.indexOf(storedData.g_user_name))) {
                  if (storedData.g_user_name_kana.length == 0 || (storedData.g_user_name_kana.length > 0 && ~user.g_user_name_kana.indexOf(storedData.g_user_name_kana))) {
                    if (storedData.i_user_name.length == 0 || (storedData.i_user_name.length > 0 && ~user.i_user_name.indexOf(storedData.i_user_name))) {
                      if (storedData.i_user_name_kana.length == 0 || (storedData.i_user_name_kana.length > 0 && ~user.i_user_name_kana.indexOf(storedData.i_user_name_kana))) {
                        visible = true
                      }
                    }
                  }
                }
              }
              return visible
            }).map((user, idx)=>
              <tr>
                <td>{user.g_role_id}</td>
                <td>{user.shop_name}</td>
                <td>{user.g_user_name}</td>
                <td>{user.g_user_name_kana}</td>
                <td>{user.g_email}</td>
                <td>{user.g_tel}</td>
                <td>{user.g_record_count}</td>
                <td>{user.g_new_record_date}</td>
                <td>{user.i_role_id}</td>
                <td>{user.i_user_name}</td>
                <td>{user.i_user_name_kana}</td>
                <td>{user.g_email}</td>
                <td>{user.g_tel}</td>
                <td>{user.i_record_count}</td>
                <td>{user.i_new_record_date}</td>
              </tr>
            )}
            </tbody>
          </table> */}
        </form>
      </div>
    )
    var block = $$("#recordHistory .mst-list")
    ReactDOM.render(block_data, block[0])

    var blankList = {}
    recordHistory.map((user, idx) => {
      blankList["users["+idx+"].checked"] = []
    })
    console.log("blankList")
    console.log(blankList)
    f7.form.fillFromData("#userSearchResult form",blankList)
  }


  return (
    <Page onPageBeforeRemove={onPageBeforeRemove} onPageInit={onPageInit} >
      <Navbar className="mst">
        <NavLeft>
          <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" />
        </NavLeft>
        <NavTitle style = {{fontSize: "20px"}}>記録履歴</NavTitle>
        {/* <NavRight>
          <Link href="/home/" view=".view-main" panelClose iconF7="house"></Link>
        </NavRight> */}
      </Navbar>
      <Block id="recordHistory">
        <form onSubmit={haccpUtils.handleSubmitCancel}>

          {/* 検索機能は、現時点で不要となったため、実装をコメントにする。
          一方で、初期値のフィールドは必要であるため、hiddenで実装しておく。
          検索機能を提供する際は、hidden項目は不要となる。 */}
          <input type="hidden" name="user_name"></input>
          <input type="hidden" name="email"></input>
          <input type="hidden" name="tel"></input>
          <input type="hidden" name="new_record_date_from"></input>
          <input type="hidden" name="new_record_date_to"></input>
          <input type="hidden" name="record_count_min"></input>
          <input type="hidden" name="record_count_max"></input>

          {/* <Treeview>
            <TreeviewItem className="font13" label="検索" iconF7="search">
              <List noHairlinesMd className="p-top">
                <ListInput
                  outline
                  className="font13 search-title search-condition inline-block"
                  label="記録者"
                  type="text"
                  name="user_name"
                  placeholder="記録者"
                  maxlength="100"
                ></ListInput>
                <ListInput
                  outline
                  className="font13 search-title search-condition inline-block"
                  label="メールアドレス"
                  type="text"
                  name="email"
                  placeholder="メールアドレス"
                  maxlength="100"
                ></ListInput>
                <ListInput
                  outline
                  className="font13 search-title search-condition inline-block"
                  label="電話番号"
                  type="text"
                  name="tel"
                  placeholder="電話番号"
                  maxlength="100"
                ></ListInput>
              </List>
              <List noHairlinesMd className="p-top">
                <ListInput
                  outline
                  className="font13 search-title search-title-date inline-block"
                  label="最終登録日（開始）"
                  type="date"
                  name="new_record_date_from"
                  placeholder=""
                ></ListInput>
                <ListInput
                item-input-outline
                  outline
                  className="font13 search-title search-title-date inline-block"
                  label="最終登録日（終了）"
                  type="date"
                  name="new_record_date_to"
                  placeholder=""
                ></ListInput>
              </List>
              <List noHairlinesMd className="p-top">
                <ListInput
                  outline
                  className="font13 search-title search-title-date  inline-block"
                  label="記録日数（min）"
                  type="text"
                  name="record_count_min"
                  placeholder=""
                  maxlength="1000"
                ></ListInput>
                <ListInput
                  outline
                  className="font13 search-title search-title-date inline-block"
                  label="記録日数（max）"
                  type="text"
                  name="record_count_max"
                  placeholder=""
                  maxlength="1000"
                ></ListInput>
              </List>
              <List>
                <ListButton
                  title="検索する"
                  onClick={()=> {
                    const recordHistory = storage.getItemObject(storage_key)
                    renderInit(recordHistory)
                  }}
                />
              </List>
            </TreeviewItem>
          </Treeview> */}
        </form>
        <Block strong className="no-padding mst-list"></Block>
      </Block>
    </Page>
  );
};
export default RecordHistory;
