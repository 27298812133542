import React,{ useRef } from 'react';
import {
  Page,
  Navbar,
  Block,
  BlockTitle,
  List,
  ListItem,
  View,
  f7,
  Row,
  Col,
  AccordionContent, 
  Button,
  ListInput,
  NavLeft,
  NavRight,
  NavTitle,
  Link,
  Treeview,
  TreeviewItem,
  Fab,
  Icon,
} from 'framework7-react';

import Dom7 from 'dom7';
import ReactDOM from 'react-dom';
import haccpUtils from '../js/haccp-utils.js';

import '../css/grid.css'

const GeneralHygienicCtrlPlan = () => {
  var $$ = Dom7

  const storage = haccpUtils.getStorage();
  const storage_key = "#USER#general-hygienic-ctrl-plan"
  
  const actions = useRef(null);
  const onPageBeforeRemove = () => {
    actions.current.destroy();
  };
  const onPageInit = () => {
    haccpUtils.request(f7, {
      url: '/general_hygienic_ctrl_plan',
      method: "GET",
      contentType: "application/json",
      crossDomain: true,
      headers: {Authorization: "Bearer ..."},
      data: {},
      dataType: "json",
      success: function (body, status, xhr){
        storage.setItem(storage_key, body.data.generalPlan)
      },
      error: function (xhr, status, message) {
        console.log("error...");
      },      
      complete: function (xhr, status) {
        console.log(xhr, status);
        renderList()
      },
      statusCode: {
        401: function(xhr) {
          console.log(xhr)
        }
      },
    });
    actions.current = f7.actions.create({});
  };

  const renderList = () => {
    const generalPlan = storage.getItemObject(storage_key)
    console.log("----generalPlan-----")
    console.log(generalPlan)
    console.log("--------------------")
    const block_data = (
      <Block className="no-padding">
        {generalPlan.map(item =>
          <Block strong className="no-padding">
          {/* <BlockTitle>{item.shop_name} - {item.department_name}</BlockTitle> */}
            <input type="hidden" name="shop_name" value={item.shop_name} />
            <input type="hidden" name="department_name" value={item.department_name} />
            {/* <Treeview>
              {item.children.map(child =>
                <TreeviewItem
                  label={child.create_date.slice(0,10) + " 計画番号" + child.plan_version}
                  onClick={popupOpenEdit(child)}
                  popupOpen="#haccp-popup"
                  iconF7="doc_text"
                >
                </TreeviewItem>
              )}
            </Treeview> */}

            <List>
              {item.children.map(child =>
                <ListItem
                  link="#"
                  header={child.shop_name + ">" + child.create_date.slice(0,10)}
                  title={"計画番号" + child.plan_version}
                  onClick={popupOpenEdit(child)}
                  popupOpen="#haccp-popup"
                  iconF7="doc_text"
                  after="詳細"
                >
                </ListItem>
              )}
            </List>

          </Block>
        )}
      </Block>
    )
    haccpUtils.lazyRender("#page一般衛生管理計画.page-current form", block_data)
  }
  const storeList = (storeData) => {
    haccpUtils.request(f7, {
      url: '/general_hygienic_ctrl_plan',
      method: "POST",
      contentType: "application/json",
      crossDomain: true,
      headers: {Authorization: "Bearer ..."},
      data: storeData,
      dataType: "json",
      success: function (body, status, xhr){
        console.log("success...");
        storage.setItem(storage_key, body.data.generalPlan)
      },
      error: function (xhr, status, message) {
        console.log("error...");
      },      
      complete: function (xhr, status) {
        console.log(xhr, status);
        renderList()
      },
      statusCode: {
        401: function(xhr) {
          console.log(xhr)
        }
      },
    });
  }

  const popupUpdate = (params) => {
    return () => {
      var storedData = haccpUtils.convertFormToData(f7, '#haccp-popup form');
      if (storedData) {
        // TODO 更新したい
        // haccpUtils.update??????(f7, storedData)

        var databaseData = storage.getItemObject(storage_key)
        var isNew = true

        databaseData[0].children.forEach((child, index) => {
          if(child.id == params.id) {
            isNew = false
          //  for( let key in storedData) {
          //    databaseData[0].children[index][key] = storedData[key]
          //  }
            databaseData[0].children[index].create_signature = storedData.create_signature
            databaseData[0].children[index].create_date = storedData.create_date
            databaseData[0].children[index].details = storedData.details
          }
        });
        if(isNew) {
          databaseData[0].children.push(storedData)
        }
        console.log("------databaseData-----------")
        console.log(databaseData)
        storeList({"plan": databaseData[0]})
        storage.setItem(storage_key, databaseData)

        f7.popup.close("#haccp-popup");
      } else {
         alert('Yet there is no stored data for this form. Please try to change any field')
      }
    }
  }

  const popupOpen = (popupElements, params) => {
    console.log("------popupOpen-----------")
    console.log(popupElements)
    console.log(params)

    var popup = $$("#haccp-popup")
    const view = (
      <View>
        <Page>
          <Navbar title={popupElements.title}>
            <NavRight>
              <Link popupClose iconF7="xmark"></Link>
            </NavRight>
          </Navbar>
          <form></form>
        </Page>
      </View>
    )
    ReactDOM.render(view, popup[0])

    var form = $$("#haccp-popup form")
    const timeElementOptions = storage.getItemObject("#USER#timeElementOptions");
    var block = (
      <Block>
        <input type="hidden" name="id" value={params.id} />
        <input type="hidden" name="shop_id" value={params.shop_id} />
        <input type="hidden" name="department_id" value={params.department_id} />
        <input type="hidden" name="plan_version" value={params.plan_version} />
        <input type="hidden" name="temp_version" value={params.temp_version} />
        <List>
          {params.details.map((detail, idx)=>
            <ListItem accordionItem title={detail.item_no + detail.item_name}>
              <input type="hidden" name={"details["+idx+"].id"} value={detail.id} />
              <input type="hidden" name={"details["+idx+"].item_id"} value={detail.item_id} />
              <input type="hidden" name={"details["+idx+"].item_no"} value={detail.item_no} />
              <input type="hidden" name={"details["+idx+"].item_name"} value={detail.item_name} />
              <input type="hidden" name={"details["+idx+"].item_name_pdf"} value={detail.item_name_pdf} />
              <AccordionContent>
                <List>
                  {(params["id"] != "?") ? (
                      <ListInput
                        outline
                        label="いつ"
                        type="textarea"
                        name={"details["+idx+"].work_time"}
                        placeholder=""
                        defaultValue={detail.work_time}
                        maxlength="400"
                        required
                      >
                      </ListInput>
                  ) : (

// フリーフォーマットにするケース
                      // <ListInput
                      //   outline
                      //   label="いつ"
                      //   type="textarea"
                      //   name={"details["+idx+"].work_time"}
                      //   placeholder=""
                      //   defaultValue={
                      //     // コードの配列をメニューマスタのメニュー名に変換し改行して結合
                      //     detail.time_element_id.map((time_element_id) => {
                      //       return timeElementOptions.find(option => option.value === time_element_id).text
                      //     }).join('\n')
                      //   }
                      //   maxlength="400"
                      //   required
                      // >
                      // </ListInput>

                    <div>
                      <ListItem
                        outline
                        title="いつ"
                        smartSelect
                        smartSelectParams={{
                          openIn: 'popup',
                          searchbar: true,
                          searchbarPlaceholder: '検索キー',
                          popupCloseLinkText: '❌',
                        }}
                      >
                      <select name={"details["+idx+"].work_time"}
                        multiple defaultValue={detail.time_element_id}>
                        {timeElementOptions.map(option=> 
                          <option value={option.value}>{option.text}</option>
                        )}
                      </select>
                      </ListItem>
                      <ListInput
                      outline
                      label="その他"
                      type="textarea"
                      name={"details["+idx+"].other_description"}
                      placeholder=""
                      defaultValue={detail.other_description}
                      maxlength="350"
                      required
                      >
                      </ListInput>
                    </div>
                  )}
                  <ListInput
                    outline
                    label="どのように"
                    type="textarea"
                    name={"details["+idx+"].method_element"}
                    placeholder=""
                    defaultValue={detail.method_element}
                    maxlength="400"
                    required
                  >
                  </ListInput>
                  <ListInput
                    outline
                    label="問題があったとき"
                    type="textarea"
                    name={"details["+idx+"].problem_occured"}
                    placeholder=""
                    defaultValue={detail.problem_occured}
                    maxlength="400"
                    required
                  >
                  </ListInput>
                </List>
              </AccordionContent>
            </ListItem>
          )}
          <ListItem accordionItem title="作成者">
            <AccordionContent>
              <List noHairlinesMd>
                <ListInput
                  outline
                  label="作成者サイン"
                  type="text"
                  name="create_signature"
                  placeholder=""
                  defaultValue={params.create_signature}
                  maxlength="40"
                  ></ListInput>
                <ListInput
                  label="作成した日"
                  type="date"
                  name="create_date"
                  placeholder=""
                  defaultValue={params.create_date.split(" ")[0]}
                ></ListInput>
              </List>
            </AccordionContent>
          </ListItem>
          <Block>
            <p>{popupElements.message}</p>
            <Row>
              <Col>
                <Button onClick={popupElements.submit(params)} fill>{popupElements.button}</Button>
              </Col>
            </Row>
          </Block>
        </List>
      </Block>
    )

    ReactDOM.render(block, form[0])
  }
  const popupOpenEdit = (params) => {
    console.log("popupOpenEdit")
    console.log(params)

    return () => {
      var databaseData = storage.getItemObject(storage_key)
      var children = databaseData[0].children.filter((child) => { return child.id == params.id})

      console.log("[popupOpenEdit]:open")
      console.log([params, children])


      return popupOpen(
        {
          title: "一般衛生管理計画を編集する",
          message: "上記の内容で一般衛生管理計画を修正します。",
          button: "修正する",
          submit: () => {
            return popupUpdate(children[0])
          }
        }, children[0]
      )
    }
  }
  
  const popupOpenNew = () => {
    haccpUtils.request(f7, {
      url: '/general_ctrl_plan_template',

      method: "GET",
      contentType: "application/json",
      crossDomain: true,
      headers: {Authorization: "Bearer ..."},
      data: {},
      dataType: "json",
      success: function (body, status, xhr){
        // ストレージに詰める
        // storage.setItem("#USER#.....", body.data.generalTemplate)

        // ここではやらない。
        renderBlock(body.data.generalTemplate)
     },
     error: function (xhr, status, message) {
       console.log("error");
     },      
     _complete: function (xhr, status) {
       // ストレージからから取得して、
       // const generalTemplate = storage.getItemObject("#USER#.....")
       // renderBlock(generalTemplate)
      }
    });

    const renderBlock = (generalTemplate) => {
      const params = {
        id: "?",
        create_signature: "",
        create_date: haccpUtils.getToday("yyyy-mm-dd"),
        shop_id: generalTemplate.shop_id,
        department_id: generalTemplate.department_id,
        plan_version: generalTemplate.plan_version,
        temp_version: generalTemplate.temp_version,
        details: generalTemplate.details,
      }

      return popupOpen({
        title: "一般衛生管理計画を作成する",
        message: "上記の内容で一般衛生管理計画を保存します。",
        button: "登録",
        submit: (params) => {
          return popupUpdate(params)
        },
      }, params)
    }
  }

  return (
    <Page id="page一般衛生管理計画" onPageBeforeRemove={onPageBeforeRemove} onPageInit={onPageInit} >

      <Fab position="right-top" slot="fixed" color="teal">
        <Button fill popupOpen="#haccp-popup" onClick={popupOpenNew}>
          <Icon ios="f7:plus_square_on_square" aurora="f7:fiber_new" md="material:fiber_new" />
        </Button>
      </Fab>
      
      <Navbar className="general">
        <NavLeft>
          <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" />
        </NavLeft>
        <NavTitle style = {{fontSize: "20px"}}>一般衛生管理計画</NavTitle>
        <NavRight>
          <Link href="/home/" view=".view-main" panelClose iconF7="house" color="red"></Link>
        </NavRight>
      </Navbar>
      <BlockTitle>一覧</BlockTitle>
      <form></form>
    </Page>
  );
};
export default GeneralHygienicCtrlPlan;
