import React,{ useRef } from 'react';
import {
  Page,
  View,
  Navbar,
  NavLeft,
  NavRight,
  NavTitle,
  Block,
  BlockTitle,
  List,
  ListItem,
  f7,
  Row,
  Col,
  AccordionContent, 
  Button,
  ListInput,
  Icon,
  Fab,
  Link,
  TreeviewItem
} from 'framework7-react';

import Dom7 from 'dom7';
import ReactDOM from 'react-dom';

import '../css/grid.css'

import haccpUtils from '../js/haccp-utils.js';


const DefaultGeneralTemplate = () => {
  var $$ = Dom7
  
  const storage = haccpUtils.getStorage()
  const storage_key = "#USER#default-general-template"

  const storage_database_key = "#USER#default-general-template"

  const actions = useRef(null);
  const onPageBeforeRemove = () => {
    actions.current.destroy();
  };
  const onPageInit = () => {

    haccpUtils.request(f7, {
      url: '/default_general_temp',
      method: "GET",
      contentType: "application/json",
      crossDomain: true,
      headers: {Authorization: "Bearer ..."},
      data: {},
      dataType: "json",
      success: function (body, status, xhr){
        console.log("success...");
        var defaultGeneralTemp = body.data.defaultGeneralTemp;
        defaultGeneralTemp = defaultGeneralTemp.map(child => {
          if(child.details) {
            child.details = child.details.map(detail => {
              if(detail["time_element_id"] == null) detail["time_element_id"] = ""
              return detail
            })
          }
          return child
        })
        storage.setItem(storage_key, defaultGeneralTemp)
      },
      error: function (xhr, status, message) {
        console.log("error...");
      },      
      complete: function (xhr, status) {
        console.log(xhr, status);
        renderList()
      },
      statusCode: {
        401: function(xhr) {
          console.log(xhr)
        }
      },
    });

    actions.current = f7.actions.create({});
  };


  // -----------------------------------------------------------------
// 今回は、baseDefaultGeneralTemplateは、固定とする（未来に変動可能となった場合このマップは不要となり、画面にて動的に追加削除を可能とする予定）
// 本番までに設定ファイルを準備して転記を予定
  const baseDefaultGeneralTemplate = [
    {dgtd_id: 1, item_id: 1, time_element_id: "1,13,14", other_description: "", method_element: "外観、におい、包装の状態、表示(期限、保存方法)を確認する。", problem_occured: "交換または返品する。次回仕入れ時は特に注意して確認する。", disp_seq: 1},
    {dgtd_id: 2, item_id: 2, time_element_id: "2", other_description: "", method_element: "温度計で庫内温度を確認する。冷蔵:10℃以下、冷凍:-15℃以下であることを確認する。", problem_occured: "異常の原因を確認して必要に応じて温度設定の再調整を行う。故障と思われる場合は電器店へ修理依頼をする。食材の状態に応じて廃棄または、加熱して使用する。", disp_seq: 2},
    {dgtd_id: 3, item_id: 3, time_element_id: "17", other_description: "", method_element: "冷蔵庫内の保管の状態を確認する。まな板や包丁などの器具は用途別に使い分け、使用の都度十分に洗浄・消毒する。", problem_occured: "生肉等による汚染があった場合は、加熱して提供するか廃棄する。まな板や包丁などに汚れが残っていた場合は、洗剤で再度洗浄・消毒する。", disp_seq: 3},
    {dgtd_id: 4, item_id: 4, time_element_id: "18", other_description: "", method_element: "使用の都度、まな板、包丁、ボウル等の器具類を洗浄・消毒する。", problem_occured: "使用時に汚れや洗剤などが残っていた場合は再度洗剤洗浄して十分にすすぎを行う。", disp_seq: 4},
    {dgtd_id: 5, item_id: 5, time_element_id: "19", other_description: "", method_element: "トイレの洗浄・消毒状態を定期的に行う。特に男性トイレは床面、洋式は便座、水洗レバー、手すり、ドアノブは入念に確認する。確認後は必ず除菌スプレーの吹き付けを実施する。", problem_occured: "業務中にトイレが汚れていた場合は、洗剤で再度洗浄する。", disp_seq: 5},
    {dgtd_id: 6, item_id: 6, time_element_id: "2,3", other_description: "", method_element: "従業員の体調、手の傷の有無、作業着の汚れの確認を行う。", problem_occured: "消化器症状がある場合は調理作業に従事させない。手に傷がある場合には絆創膏をつけ、その上からゴム手袋を着用させる。汚れた作業着は交換させる。", disp_seq: 6},
    {dgtd_id: 7, item_id: 7, time_element_id: "5,7,8,9,10,15,16", other_description: "", method_element: "衛生的な手洗いを行う。", problem_occured: "作業中に必要なタイミングで手を洗っていないことを確認した場合には、すぐに手洗いを行わせる。", disp_seq: 7},
  ]

// -----------------------------------------------------------------

  const renderList = () => {
    const defaultGeneralTemp = storage.getItemObject(storage_key)
    console.log(defaultGeneralTemp)
    const block_data = (
      <Block className="no-padding">
        <List>
          {defaultGeneralTemp.map(genTemp =>
            <ListItem
              link="#"
              header={genTemp.u_last_nm + genTemp.u_first_nm + ">" + genTemp.create_dttm}
              title={"テンプレVer " + genTemp.id}
              onClick={popupOpenEdit({id: genTemp.id, details: genTemp.details})}
              popupOpen="#haccp-popup"
              iconF7="doc_text"
              after="詳細"
            >
            </ListItem>
          )}
        </List>
      </Block>
    )

    haccpUtils.lazyRender("#pageデフォルト一般テンプレ.page-current form", block_data)
  }

  const storeList = (storeData) => {
    haccpUtils.request(f7, {
      url: '/default_general_temp',
      method: "POST",
      contentType: "application/json",
      crossDomain: true,
      headers: {Authorization: "Bearer ..."},
      data: storeData, // TODO 変更中 (変更済)
      dataType: "json",
      success: function (body, status, xhr){
        console.log("success...");
        storage.setItem(storage_key, body.data.defaultGeneralTemp)
      },
      error: function (xhr, status, message) {
        console.log("error...");
      },      
      complete: function (xhr, status) {
        console.log(xhr, status);
        renderList()
      },
      statusCode: {
        401: function(xhr) {
          console.log(xhr)
        }
      },
    });
  }

  const popupUpdate = (params) => {
    return () => {
      var storedData = haccpUtils.convertFormToData(f7, '#haccp-popup form');
      console.log("storedData")
      console.log(storedData)
      if (storedData) {
        var children = storage.getItemObject(storage_key)
        var databaseData = [{children: children}]
        var isNew = true

        databaseData[0].children.forEach((child, index) => {
          if(child.id == params.id) {
            isNew = false
            databaseData[0].children[index].details = storedData.details
          }
        });

        if(isNew) {
          databaseData[0].children.push(storedData)
        }
        storeList({"template": databaseData[0]})
        storage.setItem(storage_key, databaseData[0].children)

        f7.popup.close("#haccp-popup");
      } else {
        alert('Yet there is no stored data for this form. Please try to change any field')
      }
    }
  }

  const popupOpen = (popupElements, params) => {
    return () => {
      var popup = $$("#haccp-popup")
      const view = (
        <View>
          <Page>
            <Navbar title={popupElements.title}>
              <NavRight>
                <Link popupClose iconF7="xmark"></Link>
              </NavRight>
            </Navbar>
            <form></form>
          </Page>
        </View>
      )
      ReactDOM.render(view, popup[0])

      var form = $$("#haccp-popup form")

      const timeElementOptions = storage.getItemObject("#USER#timeElementOptions");
      const itemOptions = JSON.parse(storage.getItem("#USER#itemOptions"));
      var block = (
        <Block>
          <List sortable sortableOpposite>
            <input type="hidden" name="id" value={params.id} />
            {params.details.map((detail, idx)=>
              <Block>
                <input type="hidden" name={"details["+idx+"].dgtd_id"} value={detail.dgtd_id} />
                {/* disp_seqは要検討 */}
                <input type="hidden" name={"details["+idx+"].disp_seq"} value={detail.disp_seq} />
                <List noHairlinesMd>
                  <ListItem
                    title={"項目" + (idx+1)}
                    smartSelect
                    smartSelectParams={{
                      openIn: 'popup',
                      popupCloseLinkText: '❌',
                    }}
                  >
                    <select name={"details["+idx+"].item_id"} defaultValue={detail.item_id}>
                      {itemOptions.map(option=> 
                        <option value={option.value}>{option.text}</option>
                      )}
                    </select>
                  </ListItem>
                  <ListItem accordionItem title={"項目" + (idx+1) + "詳細"}>
                    <AccordionContent>
                      <ListItem
                        title="いつ"
                        smartSelect
                        smartSelectParams={{
                          openIn: 'popup',
                          searchbar: true,
                          searchbarPlaceholder: '検索キー',
                          popupCloseLinkText: '❌',
                        }}
                      >
                        
                        <select name={"details["+idx+"].time_element_id"} multiple defaultValue={detail.time_element_id.split(",")}>
                        {/* <select name={"details["+idx+"].time_element_id"} multiple defaultValue={(detail.time_element_id || "").split(",")}> */}
                        {timeElementOptions.map(option=> 
                          <option value={option.value}>{option.text}</option>
                        )}
                        </select>
                      </ListItem>
                      <ListInput
                        outline
                        label="その他"
                        type="textarea"
                        name={"details["+idx+"].other_description"}
                        placeholder=""
                        defaultValue={detail.other_description}
                        maxlength="350"
                        required
                      >
                      </ListInput>
                      <ListInput
                        outline
                        label="どのように"
                        type="textarea"
                        name={"details["+idx+"].method_element"}
                        placeholder=""
                        defaultValue={detail.method_element}
                        maxlength="400"
                        required
                      >
                      </ListInput>
                      <ListInput
                        outline
                        label="問題があったとき"
                        type="textarea"
                        name={"details["+idx+"].problem_occured"}
                        placeholder=""
                        defaultValue={detail.problem_occured}
                        maxlength="400"
                        required
                      >
                      </ListInput>
                    </AccordionContent>
                  </ListItem>
                </List>
              </Block>
            )}
            <Block>
              <p>{popupElements.message}</p>
            </Block>
            <Row>
              <Col>
                <Button onClick={popupElements.submit()} fill>{popupElements.button}</Button>
                {/* <Button onClick={popupElements.submit(params)} fill>{popupElements.button}</Button> */}
              </Col>
            </Row>
          </List>
        </Block>
      )
      console.log(form[0])
      ReactDOM.render(block, form[0])
    }
  }

  const popupOpenEdit = (params) => {
    const popupElements = {
      title: "一般管理計画デフォルト共通テンプレートを編集する",
      message: "上記の内容で一般管理計画デフォルト共通テンプレートの修正を完了します。",
      button: "修正を完了する",
      submit: () => {
        return popupUpdate(params)
      }
    }
    return popupOpen(popupElements, params)
  }

  const popupOpenNew = (params) => {
    console.log("popupOpenNew")
    return popupOpen(
      {
        title: "一般管理計画デフォルト共通テンプレートを作成する",
        message: "上記の内容で一般管理計画デフォルト共通テンプレートを保存します。",
        button: "登録",
        submit: () => {
          return popupUpdate(params)
        },
      }, params
    )
  }

  return (
    <Page id="pageデフォルト一般テンプレ" onPageBeforeRemove={onPageBeforeRemove} onPageInit={onPageInit} >
      <Fab position="right-top" slot="fixed" color="teal">
        <Button fill popupOpen="#haccp-popup" onClick={popupOpenNew({id: "?", details: baseDefaultGeneralTemplate})}>
          <Icon ios="f7:plus_square_on_square" aurora="f7:fiber_new" md="material:fiber_new" />
        </Button>
      </Fab>
      <Navbar className="general"> 
        <NavLeft>
          <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" />
        </NavLeft>
        <NavTitle style = {{fontSize: "20px"}}>ﾃﾞﾌｫﾙﾄ一般ﾃﾝﾌﾟﾚｰﾄ</NavTitle>
        <NavRight>
          <Link href="/home/" view=".view-main" panelClose iconF7="house" color="red"></Link>
        </NavRight>
      </Navbar>
      <BlockTitle>一覧</BlockTitle>
      <form></form>
    </Page>
  );
};
export default DefaultGeneralTemplate;