import React, { useRef } from 'react';
import {
  Block,
  BlockTitle,
  Button,
  Col,
  f7,
  Fab,
  Icon,
  Link,
  List,
  ListItem,
  ListInput,
  Navbar,
  NavLeft,
  NavRight,
  NavTitle,
  Page,
  Row,
  View,
} from 'framework7-react';

import Dom7 from 'dom7';
import ReactDOM from 'react-dom';

import '../css/sortable.css';
import '../css/grid.css'

import haccpUtils from '../js/haccp-utils.js';

const AccountMst = () => {
  var $$ = Dom7

  const storage = haccpUtils.getStorage();
  const actions = useRef(null);

  const account_storage_key = "#USER#account-mst"
  const user_storage_key = "#USER#user-mst"

  const onPageBeforeRemove = () => {
    actions.current.destroy();
  };
  const onPageInit = () => {
    haccpUtils.request(f7, {
      url: '/account_mst',
      method: "GET",
      contentType: "application/json",
      crossDomain: true,
      headers: {Authorization: "Bearer ..."},
      data: {},
      dataType: "json",
      success: function (body, status, xhr){
        renderInit(body.data.accountMst)
        storage.setItem(account_storage_key, body.data.accountMst)
        initUserData()
      },
      error: function (xhr, status, message) {
        console.log("error...");
      },      
      complete: function (xhr, status) {
        console.log(xhr, status);
      },
      statusCode: {
        401: function(xhr) {
          console.log(xhr)
        }
      },
    });
    actions.current = f7.actions.create({});
  };

  const initUserData = () => {
    haccpUtils.request(f7, {
      url: '/user_mst',
      method: "GET",
      contentType: "application/json",
      crossDomain: true,
      headers: {Authorization: "Bearer ..."},
      data: {},
      dataType: "json",
      success: function (body, status, xhr){
        storage.setItem(user_storage_key, body.data.userMst)
      },
      error: function (xhr, status, message) {
        console.log("error...");
      },      
      complete: function (xhr, status) {
        console.log(xhr, status);
      },
      statusCode: {
        401: function(xhr) {
          console.log(xhr)
        }
      },
    });
    actions.current = f7.actions.create({});
  }


// DB側で処理を切り替えると不要となる 
  const selectClass = (role_id) => {
    return (role_id == "113") ? (
      "haccp-role-invisible haccp-role-system"
    ) : (
      (role_id == "112") ? (
            "haccp-role-invisible haccp-role-admin"
      ) : (
        (role_id == "64") ? (
              "haccp-role-invisible haccp-role-shop haccp-role-user"
        ) : (
              "haccp-role-invisible haccp-role-shop haccp-role-user"
        )
      )
    )
  }
// DB側で処理を切り替えると不要となる 

  const renderInit = (accountMst) => {
    const block_data = (
      <Block strong className="no-padding">
        <List>
          {accountMst.map(account =>
            <ListItem
              link="#"
              header={"ID:" + account.id + "、店舗名:" + account.shop_name + "(店舗ID:" + account.shop_id + ")"}
              title={account.last_name + " " + account.first_name + "(" + account.last_name_kana + " " + account.first_name_kana + ")"}
              className={selectClass(account.role_id) + " mst-list-item"}
              onClick={popupOpen({
                title: "アカウントマスタを編集する",
                message: "アカウントの修正を完了します。",
              }, account)}
              popupOpen="#haccp-popup"
              iconF7="doc_text"
            // after="詳細"
            >
            </ListItem>
          )}
        </List>
      </Block>
    )
    var block = $$("#accountMstList")
    ReactDOM.render(block_data, block[0])
  }

  const popupClose = () => {
    var storedData = f7.form.convertToData('#haccp-popup form');
    if(storedData) {
      var databaseData = storage.getItemObject(account_storage_key)
      console.log(storedData)

      var isNew = true
      for(let idx in databaseData) {
        if(databaseData[idx].id == storedData.id) {
          databaseData[idx] = storedData
          isNew = false
        }
      }
      if(isNew) {
        databaseData.push(storedData)
      }

      haccpUtils.request(f7, {
        url: '/account_mst',
        method: "POST",
        contentType: "application/json",
        crossDomain: true,
        headers: {Authorization: "Bearer ..."},
        data: {accountMst: storedData},
        dataType: "json",
        success: function (body, status, xhr){
          console.log("success...");
          renderInit(body.data.result)
          storage.setItem(account_storage_key, body.data.result)
        },
        error: function (xhr, status, message) {
          console.log("error...");
        },      
        complete: function (xhr, status) {
          console.log(xhr, status);
        },
        statusCode: {
          401: function(xhr) {
            console.log(xhr)
          }
        },
      });

      storage.setItem(account_storage_key, databaseData)
      f7.popup.close("#haccp-popup");
    } else {
        alert('Yet there is no stored data for this form. Please try to change any field')
    }
  }

  const popupOpen = (popupElements, account) => {
    return () => {
      var view = (
        <View>
          <Page>
            <Navbar title={popupElements.title}>
              <NavRight>
                <Link popupClose iconF7="xmark"></Link>
              </NavRight>
            </Navbar>
            <form></form>
          </Page>
        </View>
      )

      const userMst = storage.getItemObject(user_storage_key);
      const shopOptions = storage.getItemObject("#USER#shopOptions");
      // 今回は使用しない。また、storageにもdepartment情報は保持されていないので
      // 使用する際は、まずapp.jsxで取得してstorageに保持する必要がある。
      // const departmentOptions = storage.getItemObject("#USER#departmentOptions");

      var block = (
        <Block>
          <List noHairlinesMd>
            <ListInput
              outline
              label="アカウントID	"
              type="text"
              name="id"
              defaultValue={account.id}
              disabled
            ></ListInput>
            <ListItem
              title="ユーザID"
              smartSelect
              smartSelectParams={{
                openIn: 'popup',
                searchbar: true,
                searchbarPlaceholder: 'ユーザ検索',
                popupCloseLinkText: '❌',
              }}
            >
              <select name="user_id" defaultValue={account.user_id}>
                <optgroup label="">
                  {userMst.map(user =>
                    <option value={user.id}>{user.last_name} {user.first_name}({user.last_name_kana} {user.first_name_kana})</option>
                  )}
                </optgroup>
              </select>
            </ListItem>
            <ListItem
              title="店舗ID"
              smartSelect
              smartSelectParams={{
                openIn: 'popup',
                searchbar: true,
                searchbarPlaceholder: '店舗検索',
                popupCloseLinkText: '❌',
              }}
            >
              <select name="shop_id" defaultValue={account.shop_id}>
                <optgroup label="">
                  {shopOptions.map(option =>
                    <option value={option.value}>{option.text}</option>
                  )}
                </optgroup>
              </select>
            </ListItem>

            {/* 今回は、部門を意識しないため、入力フィールドはコメントにし、固定で１を投入 */}
            <input type="hidden" name="department_id" value="1" />
            {/* <ListItem
              title="部門ID"
              smartSelect
              smartSelectParams={{
                openIn: 'popup',
                searchbar: true,
                searchbarPlaceholder: '部門検索',
                popupCloseLinkText: '❌',
              }}
            >
              <select name="department_id" defaultValue={account.department_id}>
                <optgroup label="">
                  {departmentOptions.map(option =>
                    <option value={option.id}>{option.name}</option>
                  )}
                </optgroup>
              </select>
            </ListItem> */}

            <ListInput
              maxlength="8"
            ></ListInput>
            <ListInput
              outline
              label="権限ID"
              type="select"
              name="role_id"
              placeholder="Please choose..."
              defaultValue={account.role_id}
            >
              <option className="haccp-role-invisible haccp-role-shop haccp-role-user" value="64">ゲストユーザ</option>
              {/* <option className="haccp-role-invisible haccp-role-shop haccp-role-user" value="64">一般ユーザ</option> */}
              <option className="haccp-role-invisible haccp-role-admin" value="112">運用管理者</option>
              <option className="haccp-role-invisible haccp-role-system" value="113">システム管理者</option>
            </ListInput>
            <ListInput
              outline
              label="備考"
              type="textarea"
              name="remark"
              placeholder="備考"
              defaultValue={account.remark}
              maxlength="200"
              ></ListInput>
            <ListInput
              outline
              label="削除フラグ"
              type="select"
              name="del_flg"
              placeholder="Please choose..."
              defaultValue={account.del_flg}
            >
              <option value="0">有効</option>
              <option value="1">削除</option>
            </ListInput>
          </List>
          <p>{popupElements.message}</p>
          <Row>
            <Col>
              <Button onClick={popupClose} fill>保存</Button>
            </Col>
          </Row>
        </Block>
      )
      ReactDOM.render(view, document.getElementById("haccp-popup"))
      var form = $$("#haccp-popup form")
      ReactDOM.render(block, form[0])
      return null
    }
  }

  return (
    <Page onPageBeforeRemove={onPageBeforeRemove} onPageInit={onPageInit} >
      <Fab position="right-top" slot="fixed" color="teal">
        <Button fill popupOpen="#haccp-popup" onClick={popupOpen({
          title: "アカウントマスタを登録する",
          message: "アカウントの新規登録を完了します。",
        }, {
          id: "",
          user_id: "",
          shop_id: "",
          department_id: "",
          role_id: "",
          remark: "",
          del_flg: 0,
        })}>
          <Icon ios="f7:plus_square_on_square" aurora="f7:fiber_new" md="material:fiber_new" />
        </Button>
      </Fab>
      <Navbar className="mst">
        <NavLeft>
          <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" />
        </NavLeft>
        <NavTitle style = {{fontSize: "20px"}}>アカウントマスタ</NavTitle>
        {/* <NavRight>
          <Link href="/home/" view=".view-main" panelClose iconF7="house"></Link>
        </NavRight> */}
      </Navbar>
      <BlockTitle>一覧</BlockTitle>
      <Block id="accountMstList" className="mst-list"></Block>
    </Page>
  );
};
export default AccountMst;
