import React, { useRef } from 'react';
import {
  Block,
  BlockTitle,
  Button,
  Col,
  f7,
  Fab,
  Icon,
  List,
  ListItem,
  ListInput,
  Link,
  Navbar,
  NavLeft,
  NavRight,
  NavTitle,
  Page,
  Row,
  View,
} from 'framework7-react';

import Dom7 from 'dom7';
import ReactDOM from 'react-dom';

import '../css/sortable.css';
import '../css/grid.css'

import haccpUtils from '../js/haccp-utils.js';

const UserMst = () => {
  var $$ = Dom7

  const storage = haccpUtils.getStorage();
  const actions = useRef(null);

  const storage_key = "#USER#user-mst"

  const onPageBeforeRemove = () => {
    actions.current.destroy();
  };
  const onPageInit = () => {
    actions.current = f7.actions.create({});
    haccpUtils.request(f7, {
      url: '/user_mst',
      method: "GET",
      contentType: "application/json",
      crossDomain: true,
      headers: {Authorization: "Bearer ..."},
      data: {},
      dataType: "json",
      success: function (body, status, xhr){
        renderInit(body.data.userMst)
        storage.setItem(storage_key, body.data.userMst)
      },
      error: function (xhr, status, message) {
        console.log("error...");
      },      
      complete: function (xhr, status) {
        console.log(xhr, status);
      },
      statusCode: {
        401: function(xhr) {
          console.log(xhr)
        }
      },
    });

    actions.current = f7.actions.create({});
  };

  const renderInit = (userMst) => {
    const block_data = (
      <Block strong className="no-padding">
        <List>
          {userMst.map(user =>
            <ListItem
              className="mst-list-item"
              link="#"
              header={"ID:" + user.id}
              title={user.last_name + " " + user.first_name + "(" + user.last_name_kana + " " + user.first_name_kana + ")"}
              onClick={popupOpen({
                title: "ユーザマスタを編集する",
                message: "ユーザの修正を完了します。",
              }, user)}
              popupOpen="#haccp-popup"
              iconF7="doc_text"
              // after="詳細"
            >
            </ListItem>
          )}
        </List>
      </Block>
    )
    var block = $$("#userMstList")
    ReactDOM.render(block_data, block[0])
  }

  const popupClose = () => {
    var storedData = f7.form.convertToData('#haccp-popup form');
    if(storedData) {
      var databaseData = storage.getItemObject(storage_key)
      // ageは、DBでint型のため空文字を入れるとエラーとなるためnullに置き換える
      if(storedData.age == ''){storedData.age = null}
      console.log('storedData', storedData)
      var isNew = true
      for(let idx in databaseData) {
        if(databaseData[idx].id == storedData.id) {
          databaseData[idx] = storedData
          isNew = false
        }
      }
      if(isNew) {
        databaseData.push(storedData)
      }

      haccpUtils.request(f7, {
        url: '/user_mst',
        method: "POST",
        contentType: "application/json",
        crossDomain: true,
        headers: {Authorization: "Bearer ..."},
        data: {userMst: storedData},
        dataType: "json",
        success: function (body, status, xhr){
          console.log("success...");
          renderInit(body.data.result)
          storage.setItem(storage_key, body.data.result)
        },
        error: function (xhr, status, message) {
          console.log("error...");
        },      
        complete: function (xhr, status) {
          console.log(xhr, status);
        },
        statusCode: {
          401: function(xhr) {
            console.log(xhr)
          }
        },
      });

      storage.setItem(storage_key, databaseData)
      f7.popup.close("#haccp-popup");
    } else {
        alert('Yet there is no stored data for this form. Please try to change any field')
    }
  }

  const popupOpen = (popupElements, user) => {
    return () => {
      var view = (
        <View>
          <Page>
            <Navbar title={popupElements.title}>
              <NavRight>
                <Link popupClose iconF7="xmark"></Link>
              </NavRight>
            </Navbar>
            <form></form>
          </Page>
        </View>
      )

      var block = (
        <Block>
        <List noHairlinesMd>
          <ListInput
            // outline
            // label="ユーザID"
            type="text"
            name="id"
            placeholder="ユーザID"
            defaultValue={user.id}
            disabled
          ></ListInput>
          <ListInput
            // outline
            // label="メールアドレス"
            type="email"
            name="email"
            placeholder="メールアドレス"
            defaultValue={user.email}
            maxlength="255"
            required
          ></ListInput>
          <ListInput
            // outline
            // label="パスワード"
            type="password"
            name="password"
            placeholder="パスワード"
            defaultValue={user.password}
            maxlength="50"
            required
          ></ListInput>
          <ListInput
            // outline
            // label="郵便番号"
            type="text"
            name="zip"
            placeholder="郵便番号 1060032(- なし)"
            validate
            pattern="[0-9]*"
            data-error-message="入力できるのは数値のみです"
            defaultValue={user.zip}
            maxlength="7"
          ></ListInput>
          <ListInput
            // outline
            // label="住所"
            type="textarea"
            name="addr"
            placeholder="住所"
            defaultValue={user.addr}
            maxlength="255"
          ></ListInput>
          <ListInput
            // outline
            // label="姓"
            type="text"
            name="last_name"
            placeholder="姓"
            defaultValue={user.last_name}
            maxlength="100"
          ></ListInput>
          <ListInput
            // outline
            // label="名"
            type="text"
            name="first_name"
            placeholder="名"
            defaultValue={user.first_name}
            maxlength="100"
          ></ListInput>
          <ListInput
            // outline
            // label="姓（カナ）"
            type="text"
            name="last_name_kana"
            placeholder="姓（カナ）"
            defaultValue={user.last_name_kana}
            maxlength="100"
          ></ListInput>
          <ListInput
            // outline
            // label="名（カナ）"
            type="text"
            name="first_name_kana"
            placeholder="名（カナ）"
            defaultValue={user.first_name_kana}
            maxlength="100"
          ></ListInput>
          <ListInput
            // outline
            // label="年齢"
            type="text"
            name="age"
            placeholder="年齢"
            validate
            pattern="[0-9]*"
            data-error-message="入力できるのは数値のみです"
            defaultValue={user.age}
            maxlength="3"
          ></ListInput>
          <ListInput
            // outline
            // label="電話番号"
            type="tel"
            name="tel"
            placeholder="電話番号"
            defaultValue={user.tel}
            maxlength="20"
          ></ListInput>
          <ListInput
            // outline
            // label="FAX番号"
            type="tel"
            name="fax"
            placeholder="FAX番号"
            defaultValue={user.fax}
            maxlength="20"
          ></ListInput>
          <ListInput
            // outline
            // label="携帯番号"
            type="tel"
            name="mobile"
            placeholder="携帯番号"
            defaultValue={user.mobile}
            maxlength="20"
          ></ListInput>
          <ListInput
            // outline
            // label="有効状態"
            type="text"
            name="availability"
            placeholder="有効状態"
            defaultValue={user.availability}
            validate
            pattern="[0-9]*"
            data-error-message="入力できるのは数値のみです"
            maxlength="11"
            readonly={true}
          ></ListInput>
          <input type="hidden" name="valid_code" value={user.valid_code} />
          {/* <ListInput
            outline
            label="確認コード"
            type="text"
            name="valid_code"
            placeholder="確認コード"
            defaultValue={user.valid_code}
            validate
            pattern="[0-9]*"
            data-error-message="入力できるのは数値のみです"
            maxlength="32"
          ></ListInput> */}
          <ListInput
            // outline
            // label="備考"
            type="textarea"
            name="remark"
            placeholder="備考"
            defaultValue={user.remark}
            maxlength="200"
          ></ListInput>
          <ListInput
            // outline
            // label="削除フラグ"
            type="select"
            name="del_flg"
            placeholder="削除フラグ"
            defaultValue={user.del_flg}
          >
            <option value="0">有効</option>
            <option value="1">削除</option>
          </ListInput>
        </List>
        <p>{popupElements.message}</p>
        <Row>
          <Col>
            <Button onClick={popupClose} fill>保存</Button>
          </Col>
        </Row>
      </Block>
      )
      ReactDOM.render(view, document.getElementById("haccp-popup"))
      var form = $$("#haccp-popup form")
      ReactDOM.render(block, form[0])
      return null
    }
  }

  return (
    <Page onPageBeforeRemove={onPageBeforeRemove} onPageInit={onPageInit} >
      <Fab position="right-top" slot="fixed" color="teal">
        <Button fill popupOpen="#haccp-popup" onClick={popupOpen({
          title: "ユーザマスタを登録する",
          message: "ユーザの新規登録を完了します。",
        }, {
          id: "",
          password: "",
          zip: "",
          addr: "",
          last_name: "",
          first_name: "",
          last_name_kana: "",
          first_name_kana: "",
          age: null,
          tel: "",
          fax: "",
          mobile: "",
          email: "",
          availability: "100",
          valid_code: "",
          remark: "admin input",
          del_flg: 0,
        })}>
          <Icon ios="f7:plus_square_on_square" aurora="f7:fiber_new" md="material:fiber_new" />
        </Button>
      </Fab>
      <Navbar className="mst">
        <NavLeft>
          <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" />
        </NavLeft>
        <NavTitle style = {{fontSize: "20px"}}>ユーザマスタ</NavTitle>
        {/* <NavRight>
          <Link href="/home/" view=".view-main" panelClose iconF7="house"></Link>
        </NavRight> */}
      </Navbar>
      <BlockTitle>一覧</BlockTitle>
      <Block id="userMstList" className="mst-list"></Block>
    </Page>
  );
};
export default UserMst;
