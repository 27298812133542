import React,{ useRef } from 'react';
import {
  Page,
  Navbar,
  NavLeft,
  NavRight,
  NavTitle,
  Block,
  List,
  ListItem,
  f7,
  Button,
  ListInput,
  Link,
  Row,
  Col,
  Swiper,
  SwiperSlide,
  ListButton,
  View,
  Icon,
  Fab,
} from 'framework7-react';

import Dom7 from 'dom7';
import ReactDOM from 'react-dom';
import '../css/grid.css'
import haccpUtils from '../js/haccp-utils.js';

const TodayImportantCtrl = () => {
  var $$ = Dom7
  const storage = haccpUtils.getStorage();
  const storage_key = "#USER#important-hygienic-ctrl-plan"

  const current_storage_key = "#USER#CurrentImportantCtrl";
  var currentImportantCtrl = storage.hasItem(current_storage_key) ? storage.getItemObject(current_storage_key) : {
    work_year: -1,
    work_month: -1,
    work_day: -1,
  }
  if(currentImportantCtrl.work_year == -1) {
    var a = haccpUtils.getToday("yyyy/m/d").split("/")
    currentImportantCtrl.work_year = a[0]
    currentImportantCtrl.work_month = a[1]
    currentImportantCtrl.work_day = a[2]
  }

  var 年月日 = currentImportantCtrl.work_year + "年" + currentImportantCtrl.work_month + "月"　+ currentImportantCtrl.work_day + "日"

  const ctrl_storage_key = "#USER#important-hygienic-ctrl"
  const actions = useRef(null);
  const onPageBeforeRemove = () => {
    actions.current.destroy();
  };

  const initProcess1 = () => {
    haccpUtils.request(f7, {
      url: '/important_hygienic_ctrl',
      method: "GET",
      contentType: "application/json",
      crossDomain: true,
      headers: {Authorization: "Bearer ..."},
      data: {},
      dataType: "json",
      success: function (body, status, xhr){
        storage.setItem(ctrl_storage_key, body.data.importantCtrl)
        initProcess2()
      },
      error: function (xhr, status, message) {
        console.log("error...");
      },      
      complete: function (xhr, status) {
        console.log(xhr, status);
      },
      statusCode: {
        401: function(xhr) {
          console.log(xhr)
        }
      },
    });
  }

  const initProcess2 = () => {
    haccpUtils.request(f7, {
      url: '/important_hygienic_ctrl_plan',
      method: "GET",
      contentType: "application/json",
      crossDomain: true,
      headers: { Authorization: "Bearer ..." },
      data: {},
      dataType: "json",
      success: function (body, status, xhr) {
        console.log("importantPlan");
        console.log(body.data.importantPlan);
        const importantPlan = body.data.importantPlan[0];
        render管理計画初期表示(currentImportantCtrl, importantPlan)
      },
      error: function (xhr, status, message) {
        console.log("error...:" + status);
      },
      complete: function (xhr, status) {
        console.log(xhr, status);
      },
      statusCode: {},
    });
  }

  const onPageInit = () => {
    actions.current = f7.actions.create({});
    initProcess1()
  };

  const render管理計画初期表示 = (currentImportantCtrl, importantPlan) => {

    const importantCtrl = storage.getItemObject(ctrl_storage_key)
    const validImportantPlan = importantPlan.children.filter(plan => {return plan.del_flg == 0})

    console.log('currentImportantCtrl')
    console.log(currentImportantCtrl)
    console.log('validImportantPlan')
    console.log(validImportantPlan)
    console.log('importantCtrl')
    console.log(importantCtrl)
    
    var important_hygienic_ctrl = null
    var detail = null
    var account = haccpUtils.getLoginAccount()
    var name = "ゲスト"
    if(account && account.user) name = account.user.last_name + account.user.first_name

    if(importantCtrl.length == 0) {
      // 親新規、子新規
      important_hygienic_ctrl = {id: "?", details: []}
      detail = {id: "?",daily_check: name,admin_memo: name}
    } else {
      var children = importantCtrl[0].children.filter(child => {
        return child.work_year == currentImportantCtrl.work_year && child.work_month == currentImportantCtrl.work_month
          && child.details.filter(detail => {return detail.work_day == currentImportantCtrl.work_day}).length > 0
      })

      if(children.length == 1) {
      // 子あり
          // 親あり、子あり
          important_hygienic_ctrl = children[0]
          const details = important_hygienic_ctrl.details.filter(detail => {
            return detail.work_day == currentImportantCtrl.work_day;
          })
          detail = details[0]
      } else {
      // 子なし
          children = importantCtrl[0].children.filter(child => {
          return child.work_year == currentImportantCtrl.work_year && child.work_month == currentImportantCtrl.work_month
                    && validImportantPlan[0].temp_version == child.temp_version
                    && validImportantPlan[0].plan_version == child.plan_version
          })
          if (children.length == 1) {
          // 親あり、子新規
            // 指定した日付が存在しない場合、新規追加となるため、detailのidに "?" を設定
            important_hygienic_ctrl = children[0]
            detail = {id: "?",daily_check: name,admin_memo: name}
          } else {
          // 親新規、子新規
            important_hygienic_ctrl = {id: "?", details: []}
            detail = {id: "?",daily_check: name,admin_memo: name}
          }
      }
    }

    console.log('important_hygienic_ctrl')
    console.log(important_hygienic_ctrl)
    console.log('detail')
    console.log(detail)

    // 指定した日付が存在せず新規追加の場合(id == "?")、最新の計画(del_flg == 0)の計画を検索
    // 指定した日付が存在し、idが取得できた場合、そのidの持つ計画id(important_hygienic_ctrl_plan_id)の計画を検索
    var plan = importantPlan;
    if(detail.id == "?") {
      importantPlan = validImportantPlan
      if(importantPlan.length != 1) throw Error("システムエラー：有効な管理計画のデータに不備があります。致命的なエラーです")
      plan = importantPlan[0]
    } else {
      importantPlan = importantPlan.children.filter(plan => {return plan.id == detail.important_hygienic_ctrl_plan_id})
      if(importantPlan.length != 1) throw Error("システムエラー：指定された管理計画のデータに不備があります。致命的なエラーです")
      plan = importantPlan[0]
    }

    console.log('importantPlan')
    console.log(importantPlan)
    console.log('plan')
    console.log(plan)
    
    // detail   指定された日付の管理記録情報。新規の場合は空
    // plan     指定された日付の管理記録で参照された管理計画情報。
    // currentImportantCtrl ログイン時の日付及びログイン時の最新管理計画情報等

    const hygienic_ctrl_work = (category_id,detail) => {
      switch (category_id){
        case '1': return detail.hygienic_ctrl_work_1;
        case '2': return detail.hygienic_ctrl_work_2;
        case '3': return detail.hygienic_ctrl_work_3;
        case '4': return detail.hygienic_ctrl_work_4;
        case '5': return detail.hygienic_ctrl_work_5;
        case '6': return detail.hygienic_ctrl_work_6;
        case '7': return detail.hygienic_ctrl_work_7;
        default: return ""
      }
    }

    const hygienic_ctrl_work_val = (category_id,detail) => {
      switch (category_id){
        case '1': return detail.hygienic_ctrl_work_1_val;
        case '2': return detail.hygienic_ctrl_work_2_val;
        case '3': return detail.hygienic_ctrl_work_3_val;
        case '4': return detail.hygienic_ctrl_work_4_val;
        case '5': return detail.hygienic_ctrl_work_5_val;
        case '6': return detail.hygienic_ctrl_work_6_val;
        case '7': return detail.hygienic_ctrl_work_7_val;
        default: return ""
      }
    }

    const block_data = (
      <Block>
        <input type="hidden" name="id" value={detail.id} />
        {/* <input type="hidden" name="using_temp_version" value={detail.temp_version} /> */}

        <input type="hidden" name="important_hygienic_ctrl_plan_id" value={plan.id} />
        <input type="hidden" name="shop_id" value={plan.shop_id} />
        <input type="hidden" name="department_id" value={plan.department_id} />
        <input type="hidden" name="plan_version" value={plan.plan_version} />
        <input type="hidden" name="temp_version" value={plan.temp_version} />

        <input type="hidden" name="work_year" value={currentImportantCtrl.work_year} />
        <input type="hidden" name="work_month" value={currentImportantCtrl.work_month} />
        <input type="hidden" name="work_day" value={currentImportantCtrl.work_day} />

        <input type="hidden" name="important_hygienic_ctrl_id" value={important_hygienic_ctrl.id} />

        <List>
          <Swiper pagination scrollbar>
            {plan.details.map((item, idx) =>
                <SwiperSlide>
                  <div width="300px" class="overflow-wrap" style = {{fontSize: "18px"}}>
                    <Icon f7="doc_checkmark" size="21px" color="blue"></Icon>{item.category_name}
                  </div>
                  <List noHairlinesMd>
                    <ListButton
                      className="daily-create-button"
                      onClick={() => {
                        openImportantCtrlTodayCreate(item, {
                          hygienic_ctrl_work: "hygienic_ctrl_work_" + item.category_id,
                        })
                        f7.dialog.open("#haccp-popup")
                      }}
                    >
                      <ListInput
                        className="daily-create-window"
                        type="text"
                        defaultValue="一日の結果"
                        readonly={true}
                        style = {{fontSize: "18px"}}
                        />
                      <input type="hidden" name={"hygienic_ctrl_work_" + item.category_id}  value = {hygienic_ctrl_work(item.category_id, detail)}/>
                      <ListInput
                        className="daily-create-window"
                        name={"hygienic_ctrl_work_" + item.category_id + "_val"}
                        placeholder="未記入・未確認"
                        type="text"
                        defaultValue = {hygienic_ctrl_work_val(item.category_id, detail)}
                        readonly={true}
                        inputStyle = { (hygienic_ctrl_work(item.category_id, detail) == "1" ) ? {color: "blue"} : (hygienic_ctrl_work(item.category_id, detail) == "2" ) ? {color: "red"} : {color: "Block" }}
                      />
                    </ListButton>
                  </List>
                  <div width="300px" class="overflow-wrap" style = {{fontSize: "18px", margin: "25px 0px 25px 0px"}}>
                    <Icon f7="doc_text" size="21px" color="blue"></Icon>管理計画
                  </div>
                  <List noHairlinesMd>
                    <ListButton
                      onClick={() => {
                        openImportantPlanDetail(item, {
                          menu: "menu_" + item.id,
                          check_method: "check_method_" + item.id,
                        })
                        f7.dialog.open("#haccp-popup")
                      }}
                    >
                      <ListInput
                        name={"menu_" + item.id}
                        label="メニュー"
                        // floatingLabel
                        type="textarea"
                        defaultValue={item.menu}
                        readonly={true}
                        />
                      <ListInput
                        className="check-method"
                        name={"check_method_" + item.id}
                        label="チェック方法"
                        // floatingLabel
                        type="textarea"
                        defaultValue={item.check_method}
                        readonly={true}
                      />
                    </ListButton>
                  </List>
                </SwiperSlide>
              )}
              <SwiperSlide>
                <List noHairlinesMd>
                  <ListInput
                    label="日々チェック"
                    type="text"
                    name="daily_check"
                    placeholder="日々チェック"
                    defaultValue={detail.daily_check}
                    maxlength="40"
                    >
                  </ListInput>
                  <ListInput
                    label="特記事項"
                    type="textarea"
                    name="memo"
                    placeholder="特記事項"
                    defaultValue={detail.memo}
                    maxlength="200"
                    >
                  </ListInput>
                  <ListInput
                    label="確認者"
                    type="text"
                    name="admin_memo"
                    placeholder="確認者"
                    defaultValue={detail.admin_memo}
                    maxlength="200"
                    >
                  </ListInput>
                </List>
                <Block strong>
                  <Row>
                    <Col>
                      <Button color="blue" onClick={(e) => {
                        if(!haccpUtils.validateForm($$(e.target).closest("form"), e.target)) return false;
                        storeData(
                          () => {
                            haccpUtils.modifyGeneralCtrlDate(currentImportantCtrl.shop_id, currentImportantCtrl.work_year, currentImportantCtrl.work_month, currentImportantCtrl.work_day)
                            f7.views.get(".view-main").router.navigate({ name: 'TodayGeneralCtrl' });
                          }
                        );
                      }} style = {{fontSize: "21px"}}>重要記録を保存して終了</Button>
                    </Col>
                  </Row>
                </Block>

                {/* <Link text="保存" color="blue" onClick={storeData}></Link>
                <Link
                  panelClose text="一般"
                  color="red"
                  onClick={() => {
                    haccpUtils.modifyImportantCtrlDate(currentImportantCtrl.shop_id, currentImportantCtrl.work_year, currentImportantCtrl.work_month, currentImportantCtrl.work_day)
                    f7.views.get(".view-main").router.navigate({ name: 'TodayGeneralCtrl' });
                  }}
                ></Link> */}

              </SwiperSlide>
            </Swiper>
          </List>                       
      </Block>
    )
    haccpUtils.lazyRender("#page_重要記録.page-current form", block_data)
  }

  const popupClose = (params) => {
    return () => {
      var storedData = f7.form.convertToData('form');
      if(storedData) {
        var databaseData = storage.getItemObject(storage_key, defaultData)
        databaseData[params.id] = storedData
        storage.setItem(storage_key, databaseData)
        f7.popup.close("#haccp-popup");
      } else {
         alert('Yet there is no stored data for this form. Please try to change any field')
      }
    }
  }


  {/* 重要管理計画内容_項目別 */}
  const openImportantPlanDetail = (item, elems) => {
    const onClick = () => {
      var data = f7.form.convertToData("#haccp-popup form");
      for(let name in elems) {
        $$(".page-current [name='" + elems[name] + "']").val(data[name])
      }
      var sendData = data;
      sendData.id = item.id;
      console.log('sendData')
      console.log(sendData)
      storePlanData(sendData)
    }
    var view = (
      <View>
      <Page>
        <Navbar title={年月日 + " " + item.category_name}>
          <NavRight>
            <Link popupClose iconF7="xmark"></Link>
          </NavRight>
        </Navbar>
        <form></form>
        </Page>
      </View>
    )
    ReactDOM.render(view, document.getElementById("haccp-popup"))
    var form = $$("#haccp-popup form")
    var values = {}
    for(let name in elems) {
      values[name] = $$(".page-current [name='" + elems[name] + "']").val()
    }
    ReactDOM.render(blockImportantPlanDetail(values, onClick), form[form.length-1])
  }

  const blockImportantPlanDetail = (item, onclick) => {
    return (
      <Block>
        <List noHairlinesMd>
          <div style = {{fontSize: "14px", color: "red", margin: "5px 10px 15px 10px"}}>こちらは、管理計画の編集画面となります。この計画で記録を行った過去の計画も更新されますのでご注意下さい。新規で計画を作成する際は、一度キャンセルにてポップアップ画面を閉じ、画面左上のメニューボタンにてメニューを開いて重要衛生管理計画画面へと進み新規作成を行って下さい。</div>
        </List>
        <List noHairlinesMd>
          <ListInput
            label="メニュー"
            // floatingLabel
            type="textarea"
            name="menu"
            defaultValue={item.menu}
            maxlength="400"
            placeholder
          />
          <ListInput
            label="チェック方法"
            // floatingLabel
            type="textarea"
            className="check-method-popup"
            name="check_method"
            defaultValue={item.check_method}
            maxlength="400"
            placeholder
          />
        </List>
        <Block strong>
          <Row noGap>
            <Col><Button onClick = {onclick} popupClose style = {{fontSize: "21px"}}>編集を保存</Button></Col>
            <Col><Button popupClose style = {{fontSize: "21px"}}>キャンセル</Button></Col>
          </Row>
        </Block>
      </Block>
    )
  }

  {/* 重要衛生管理実施当日記録_項目別 */}
  const openImportantCtrlTodayCreate = (item, elems) => {
    const onClick = () => {
      var data = f7.form.convertToData("#haccp-popup form");
      for(let name in elems) {
        $$(".page-current [name='" + elems[name] + "']").val(data[name])
        $$(".page-current [name='" + elems[name] + "_val']").val( data[name] == 1 ? "良" : data[name] == 2 ? "否" : "")
        $$(".page-current [name='" + elems[name] + "_val']").prop("style", data[name] == 1 ? "color: blue" : data[name] == 2 ? "color: red" : "color: ")
      }
    }
    var view = (
      <View>
        <Page>
          <Navbar title={年月日 + " " + item.category_name}>
            <NavRight>
              <Link popupClose iconF7="xmark"></Link>
            </NavRight>
          </Navbar>
          <form></form>
        </Page>
      </View>
    )
    ReactDOM.render(view, document.getElementById("haccp-popup"))
    var form = $$("#haccp-popup form")
    var values = {category_id: item.category_id}
    for(let name in elems) {
      values[name] = $$(".page-current [name='" + elems[name] + "']").val()
    }
    ReactDOM.render(blockImportantCtrlTodayCreate(values, onClick), form[form.length-1])
  }
  const blockImportantCtrlTodayCreate = (item, onclick) => {
    return (
      <Block>
        {(() => {
          if (item.category_id == 0) {
            return (
              <List>
                <ListInput
                  label="(°C)"
                  type="text"
                  name="hygienic_ctrl_work"
                  defaultValue={item.hygienic_ctrl_work}
                  maxlength="20"
                  >
                </ListInput>
              </List>
            )
          } else {
            return (
              <List>
                <ListItem
                  radio
                  radioIcon="start"
                  title="良"
                  value="1"
                  defaultChecked={item.hygienic_ctrl_work == 1}
                  name="hygienic_ctrl_work"
                  style = {{color: "blue", fontSize: "21px"}}
                ></ListItem>
                <ListItem
                  radio
                  radioIcon="start"
                  title="否"
                  value="2"
                  defaultChecked={item.hygienic_ctrl_work == 2}
                  name="hygienic_ctrl_work"
                  style = {{color: "red", fontSize: "21px"}}
                ></ListItem>
              </List>
            )
          }
        })()}
        <Block strong>
          <Row noGap>
            <Col><Button onClick={onclick} popupClose style = {{fontSize: "21px"}}>記録</Button></Col>
            <Col><Button popupClose style = {{fontSize: "21px"}}>キャンセル</Button></Col>
          </Row>
        </Block>
      </Block>
    )
  }

  const storeData = (complete) => {
    var storedData = f7.form.convertToData("#page_重要記録.page-current form")
    console.log('storedData')
    console.log(storedData)
    
    var plan = [];
    var id = 0;
    var idx = 0;
    var name = "";

    currentImportantCtrl = storedData

    console.log("currentImportantCtrl")
    console.log(currentImportantCtrl)
    haccpUtils.updateImportantCtrlDate(f7, currentImportantCtrl, complete)
  }

  const storePlanData = (planItem) => {
    var storePlanData = f7.form.convertToData("#page_重要記録.page-current form")
    console.log('storePlanData')
    console.log(storePlanData)
    console.log('planItem')
    console.log(planItem)
    
    var plan = [];
    var id = 0;
    var idx = 0;
    var name = "";

    updateImportantPlanDate({plan: planItem})
  }

  const updateImportantPlanDate = (storeData) => {
    haccpUtils.request(f7, {
      url: '/important_plan_update',
      method: "POST",
      contentType: "application/json",
      crossDomain: true,
      headers: {Authorization: "Bearer ..."},
      data: storeData, // TODO 変更中 (変更済) OK
      dataType: "json",
      success: function (body, status, xhr){
        console.log("success...");
        storage.setItem(storage_key, storeData["plan"])
      },
      error: function (xhr, status, message) {
        console.log("error...");
      },      
      complete: function (xhr, status) {
        console.log(xhr, status);
      },
      statusCode: {
        401: function(xhr) {
          console.log(xhr)
        }
      },
    });
  }

  return (
    <Page id="page_重要記録" onPageBeforeRemove={onPageBeforeRemove} onPageInit={onPageInit} >
      <Fab position="right-top" slot="fixed" color="white"
        onClick={() => {
          const account = haccpUtils.getLoginAccount()
          var targetDay = new Date();
          targetDay.setDate(targetDay.getDate());
          // console.log("targetDay", targetDay)
          var calendar = f7.calendar.create({
            locale: "en-US",
            monthNames: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月' , '9月' , '10月', '11月', '12月'],
            monthNamesShort: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月' , '9月' , '10月', '11月', '12月'],
            dayNames: ['日', '月', '火', '水', '木', '金', '土'],
            dayNamesShort: ['日', '月', '火', '水', '木', '金', '土'],
            firstDay: 0,
            maxDate: targetDay,
            //2015年10月1日から2015年12月31日までのすべての日付を無効にする
            //minDate: "2021-08-31T15:00:00Z",
            //maxDate: "2021-11-30T15:00:00Z",
            /*disabled: {
                from: new Date(2021, 9, 1),
                to: new Date(2021, 11, 31)
            },*/
            closeOnSelect: true,
            on: {
              opened: function () {
                console.log('Calendar opened')
              },
              closed: function () {
                console.log('Calendar closed')
                if(calendar.value) {
                  var d = new Date(Date.parse(calendar.value[0]))
                  var shopid = account["shop_id"]
                  var year = d.getFullYear()
                  var month = d.getMonth() + 1
                  var day = d.getDate()
                  haccpUtils.modifyImportantCtrlDate(shopid, year, month ,day)
                  f7.views.get(".view-main").router.navigate({name: 'CalendarImportantCtrl', params: {targetDate: [year, month, day].join("-")}})
                }
              },
            }
          });
          calendar.open()
        }}
      >
        <Icon f7="calendar_badge_plus" size="22px" color="teal"></Icon>
      </Fab>
      <Navbar className="important"> 
        <NavLeft>
          <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" />
        </NavLeft>
        <NavTitle style = {{fontSize: "20px"}}>{年月日 + " 重要記録"}</NavTitle>
        <NavRight>
          <Link href="/home/" view=".view-main" panelClose iconF7="house"></Link>
        </NavRight>
      </Navbar>
      <form></form>
    </Page>
  );
};
export default TodayImportantCtrl;
