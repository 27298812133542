import React, { useRef } from 'react';
import {
  AccordionContent,
  BlockTitle,
  Page,
  Navbar,
  NavLeft,
  NavRight,
  NavTitle,
  Block,
  List,
  ListItem,
  f7,
  Button,
  Link,
  Row,
  Col,
} from 'framework7-react';

import Dom7 from 'dom7';
import ReactDOM from 'react-dom';
import '../css/grid.css'
import haccpUtils from '../js/haccp-utils.js';

const DataClearGeneralCtrl = () => {
  var $$ = Dom7
  const storage = haccpUtils.getStorage();
  const ctrl_storage_key = "#USER#general-hygienic-ctrl"
  const storage_key_clear_target_id = "#USER#general-clear-target-id"
  const actions = useRef(null);
  const onPageBeforeRemove = () => {
    actions.current.destroy();
  };

  const initProcess1 = () => {
    haccpUtils.request(f7, {
      url: '/general_hygienic_ctrl',
      method: "GET",
      contentType: "application/json",
      crossDomain: true,
      headers: {Authorization: "Bearer ..."},
      data: {},
      dataType: "json",
      success: function (body, status, xhr){
        console.log("generalCtrl", body.data.generalCtrl);
        storage.setItem(ctrl_storage_key, body.data.generalCtrl)
        initProcess2()
      },
      error: function (xhr, status, message) {
        console.log("error...");
      },      
      complete: function (xhr, status) {
        console.log(xhr, status);
      },
      statusCode: {
        401: function(xhr) {
          console.log(xhr)
        }
      },
    });
  }

  const initProcess2 = () => {
    haccpUtils.request(f7, {
      url: '/general_hygienic_ctrl_plan',
      method: "GET",
      contentType: "application/json",
      crossDomain: true,
      headers: { Authorization: "Bearer ..." },
      data: {},
      dataType: "json",
      success: function (body, status, xhr) {
        // console.log("generalPlan", body.data.generalPlan);
        const generalPlan = body.data.generalPlan[0];
        render管理計画初期表示(generalPlan)
      },
      error: function (xhr, status, message) {
        console.log("error...:" + status);
      },
      complete: function (xhr, status) {
        console.log(xhr, status);
      },
      statusCode: {},
    });
  }
  const onPageInit = () => {
    actions.current = f7.actions.create({})
    initProcess1()
  };

  const render管理計画初期表示 = (generalPlan) => {

    const generalCtrl = storage.getItemObject(ctrl_storage_key)
    const generalClearTarget = storage.getItemObject(storage_key_clear_target_id)

    console.log('generalClearTarget[id]',generalClearTarget.id)
    console.log('generalClearTarget[general_hygienic_ctrl_id]',generalClearTarget.general_hygienic_ctrl_id)
    // console.log('generalCtrl',generalCtrl)
    // console.log('generalPlan',generalPlan)

    var general_hygienic_ctrl = null
    var general_hygienic_ctrl_detail = null
    var account = haccpUtils.getLoginAccount()
    var name = "ゲスト"
    if(account && account.user) name = account.user.last_name + account.user.first_name
  
    const general_hygienic_ctrls = generalCtrl[0].children.filter(child => {
      return child.id == generalClearTarget.general_hygienic_ctrl_id
    })
    general_hygienic_ctrl = general_hygienic_ctrls[0]
    // console.log('general_hygienic_ctrl',general_hygienic_ctrl)

    const details = general_hygienic_ctrl.details.filter(detail => {
      return detail.id == generalClearTarget.id;
    })
    general_hygienic_ctrl_detail = details[0]

    var w_generalPlan = generalPlan.children.filter(plan => {return plan.id == general_hygienic_ctrl_detail.general_hygienic_ctrl_plan_id})
    var plan = w_generalPlan[0]

    const hygienic_ctrl_work = (item_id,general_hygienic_ctrl_detail) => {
      switch (item_id){
        case '1': return general_hygienic_ctrl_detail.hygienic_ctrl_work_1;
        case '2': return general_hygienic_ctrl_detail.hygienic_ctrl_work_2;
        case '3': return general_hygienic_ctrl_detail.hygienic_ctrl_work_3;
        case '4': return general_hygienic_ctrl_detail.hygienic_ctrl_work_4;
        case '5': return general_hygienic_ctrl_detail.hygienic_ctrl_work_5;
        case '6': return general_hygienic_ctrl_detail.hygienic_ctrl_work_6;
        case '7': return general_hygienic_ctrl_detail.hygienic_ctrl_work_7;
        default: return ""
      }
    }

    const hygienic_ctrl_work_val = (item_id,general_hygienic_ctrl_detail) => {
      switch (item_id){
        case '1': return general_hygienic_ctrl_detail.hygienic_ctrl_work_1_val;
        case '2': return general_hygienic_ctrl_detail.hygienic_ctrl_work_2_val;
        case '3': return general_hygienic_ctrl_detail.hygienic_ctrl_work_3_val;
        case '4': return general_hygienic_ctrl_detail.hygienic_ctrl_work_4_val;
        case '5': return general_hygienic_ctrl_detail.hygienic_ctrl_work_5_val;
        case '6': return general_hygienic_ctrl_detail.hygienic_ctrl_work_6_val;
        case '7': return general_hygienic_ctrl_detail.hygienic_ctrl_work_7_val;
        default: return ""
      }
    }

    const block_data = (
      <Block>
        {/* <BlockTitle className="dgc-title">{general_hygienic_ctrl.work_year + "年" + general_hygienic_ctrl.work_month + "月"　+ general_hygienic_ctrl_detail.work_day + "日 一般記録"}</BlockTitle> */}
        <input type="hidden" name="id" value={general_hygienic_ctrl_detail.id}/>
        <input type="hidden" name="general_hygienic_ctrl_id" value={general_hygienic_ctrl.id}/>
        <input type="hidden" name="general_hygienic_ctrl_plan_id" value={plan.id}/>
        <input type="hidden" name="plan_version" value={plan.plan_version}/>
        <input type="hidden" name="work_day" value={general_hygienic_ctrl_detail.work_day}/>
        <input type="hidden" name="daily_check" value={general_hygienic_ctrl_detail.daily_check}/>
        <input type="hidden" name="memo" value={general_hygienic_ctrl_detail.memo}/>
        <input type="hidden" name="admin_memo" value={general_hygienic_ctrl_detail.admin_memo}/>
        <input type="hidden" name="remark" value={general_hygienic_ctrl_detail.admin_memo + "ユーザによるデータクリア済"}/>
        <List>
          <ListItem className="dgc-title" title={general_hygienic_ctrl.work_year + "年" + general_hygienic_ctrl.work_month + "月"　+ general_hygienic_ctrl_detail.work_day + "日 一般記録"}></ListItem>
          {plan.details.map((item, idx) =>
            <List>
              <input type="hidden" name={"hygienic_ctrl_work_" + item.item_id} value = {hygienic_ctrl_work(item.item_id, general_hygienic_ctrl_detail)}/>
              <BlockTitle className="dgc-1">{item.item_name}</BlockTitle>
              <ListItem
                header="一日の結果"
                title={hygienic_ctrl_work_val(item.item_id, general_hygienic_ctrl_detail) ? hygienic_ctrl_work_val(item.item_id, general_hygienic_ctrl_detail) : "未記入・未確認"}
                style = { item.item_id == "0" ? {color: "Block"} : hygienic_ctrl_work(item.item_id, general_hygienic_ctrl_detail) == "1" ? {color: "blue"} : hygienic_ctrl_work(item.item_id, general_hygienic_ctrl_detail) == "2" ? {color: "red"} : {color: "Block"}}
              >
              </ListItem>
              <ListItem accordionItem title="管理計画">
                <AccordionContent>
                  <List>
                    <ListItem name={"work_time_" + item.id} title="いつ">{item.work_time}</ListItem>
                    <ListItem name={"method_element_" + item.id} title="どのように">{item.method_element}</ListItem>
                    <ListItem name={"problem_occured_" + item.id} title="問題があったとき">{item.problem_occured}</ListItem>
                  </List>
                </AccordionContent>
              </ListItem>
            </List>
          )}
          <List>
            <BlockTitle>　</BlockTitle>
            <ListItem
              header="日々チェック"
              title={general_hygienic_ctrl_detail.daily_check ? general_hygienic_ctrl_detail.daily_check : "日々チェック"}
            >
            </ListItem>
            <ListItem
              header="特記事項"
              title={general_hygienic_ctrl_detail.memo ? general_hygienic_ctrl_detail.memo : "特記事項"}
            >
            </ListItem>
            <ListItem
              header="確認者"
              title={general_hygienic_ctrl_detail.admin_memo ? general_hygienic_ctrl_detail.admin_memo : "確認者"}
            >
            </ListItem>
          </List>
          <Block strong>
            <p style = {{fontSize: "21px", color: "red"}}>削除した記録は戻せません。<br/>よろしいですか？</p>
            <Row>
              <Col>
                <Button className="btm-type1" color="blue" onClick={(e) => {
                  if(!haccpUtils.validateForm($$(e.target).closest("form"), e.target)) return false;
                  storeData()
                }} style = {{fontSize: "21px", color: "red"}}>記録を削除する</Button>
              </Col>
            </Row>
          </Block>
        </List>
      </Block>
    )
    haccpUtils.lazyRender("#page_一般記録クリア.page-current form", block_data)
  }

  const storeData = () => {
    var storedData = f7.form.convertToData("#page_一般記録クリア.page-current form")
    console.log('storedData',storedData)

    var clearGeneralCtrl = storedData
    clearGeneralCtrl.hygienic_ctrl_work_1 = "0"
    clearGeneralCtrl.hygienic_ctrl_work_2 = "0"
    clearGeneralCtrl.hygienic_ctrl_work_3 = "0"
    clearGeneralCtrl.hygienic_ctrl_work_4 = "0"
    clearGeneralCtrl.hygienic_ctrl_work_5 = "0"
    clearGeneralCtrl.hygienic_ctrl_work_6 = "0"
    clearGeneralCtrl.hygienic_ctrl_work_7 = "0"
    dataClearGeneralCtrlDetail(clearGeneralCtrl)
  }


  const dataClearGeneralCtrlDetail = (clearGeneralCtrl) => {
    haccpUtils.request(f7, {
      url: '/data_clear_general_hygienic_ctrl_detail',
      method: "POST",
      contentType: "application/json",
      crossDomain: true,
      headers: {Authorization: "Bearer ..."},
      data: clearGeneralCtrl,
      dataType: "json",
      success: function (body, status, xhr){
        console.log("success...");
      },
      error: function (xhr, status, message) {
        console.log("error...");
      },      
      complete: function (xhr, status) {
        console.log(xhr, status);
        f7.views.get(".view-main").router.navigate({ name: 'GeneralHygienicCtrl' });
      },
      statusCode: {
        401: function(xhr) {
          console.log(xhr)
        }
      },
    });
  }

  return (
    <Page id="page_一般記録クリア" onPageBeforeRemove={onPageBeforeRemove} onPageInit={onPageInit}>
      <Navbar className="general">
        <NavLeft>
          <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" />
        </NavLeft>
        <NavTitle style = {{fontSize: "20px", color: "white"}}>確認画面（記録削除）</NavTitle>
        <NavRight>
          <Link href="/home/" view=".view-main" panelClose iconF7="house"></Link>
        </NavRight>
      </Navbar>
      <form></form>
    </Page>
  );
};
export default DataClearGeneralCtrl;
