import React, { useRef } from 'react';
import {
  Page,
  Navbar,
  Block,
  List,
  f7,
  ListButton,
  NavLeft,
  NavTitle,
  Link,
} from 'framework7-react';

import Dom7 from 'dom7';
import ReactDOM from 'react-dom';

import '../css/sortable.css';
import '../css/grid.css'

import haccpUtils from '../js/haccp-utils.js';

const CsvUserInformation = () => {
  var $$ = Dom7

  const storage = haccpUtils.getStorage();
  const actions = useRef(null);

  const storage_key = "#USER#csv-user-information"

  const onPageBeforeRemove = () => {
    actions.current.destroy();
  };
  const onPageInit = () => {
    actions.current = f7.actions.create({});
    haccpUtils.request(f7, {
      url: '/csv_user_information',
      method: "GET",
      contentType: "application/json",
      crossDomain: true,
      headers: {Authorization: "Bearer ..."},
      data: {},
      dataType: "json",
      success: function (body, status, xhr){
        renderInit(body.data.csvUserInformation)
        storage.setItem(storage_key, body.data.csvUserInformation)
      },
      error: function (xhr, status, message) {
        console.log("error...");
      },      
      complete: function (xhr, status) {
        console.log(xhr, status);
      },
      statusCode: {
        401: function(xhr) {
          console.log(xhr)
        }
      },
    });

    actions.current = f7.actions.create({});
  };

  const renderInit = (csvUserInformation) => {
    const block_data = (
      <Block strong className="no-padding">
      <List>
        <ListButton
          title="CSV出力を行う"
          onClick={()=> {
            var csv = [haccpUtils.USER_HEADERS.join(",")]
            var update_csvUserInformation = csvUserInformation.map(function( value ) {
              for (let key in value) {
                if(value[key]){
                  // CSV出力時、改行とカンマは行および列を崩してしまうため削除する
                  // 改行を削除
                  // value[key] = value[key].replace(/\r?\n/g, '');
                  value[key] = value[key].replace(/[\r\n]+/g, '');
                  // カンマを削除
                  value[key] = value[key].replace(',', '');
                }
              }
              return value;
            });
            csv = csv.concat(update_csvUserInformation.map(haccpUtils.USER_COLUMNS))
            haccpUtils.downloadCSV('userInfo.csv', csv)
          }}
        />
      </List>
            <div class="card data-table">
            <table>
              <thead>
                <tr>
                  {haccpUtils.USER_HEADERS.map((name) => {
                    var width = 120
                    const width30 = ["店舗ID", "部門ID", "ユーザID", "アカウントID"]
                    if(name in width30) width = 30
                    return <th class="font13 width{width}">{name}</th>
                  })}
                </tr>
              </thead>
              <tbody>
              {csvUserInformation.map(user =>
                <tr>
                  <td class="width30">{user.shop_id}</td>
                  <td class="width120">{user.shop_name}</td>
                  {/* <td class="width120">{user.shop_remark}</td>
                  <td class="width120">{user.shop_create_dttm}</td>
                  <td class="width120">{user.shop_create_acct_id}</td>
                  <td class="width120">{user.shop_upd_dttm}</td>
                  <td class="width120">{user.shop_upd_acct_id}</td>
                  <td class="width120">{user.shop_del_flg}</td>
                  <td class="width30">{user.department_id}</td>
                  <td class="width120">{user.department_name}</td>
                  <td class="width120">{user.department_remark}</td>
                  <td class="width120">{user.department_create_dttm}</td>
                  <td class="width120">{user.department_create_acct_id}</td>
                  <td class="width120">{user.department_upd_dttm}</td>
                  <td class="width120">{user.department_upd_acct_id}</td>
                  <td class="width120">{user.department_del_flg}</td> */}
                  <td class="width30">{user.user_id}</td>
                  <td class="width120">{user.last_name}</td>
                  <td class="width120">{user.first_name}</td>
                  <td class="width120">{user.last_name_kana}</td>
                  <td class="width120">{user.first_name_kana}</td>
                  <td class="width120">{user.zip}</td>
                  <td class="width120">{user.addr}</td>
                  {/* <td class="width120">{user.age}</td> */}
                  <td class="width120">{user.tel}</td>
                  {/* <td class="width120">{user.fax}</td>
                  <td class="width120">{user.mobile}</td> */}
                  <td class="width120">{user.email}</td>
                  {/* <td class="width120">{user.valid_code}</td>
                  <td class="width120">{user.user_remark}</td> */}
                  <td class="width120">{user.user_create_dttm}</td>
                  {/* <td class="width120">{user.user_create_acct_id}</td> */}
                  <td class="width120">{user.user_upd_dttm}</td>
                  {/* <td class="width120">{user.user_upd_acct_id}</td>
                  <td class="width120">{user.user_del_flg}</td>
                  <td class="width30">{user.account_id}</td>
                  <td class="width120">{user.account_remark}</td>
                  <td class="width120">{user.account_create_dttm}</td>
                  <td class="width120">{user.account_create_acct_id}</td>
                  <td class="width120">{user.account_upd_dttm}</td>
                  <td class="width120">{user.account_upd_acct_id}</td>
                  <td class="width120">{user.account_del_flg}</td> */}
                </tr>
              )}
              </tbody>
            </table>
            </div>
      </Block>
    )
    var block = $$("#csvUserInformation")
    ReactDOM.render(block_data, block[0])
  }

  return (
    <Page onPageBeforeRemove={onPageBeforeRemove} onPageInit={onPageInit} >
      <Navbar className="mst">
        <NavLeft>
          <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" />
        </NavLeft>
        <NavTitle style = {{fontSize: "20px"}}>ユーザ情報CSV出力</NavTitle>
        {/* <NavRight>
          <Link href="/home/" view=".view-main" panelClose iconF7="house"></Link>
        </NavRight> */}
      </Navbar>
      <Block id="csvUserInformation" className="mst-list"></Block>
    </Page>
  );
};
export default CsvUserInformation;
