import Dom7, { trigger } from "dom7";

import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import {
    View,
    Page,
    Navbar,
    NavRight,
    Link,
    List,
    ListItem,
    Input,
    ListInput,
    ListButton,
    BlockFooter,
} from 'framework7-react';

const haccpUtils = {
    preloader_counter: 0,

    logout: (f7) => {
        console.log("logout")
        haccpUtils.getStorage().clearItems(); // セッション削除
        f7.panel.close(".panel-right"); // 右パネルを閉じる
        f7.panel.close(".panel-left"); // 左パネルを閉じる
        f7.views.get(".view-main").router.navigate({ name: 'home' }); // 中央に表示
    },

    downloadCSV: (filename, csv) => {
        console.log(csv);
        var element = document.createElement('a');
        element.href = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csv.join('\n'));
        element.setAttribute('download', filename);
        element.className = "link external";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    },
    USER_HEADERS: [
        "店舗ID",
        "店舗名",
        // "店舗備考",
        // "店舗作成日時",
        // "店舗作成者",
        // "店舗更新日時",
        // "店舗更新者",
        // "店舗削除フラグ",
        // "部門ID",
        // "部門名",
        // "部門備考",
        // "部門作成日時",
        // "部門作成者",
        // "部門更新日時",
        // "部門更新者",
        // "部門削除フラグ",
        "ユーザID",
        "姓",
        "名",
        "姓カナ",
        "名カナ",
        "郵便番号",
        "住所",
        // "年齢",
        "電話番号",
        // "ファックス番号",
        // "携帯番号",
        "メールアドレス",
        // "確認コード",
        // "ユーザ備考",
        "ユーザ作成日時",
        // "ユーザ作成者",
        "ユーザ更新日時"
        // "ユーザ更新者",
        // "ユーザ削除フラグ",
        // "アカウントID",
        // "アカウント備考",
        // "アカウント作成日時",
        // "アカウント作成者",
        // "アカウント更新日時",
        // "アカウント更新者",
        // "アカウント削除フラグ"
    ],
    USER_COLUMNS: (user => {
        return user.shop_id + "," +
            user.shop_name + "," +
            // user.shop_remark + "," +
            // user.shop_create_dttm + "," +
            // user.shop_create_acct_id + "," +
            // user.shop_upd_dttm + "," +
            // user.shop_upd_acct_id + "," +
            // user.shop_del_flg + "," +
            // user.department_id + "," +
            // user.department_name + "," +
            // user.department_remark + "," +
            // user.department_create_dttm + "," +
            // user.department_create_acct_id + "," +
            // user.department_upd_dttm + "," +
            // user.department_upd_acct_id + "," +
            // user.department_del_flg + "," +
            user.user_id + "," +
            user.last_name + "," +
            user.first_name + "," +
            user.last_name_kana + "," +
            user.first_name_kana + "," +
            user.zip + "," +
            user.addr + "," +
            // user.age + "," +
            user.tel + "," +
            // user.fax + "," +
            // user.mobile + "," +
            user.email + "," +
            // user.valid_code + "," +
            // user.user_remark + "," +
            user.user_create_dttm + "," +
            // user.user_create_acct_id + "," +
            user.user_upd_dttm
        // user.user_upd_acct_id + "," +
        // user.user_del_flg + "," +
        // user.account_id + "," +
        // user.account_remark + "," +
        // user.account_create_dttm + "," +
        // user.account_create_acct_id + "," +
        // user.account_upd_dttm + "," +
        // user.account_upd_acct_id + "," +
        // user.account_del_flg
    }
    ),
    isLogined: () => {
        return haccpStorage.hasItem("#USER#token") ? true : false;
    },
    getHostPrefix: (f7, path = "") => {
        var prefix = "";
        if (f7.device.cordova) {
            if (process.env.NODE_ENV == "production") {
                prefix = "https://haccp-app.mylabo-net.com/api/v1"
            } else {
                prefix = "https://haccp-app-uat.mylabo-net.com/api/v1"
            }
        } else if (document.location.host == "demo.lakesuccess.jp") {
            prefix = "https://demo.lakesuccess.jp/haccp-app/api/v1"
        } else if (document.location.host == "haccp-app.mylabo-net.com") {
            prefix = "https://haccp-app.mylabo-net.com/api/v1"
        } else if (document.location.host == "haccp-app-uat.mylabo-net.com") {
            prefix = "https://haccp-app-uat.mylabo-net.com/api/v1"
        } else if (document.location.host.indexOf(".dev.lakesuccess.jp") > 0) {
            prefix = "https://" + document.location.host.replace("-app-", "-api-") + "/api/v1"
        } else {
            prefix = "http://" + document.location.hostname + ":8088/api/v1"
        }
        return prefix + path;
    },
    downloadPDF: (f7, category, name, id) => {
        var path = ["", category, name, id].join("/")
        haccpUtils.request(f7, {
            url: path,
            method: "POST",
            contentType: "application/json",
            headers: { Authorization: "Bearer ..." },
            crossDomain: true,
            data: {},
            dataType: "json",
            success: function (body, status, xhr) {
                console.log("success");
                console.log(body);
                if (f7.device.cordova) {
                    var url = haccpUtils.getHostPrefix(f7, ["", category, name, body.data.timestamp].join("/")) + "/download";
                    window.open(url, "_system");
                } else {
                    var url = haccpUtils.getHostPrefix(f7, ["", category, name, body.data.timestamp].join("/"));
                    window.open(url, "_blank");
                }
            },
            error: function (xhr, status, message) {
                console.log("error");
            },
            complete: function (xhr, status) {
                console.log(xhr, status);
            },
        });

        console.log("downloadPDF:" + path)
    },
    request: (f7, props) => {
        var storage = window.localStorage
        var token = storage.getItem("#USER#token")
        if (props.headers && props.headers.Authorization == "Bearer ...") props.headers.Authorization = "Bearer " + token

        props.url = haccpUtils.getHostPrefix(f7, props.url)

        const openLogin = (xhr) => {
            console.log(xhr);
            f7.views.get(".view-main").router.navigate({ name: 'TopPage' }); // 中央に表示
            f7.popup.open("#my-login-screen")

        }
        props.statusCode = {
            401: openLogin,
            500: openLogin,
        }

        if (!props["complete"]) {
            console.log("-------------------------");
            console.log("requestの　pcomplete メソッドがない！！")
            console.log("-------------------------");
        }

        const _complete = props["complete"] ? props["complete"] : function () { };
        props.complete = (xhr, status) => {
            if (--haccpUtils.preloader_counter == 0) f7.preloader.hide()
            return _complete(xhr, status)
        }
        if (haccpUtils.preloader_counter++ == 0) f7.preloader.show()
        f7.request(props)
    },
    getStorage: () => {
        return haccpStorage;
    },
    lazyRender: (selector, block_data) => {
        var $$ = Dom7
        const render = () => {
            var forms = $$(selector)
            // if(forms.length == 0 || forms[0].innerText.length > 0) {
            if (forms.length == 0) {
                timer = setTimeout(render, 100)
                return false
            }
            ReactDOM.render(block_data, forms[0])
            clearTimeout(timer)
            return true
        }
        var timer = setTimeout(render, 100)
    },
    getToday: (format = "yyyy/mm/dd", now = null) => {
        if (now == null) now = new Date();
        var str = format;
        str = str.replace("yyyy", now.getFullYear());
        // str = str.replace("mm", ("00" + (now.getMonth() + 1)).substring(0, 2));
        str = str.replace("mm", ("00" + (now.getMonth() + 1)).slice(-2));
        str = str.replace("m", now.getMonth() + 1);
        // str = str.replace("dd", ("00" + now.getDate()).substring(0, 2));
        str = str.replace("dd", ("00" + now.getDate()).slice(-2));
        str = str.replace("d", now.getDate());

        return str;
    },
    getLoginAccount: () => {
        var account = haccpStorage.getAccount()
        if (account) account.user = haccpStorage.getUser()
        return account
    },
    convertFormToData: (f7, form) => {
        const flatForm = f7.form.convertToData(form)
        const parseKeys = (nextPath, value, result) => {
            if (nextPath.length == 0) return value
            var key = nextPath.shift()

            if (!result[key]) result[key] = (isNaN(parseFloat(key)) ? [] : {})
            result[key] = parseKeys(nextPath, value, result[key])
            return result;
        }
        var result = {}
        for (const key in flatForm) {
            result = parseKeys(key.split(/[\.\[\]]+/), flatForm[key], result)
        }
        console.log(flatForm)
        console.log(result)
        return result
    },
    validateForm: (form, target) => {
        form.find('[data-validate-on-blur=true]').forEach((elem) => {
            elem.focus()
            elem.blur()
        });
        target.focus()
        console.log(form.find('.input-invalid').length)
        return form.find('.input-invalid').length == 0
    },
    cloneDeep: (arr) => {
        var newArr = []
        arr.forEach(a => newArr.push(a));
        return newArr
    },
    merge: (base, append) => {
        for (var name in append) {
            base[name] = append[name]
        }
        return base
    },
    get_by_path: (data, keys) => {
        if (keys.length == 0) return data

        const condition = keys.shift()
        const listMatcher = (item) => {
            var match = true;
            for (let key in condition) {
                if (item[key] != condition[key]) match = false;
            }
            return match
        }

        if ((typeof condition) == "string") {
            return haccpUtils.get_by_path(data[condition], keys);
        } else {
            return haccpUtils.get_by_path(data.filter(listMatcher).shift(), keys)
        }
    },
    let_by_path: (data, keys, value) => {
        if (keys.length == 0) return value

        const condition = keys.shift()
        if ((typeof condition) == "string") {

            data[condition] = haccpUtils.let_by_path(data[condition], keys, value)
        } else {
            var notMatchAll = true
            for (var idx in data) {
                var match = true;
                for (let key in condition) {
                    if (data[idx][key] != condition[key]) match = false;
                }
                if (match) {
                    data[idx] = haccpUtils.let_by_path(data[idx], keys, value)
                    notMatchAll = false
                }
            }

            if (notMatchAll) {
                if (keys.length == 0) {
                    console.log([value, data])
                    data.push(value)
                } else {
                    throw Error("システムエラー：親フィールドがないための致命的なエラーです")
                }
            }
        }
        return data;
    },
    sendValidCode: (f7, resetUser = {}) => {
        haccpUtils.request(f7, {
            url: '/user/sendValidCode',
            method: "PUT",
            contentType: "application/json",
            headers: { Authorization: "Bearer ..." },
            crossDomain: true,
            data: resetUser,
            dataType: "json",
            success: function (body, status, xhr) {
                console.log("success");
                console.log(body);
                var title = "新しいパスワードを設定して、入力したメールアドレスに送信された6桁の確認コードを入力してください。";
                f7.dialog.alert(title);
            },
            error: function (xhr, status, message) {
                console.log("error");
                var message = "入力に誤りがあります。";
                f7.dialog.alert(message, () => {
                    f7.popup.close("#haccp-popup");
                });
            },
            complete: function (xhr, status) {
                console.log(xhr, status);
            },
            statusCode: {
                401: function (xhr) {
                    console.log(xhr)
                }
            },
        });

        haccpUtils.openResetPasswordDialog(f7, resetUser["username"], "変更")
    },
    openResetPasswordDialog: (f7, username = "", actionTitle) => {
        f7.dialog.open("#haccp-popup")


        var $$ = Dom7
        console.log("[openResetPasswordDialog]")

        var popup = $$("#haccp-popup")
        const updatePassword = () => {
            console.log("updatePassword")

            const resetUser = f7.form.convertToData("#haccp-popup form")
            haccpUtils.request(f7, {
                url: '/user/updatePassword',
                method: "PUT",
                contentType: "application/json",
                headers: { Authorization: "Bearer ..." },
                crossDomain: true,
                data: resetUser,
                dataType: "json",
                success: function (body, status, xhr) {
                    console.log("success");
                    console.log(body);
                    var title = "パスワードを" + actionTitle + "しました。";
                    f7.dialog.alert(title, () => {
                        f7.popup.close("#haccp-popup");
                    });
                },
                error: function (xhr, status, message) {
                    console.log("error");
                    var message = "入力に誤りがあります。<br><br>確認コードをメールで再送信します。";
                    f7.dialog.alert(message, () => {
                        resetUser["username"] = resetUser["email"]
                        haccpUtils.sendValidCode(f7, resetUser)
                    },
                        // () => {
                            // 確認コード入力ミス時に画面は遷移せずそのままにする場合は、以下「f7.popup.close("#haccp-popup");」この１行をコメントにする
                        //     f7.popup.close("#haccp-popup");
                        // }

                    );
                },
                complete: function (xhr, status) {
                    console.log(xhr, status);
                },
            });
        }

        var view = (
            <View>
                <Page>
                    <Navbar title={"パスワード" + actionTitle}>
                        <NavRight>
                            <Link popupClose iconF7="xmark"></Link>
                        </NavRight>
                    </Navbar>
                    <form></form>
                </Page>
            </View>
        )

        var popup = $$("#haccp-popup")
        ReactDOM.render(view, popup[0])

        var form = $$("#haccp-popup form")
        const list = (

            <List>
                <ListInput
                    type="hidden"
                    name="email"
                    placeholder="e-mail address"
                    defaultValue={username}
                    disabled
                ></ListInput>
                <ListItem>
                    <Input
                        type="password"
                        name="new_password"
                        placeholder="新しいパスワード"
                        style={{ width: "100%" }}
                        required
                        validateOnBlur
                    />
                    <Link
                        iconF7="eye"
                        name="eye"
                        style={{
                            display: "inherit",
                            padding: "0px 10px",
                            height: "100%",
                            // backgroundColor: "rgb(232, 240, 254)"
                            position: "absolute",
                            right: "25px"
                        }}
                        onClick={(event) => {
                            const el_password = $$(event.target).closest("form").find('[name="new_password"]')
                            const el_eyes = $$(event.target).closest("form").find('a.link.icon-only')
                            console.log("el_eyes", el_eyes[0], el_eyes[1])

                            if (el_password.prop("type") != "password") {
                                el_password.prop("type", "password")
                            } else {
                                el_password.prop("type", "text")
                            }
                            if (el_eyes[0].style.display == "none") {
                                el_eyes[0].style.display = "inherit"
                                el_eyes[1].style.display = "none"
                            } else {
                                el_eyes[0].style.display = "none"
                                el_eyes[1].style.display = "inherit"
                            }
                            return false;
                        }}
                    />
                    <Link
                        iconF7="eye_slash"
                        style={{
                            display: "none",
                            padding: "0px 10px",
                            height: "100%",
                            // backgroundColor: "rgb(232, 240, 254)"
                            position: "absolute",
                            right: "25px"
                        }}
                        onClick={(event) => {
                            const el_password = $$(event.target).closest("form").find('[name="new_password"]')
                            const el_eyes = $$(event.target).closest("form").find('a.link.icon-only')
                            console.log("el_eyes", el_eyes[0], el_eyes[1])

                            if (el_password.prop("type") != "password") {
                                el_password.prop("type", "password")
                            } else {
                                el_password.prop("type", "text")
                            }
                            if (el_eyes[0].style.display == "none") {
                                el_eyes[0].style.display = "inherit"
                                el_eyes[1].style.display = "none"
                            } else {
                                el_eyes[0].style.display = "none"
                                el_eyes[1].style.display = "inherit"
                            }
                            return false;
                        }}
                    />
                </ListItem>
                <ListItem>
                    <Input
                        type="text"
                        name="valid_code"
                        placeholder="メールに届いた確認コード"
                        autocomplete="new-password"
                        defaultValue=""

                        style={{width: "100%"}}
                    // required
                    // validateOnBlur
                    />
                </ListItem>

                <ListButton title={actionTitle} onClick={updatePassword} />
                <BlockFooter>
                    <p style={{ color: "red", fontSize: "14px", marginTop: "0px", marginBottom: "0px" }}>
                        新しいパスワードを設定して、入力したメールアドレスに送信された６桁の確認コードを入力した後、登録をタップしてください。
                    </p>
                </BlockFooter>
            </List>
        )
        ReactDOM.render(list, form[0])

    },
    updatePermissionDisplay: () => {
        var $$ = Dom7
        var classNames = []

        var root = $$("#framework7-root");
        if (root) {
            root.removeClass("haccp-role-user")
            root.removeClass("haccp-role-shop")
            root.removeClass("haccp-role-admin")
            root.removeClass("haccp-role-pre-regist")
            root.removeClass("haccp-role-guest")

        }

        var account = haccpUtils.getLoginAccount();
        var role_id = (account && account["id"]) ? account["role_id"] : 0;

        console.log("role_id")
        console.log(role_id)

        if (role_id == 0) {
            root.addClass("haccp-role-guest")
            classNames.push("haccp-role-guest")
        } else if (role_id == 113) { // 1 * 16 + 32 + 64
            root.addClass("haccp-role-system")
            root.addClass("haccp-role-admin")
            root.addClass("haccp-role-user")
            root.addClass("haccp-role-shop")
            classNames.push("haccp-role-system")
            classNames.push("haccp-role-admin")
            classNames.push("haccp-role-user")
            classNames.push("haccp-role-shop")
        } else if (role_id == 112) { // 16 + 32 + 64
            root.addClass("haccp-role-admin")
            root.addClass("haccp-role-user")
            root.addClass("haccp-role-shop")
            classNames.push("haccp-role-admin")
            classNames.push("haccp-role-user")
            classNames.push("haccp-role-shop")
        } else if (role_id == 64) {
            root.addClass("haccp-role-user")
            classNames.push("haccp-role-user")
        } else {
            root.addClass("haccp-role-pre-regist")
            classNames.push("haccp-role-pre-regist")
        }
        return classNames;
    },

    // 一般
    getGeneralCtrlDate: (shop_id, work_year, work_month, work_day) => {
        const storage = haccpStorage;
        const history_storage_key = "#USER#general-hygienic-ctrl";

        var generalCtrls = storage.hasItem(history_storage_key) ? storage.getItemObject(history_storage_key) : [];

        var currentMonth = {
            work_year: work_year,
            work_month: work_month,
            details: [
                {
                    work_day: work_day
                }
            ]
        }
        generalCtrls.forEach(ctrl => {
            if (ctrl.shop_id == shop_id) {
                ctrl.children.forEach(child => {
                    if (child.work_year == work_year && child.work_month == work_month) {
                        currentMonth = child
                    }
                })
            }
        })

        const currentDays = currentMonth.details.filter(detail => { return detail.work_day == work_day; });
        var currentDay = currentDays.length ? currentDays[0] : {
            work_day: work_day
        };

        currentDay.general_hygienic_ctrl_id = currentMonth.id;
        currentDay.shop_id = shop_id;
        currentDay.work_year = work_year;
        currentDay.work_month = work_month;
        return currentDay
    },
    modifyGeneralCtrlDate: (shop_id, work_year = -1, work_month = -1, work_day = -1) => {

        const storage = haccpStorage;
        const current_storage_key = "#USER#CurrentGeneralCtrl";

        if (work_year == -1) {
            var 年月日 = haccpUtils.getToday("yyyy/m/d").split("/")
            work_year = 年月日[0];
            work_month = 年月日[1];
            work_day = 年月日[2];
        }

        const currentDay = haccpUtils.getGeneralCtrlDate(shop_id, work_year, work_month, work_day)
        storage.setItem(current_storage_key, currentDay);
    },
    updateGeneralCtrlDate: (f7, data, complete = () => { }) => {
        const storage = haccpStorage;
        const history_storage_key = "#USER#general-hygienic-ctrl";

        var generalCtrls = storage.hasItem(history_storage_key) ? storage.getItemObject(history_storage_key) : {};
        console.log("generalCtrls")
        console.log(generalCtrls)
        console.log(["data", data])


        const yearMonthPath = [
            { shop_id: data.shop_id },
            "children",
            {
                work_year: data.work_year,
                work_month: data.work_month,
                temp_version: data.temp_version,
            }
        ]
        var yearMonthData = haccpUtils.get_by_path(generalCtrls, haccpUtils.cloneDeep(yearMonthPath))
        if (yearMonthData == undefined) {
            // 年月がない
            const newYearMonthData = {
                work_year: data.work_year,
                work_month: data.work_month,
                temp_version: data.temp_version,
                details: [],
            }
            generalCtrls = haccpUtils.let_by_path(generalCtrls, haccpUtils.cloneDeep(yearMonthPath), newYearMonthData)
        }
        const dayPath = [
            { shop_id: data.shop_id },
            "children",
            {
                work_year: data.work_year,
                work_month: data.work_month,
                temp_version: data.temp_version,
            },
            "details",
            {
                work_day: data.work_day,
            }
        ]
        console.log([generalCtrls, haccpUtils.cloneDeep(dayPath)])
        generalCtrls = haccpUtils.let_by_path(generalCtrls, haccpUtils.cloneDeep(dayPath), data)

        // for (var key1 in generalCtrls) {
        //     if(generalCtrls[key1].shop_id == data.shop_id) {
        //         for (var key2 in generalCtrls[key1].children) {
        //             if(generalCtrls[key1].children[key2].work_year == data.work_year
        //                 && generalCtrls[key1].children[key2].work_month == data.work_month
        //                 && generalCtrls[key1].children[key2].temp_version == data.temp_version) {
        //                         //generalCtrls[key1].children[key2].general_hygienic_ctrl_id = data.general_hygienic_ctrl_id
        //                         //generalCtrls[key1].children[key2].shop_id = data.shop_id
        //                         //generalCtrls[key1].children[key2].department_id = data.department_id
        //                         generalCtrls[key1].children[key2].general_hygienic_ctrl_plan_id = data.general_hygienic_ctrl_plan_id
        //                         generalCtrls[key1].children[key2].plan_version = data.plan_version
        //                         //generalCtrls[key1].children[key2].temp_version = data.temp_version
        //                         //generalCtrls[key1].children[key2].work_year = data.work_year
        //                         //generalCtrls[key1].children[key2].work_month = data.work_month

        //                         var hasDetails = false;
        //                         var hasChildren = false;
        //                 for (var key3 in generalCtrls[key1].children[key2].details) {
        //                     if(generalCtrls[key1].children[key2].details[key3].work_day == data.work_day) {
        //                         hasChildren = true;
        //                     }
        //                 }
        //                 if(!hasChildren) {
        //                     generalCtrls[key1].children.push(

        //                         )   
        //                 }

        //                 for (var key3 in generalCtrls[key1].children[key2].details) {
        //                     if(generalCtrls[key1].children[key2].details[key3].work_day == data.work_day) {

        //                         // 指定された日付のデータが存在するため、general_hygienic_ctrl_dailyの該当データを更新
        //                         //generalCtrls[key1].children[key2].details[key3].id                           = data.id
        //                         //generalCtrls[key1].children[key2].details[key3].shop_id                      = data.shop_id
        //                         //generalCtrls[key1].children[key2].details[key3].department_id                = data.department_id
        //                         generalCtrls[key1].children[key2].details[key3].plan_version                  = data.plan_version
        //                         generalCtrls[key1].children[key2].details[key3].general_hygienic_ctrl_id      = data.general_hygienic_ctrl_id
        //                         generalCtrls[key1].children[key2].details[key3].general_hygienic_ctrl_plan_id = data.general_hygienic_ctrl_plan_id
        //                         generalCtrls[key1].children[key2].details[key3].hygienic_ctrl_work_1          = data.hygienic_ctrl_work_1
        //                         generalCtrls[key1].children[key2].details[key3].hygienic_ctrl_work_2          = data.hygienic_ctrl_work_2
        //                         generalCtrls[key1].children[key2].details[key3].hygienic_ctrl_work_3          = data.hygienic_ctrl_work_3
        //                         generalCtrls[key1].children[key2].details[key3].hygienic_ctrl_work_4          = data.hygienic_ctrl_work_4
        //                         generalCtrls[key1].children[key2].details[key3].hygienic_ctrl_work_5          = data.hygienic_ctrl_work_5
        //                         generalCtrls[key1].children[key2].details[key3].hygienic_ctrl_work_6          = data.hygienic_ctrl_work_6
        //                         generalCtrls[key1].children[key2].details[key3].hygienic_ctrl_work_7          = data.hygienic_ctrl_work_7
        //                         generalCtrls[key1].children[key2].details[key3].daily_check                   = data.daily_check
        //                         generalCtrls[key1].children[key2].details[key3].admin_memo                    = data.admin_memo
        //                         generalCtrls[key1].children[key2].details[key3].memo                          = data.memo
        //                         hasDetails = true
        //                     } else {
        //                         // 指定された日付のデータが存在しないため、次の処理で
        //                         // general_hygienic_ctrl_dailyに該当データを新規追加
        //                         // data.general_hygienic_ctrl_id = generalCtrls[key1].children[key2].details[0].general_hygienic_ctrl_id
        //                         // data.general_hygienic_ctrl_plan_id = generalCtrls[key1].children[key2].details[0].general_hygienic_ctrl_plan_id
        //                         // data.plan_version = generalCtrls[key1].children[key2].details[0].plan_version
        //                         generalCtrls[key1].children[key2].details.push(data)
        //                         hasDetails === false
        //                     }
        //                 }
        //             } else {
        //                 // 指定された年月のデータが存在しないため、次の処理で
        //                 // 年月データを保持する親テーブルgeneral_hygienic_ctrlにレコード追加し、general_hygienic_ctrl_dailyに該当データを新規追加
        //                 // data.general_hygienic_ctrl_id = generalCtrls[key1].children[key2].details[0].general_hygienic_ctrl_id
        //                 // data.general_hygienic_ctrl_plan_id = generalCtrls[key1].children[key2].details[0].general_hygienic_ctrl_plan_id
        //                 // data.plan_version = generalCtrls[key1].children[key2].details[0].plan_version
        //                 generalCtrls[key1].children[key2].details.push(data)
        //                 hasDetails === false
        //             }
        //             console.log(hasDetails)
        //         }            
        //     } else {
        //         // ショップが見つからなかった場合、登録しないのは、問題ないか？？？
        //         console.log("data error shop_id not found")
        //     }
        // }
        storage.setItem(history_storage_key, generalCtrls)
        console.log("data")
        console.log(data)

        haccpUtils.request(f7, {
            url: '/general_hygienic_ctrl_detail',
            method: "POST",
            contentType: "application/json",
            crossDomain: true,
            headers: { Authorization: "Bearer ..." },
            data: data,
            dataType: "json",
            success: function (body, status, xhr) {
                console.log(body)
            },
            error: function (xhr, status, message) {
                console.log("error...:" + status);
            },
            complete: function (xhr, status) {
                console.log(xhr, status);
                complete();
            },
            statusCode: {},
        });
    },


    // 重要

    getImportantCtrlDate: (shop_id, work_year, work_month, work_day) => {
        const storage = haccpStorage;
        const history_storage_key = "important-hygienic-ctrl";

        var importantCtrls = storage.hasItem(history_storage_key) ? storage.getItemObject(history_storage_key) : [];

        var currentMonth = {
            work_year: work_year,
            work_month: work_month,
            details: [
                {
                    work_day: work_day
                }
            ]
        }
        importantCtrls.forEach(ctrl => {
            if (ctrl.shop_id == shop_id) {
                ctrl.children.forEach(child => {
                    if (child.work_year == work_year && child.work_month == work_month) {
                        currentMonth = child
                    }
                })
            }
        })

        const currentDays = currentMonth.details.filter(detail => { return detail.work_day == work_day; });
        var currentDay = currentDays.length ? currentDays[0] : {
            work_day: work_day
        };

        currentDay.important_hygienic_ctrl_id = currentMonth.id;
        currentDay.shop_id = shop_id;
        currentDay.work_year = work_year;
        currentDay.work_month = work_month;
        return currentDay
    },
    modifyImportantCtrlDate: (shop_id, work_year = -1, work_month = -1, work_day = -1) => {

        const storage = haccpStorage;
        const current_storage_key = "#USER#CurrentImportantCtrl";

        if (work_year == -1) {
            var 年月日 = haccpUtils.getToday("yyyy/m/d").split("/")
            work_year = 年月日[0];
            work_month = 年月日[1];
            work_day = 年月日[2];
        }

        const currentDay = haccpUtils.getImportantCtrlDate(shop_id, work_year, work_month, work_day)
        storage.setItem(current_storage_key, currentDay);
    },
    updateImportantCtrlDate: (f7, data, complete = () => { }) => {
        const storage = haccpStorage;
        const history_storage_key = "#USER#important-hygienic-ctrl";

        var importantCtrls = storage.hasItem(history_storage_key) ? storage.getItemObject(history_storage_key) : {};
        console.log("importantCtrls")
        console.log(importantCtrls)
        console.log(["data", data])


        const yearMonthPath = [
            { shop_id: data.shop_id },
            "children",
            {
                work_year: data.work_year,
                work_month: data.work_month,
                temp_version: data.temp_version,
            }
        ]
        var yearMonthData = haccpUtils.get_by_path(importantCtrls, haccpUtils.cloneDeep(yearMonthPath))
        if (yearMonthData == undefined) {
            // 年月がない
            const newYearMonthData = {
                work_year: data.work_year,
                work_month: data.work_month,
                temp_version: data.temp_version,
                details: [],
            }
            importantCtrls = haccpUtils.let_by_path(importantCtrls, haccpUtils.cloneDeep(yearMonthPath), newYearMonthData)
        }
        const dayPath = [
            { shop_id: data.shop_id },
            "children",
            {
                work_year: data.work_year,
                work_month: data.work_month,
                temp_version: data.temp_version,
            },
            "details",
            {
                work_day: data.work_day,
            }
        ]
        console.log([importantCtrls, haccpUtils.cloneDeep(dayPath)])
        importantCtrls = haccpUtils.let_by_path(importantCtrls, haccpUtils.cloneDeep(dayPath), data)

        storage.setItem(history_storage_key, importantCtrls)
        console.log("data")
        console.log(data)

        haccpUtils.request(f7, {
            url: '/important_hygienic_ctrl_detail',
            method: "POST",
            contentType: "application/json",
            crossDomain: true,
            headers: { Authorization: "Bearer ..." },
            data: data,
            dataType: "json",
            success: function (body, status, xhr) {
                console.log(body)
            },
            error: function (xhr, status, message) {
                console.log("error...:" + status);
            },
            complete: function (xhr, status) {
                console.log(xhr, status);
                complete();
            },
            statusCode: {},
        });
    },
    handleSubmitCancel: (e) => {
        e.preventDefault();
    },
}

const haccpStorage = {
    getUser: () => {
        return haccpStorage.getItemObject("#USER#user");
    },
    getAccount: () => {
        return haccpStorage.getItemObject("#USER#account");
    },
    setItem: (key, value) => {
        var storage = window.localStorage;
        if ((typeof value) == "object") {
            storage.setItem(key, JSON.stringify(value));
        } else {
            storage.setItem(key, value);
        }
        var KEY_EXPIRES = "#HaccpStorage#Expires"
        var expires = haccpStorage.hasItem(KEY_EXPIRES) ? haccpStorage.getItemObject(KEY_EXPIRES) : {};
        expires[key] = null;
        storage.setItem(KEY_EXPIRES, JSON.stringify(expires))
    },
    getItem: (key, default_value) => {
        var storage = window.localStorage;
        const text = storage.getItem(key);
        if (text == "" || text == null) return default_value;
        return text;
    },
    getItemObject: (key, default_value) => {
        var storage = window.localStorage;
        const text = storage.getItem(key);
        if (text == "" || text == null) return default_value;
        return JSON.parse(text);
    },
    hasItem: (key) => {
        var storage = window.localStorage;
        return storage.hasOwnProperty(key);
    },
    clearItems: (keys = null) => {
        var storage = window.localStorage;
        var KEY_EXPIRES = "#HaccpStorage#Expires"
        var expires = haccpStorage.hasItem(KEY_EXPIRES) ? haccpStorage.getItemObject(KEY_EXPIRES) : {};
        Object.keys(expires).map(key => storage.removeItem(key));
        storage.removeItem(KEY_EXPIRES);
        haccpUtils.updatePermissionDisplay();

    }
};

export default haccpUtils
