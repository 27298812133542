import React, { useRef } from 'react';
import {
  Page,
  Navbar,
  NavLeft,
  NavRight,
  NavTitle,
  Block,
  List,
  ListItem,
  f7,
  Button,
  ListInput,
  Link,
  Row,
  Col,
  Swiper,
  SwiperSlide,
  ListButton,
  View,
  Icon,
  Fab,
} from 'framework7-react';

import Dom7 from 'dom7';
import ReactDOM from 'react-dom';
import '../css/grid.css'
import haccpUtils from '../js/haccp-utils.js';

const TodayGeneralCtrl = () => {
  var $$ = Dom7
  const storage = haccpUtils.getStorage();
  const storage_key = "#USER#general-hygienic-ctrl-plan"

  const current_storage_key = "#USER#CurrentGeneralCtrl";
  var currentGeneralCtrl = storage.hasItem(current_storage_key) ? storage.getItemObject(current_storage_key) : {
      work_year: -1,
      work_month: -1,
      work_day: -1,
    }
  if(currentGeneralCtrl.work_year == -1) {
    var a = haccpUtils.getToday("yyyy/m/d").split("/")
    currentGeneralCtrl.work_year = a[0]
    currentGeneralCtrl.work_month = a[1]
    currentGeneralCtrl.work_day = a[2]
  }

  var 年月日 = currentGeneralCtrl.work_year + "年" + currentGeneralCtrl.work_month + "月"　+ currentGeneralCtrl.work_day + "日"

  const ctrl_storage_key = "#USER#general-hygienic-ctrl"
  const actions = useRef(null);
  const onPageBeforeRemove = () => {
    actions.current.destroy();
  };

  const initProcess1 = () => {
    haccpUtils.request(f7, {
      url: '/general_hygienic_ctrl',
      method: "GET",
      contentType: "application/json",
      crossDomain: true,
      headers: {Authorization: "Bearer ..."},
      data: {},
      dataType: "json",
      success: function (body, status, xhr){
        storage.setItem(ctrl_storage_key, body.data.generalCtrl)
        initProcess2()
      },
      error: function (xhr, status, message) {
        console.log("error...");
      },      
      complete: function (xhr, status) {
        console.log(xhr, status);
      },
      statusCode: {
        401: function(xhr) {
          console.log(xhr)
        }
      },
    });
  }

  const initProcess2 = () => {
    haccpUtils.request(f7, {
      url: '/general_hygienic_ctrl_plan',
      method: "GET",
      contentType: "application/json",
      crossDomain: true,
      headers: { Authorization: "Bearer ..." },
      data: {},
      dataType: "json",
      success: function (body, status, xhr) {
        // console.log("generalPlan",body.data.generalPlan);
        const generalPlan = body.data.generalPlan[0];
        render管理計画初期表示(currentGeneralCtrl, generalPlan)
      },
      error: function (xhr, status, message) {
        console.log("error...:" + status);
      },
      complete: function (xhr, status) {
        console.log(xhr, status);
      },
      statusCode: {},
    });
  }
  const onPageInit = () => {
    actions.current = f7.actions.create({})
    initProcess1()
  };

  const render管理計画初期表示 = (currentGeneralCtrl, generalPlan) => {
    // console.log('currentGeneralCtrl',currentGeneralCtrl)
    // console.log('generalCtrl',generalCtrl)

    const generalCtrl = storage.getItemObject(ctrl_storage_key)
    const validGeneralPlan = generalPlan.children.filter(plan => {return plan.del_flg == 0})
    // console.log('validGeneralPlan',validGeneralPlan)

    var general_hygienic_ctrl = null
    var detail = null
    var account = haccpUtils.getLoginAccount()
    var name = "ゲスト"
    if(account && account.user) name = account.user.last_name + account.user.first_name
  
    if(generalCtrl.length == 0) {
        // 親新規、子新規
        general_hygienic_ctrl = {id: "?", details: []}
        detail = {id: "?",daily_check: name,admin_memo: name}
    } else {
      var children = generalCtrl[0].children.filter(child => {
        return child.work_year == currentGeneralCtrl.work_year && child.work_month == currentGeneralCtrl.work_month
          && child.details.filter(detail => {return detail.work_day == currentGeneralCtrl.work_day}).length > 0
      })
      if(children.length == 1) {
      // 子あり
          // 親あり、子あり
          general_hygienic_ctrl = children[0]
          const details = general_hygienic_ctrl.details.filter(detail => {
            return detail.work_day == currentGeneralCtrl.work_day;
          })
          detail = details[0]        
      } else {
      // 子なし
        children = generalCtrl[0].children.filter(child => {
        return child.work_year == currentGeneralCtrl.work_year && child.work_month == currentGeneralCtrl.work_month
                  && validGeneralPlan[0].temp_version == child.temp_version
                  && validGeneralPlan[0].plan_version == child.plan_version
        })
        if (children.length == 1) {
        // 親あり、子新規
          // 指定した日付が存在しない場合、新規追加となるため、detailのidに "?" を設定
          general_hygienic_ctrl = children[0]
          detail = {id: "?",daily_check: name,admin_memo: name}
        } else {
        // 親新規、子新規
          general_hygienic_ctrl = {id: "?", details: []}
          detail = {id: "?",daily_check: name,admin_memo: name}
        }
      }
  }

  // console.log('general_hygienic_ctrl',general_hygienic_ctrl)
  // console.log('detail',detail)

    // 指定した日付が存在せず新規追加の場合(id == "?")、最新の計画(del_flg == 0)の計画を検索
    // 指定した日付が存在し、idが取得できた場合、そのidの持つ計画id(general_hygienic_ctrl_plan_id)の計画を検索
    var plan = generalPlan;
    if(detail.id == "?") {
      generalPlan = validGeneralPlan
      if(generalPlan.length != 1) throw Error("システムエラー：有効な管理計画のデータに不備があります。致命的なエラーです")
      plan = generalPlan[0]
    } else {
      generalPlan = generalPlan.children.filter(plan => {return plan.id == detail.general_hygienic_ctrl_plan_id})
      if(generalPlan.length != 1) throw Error("システムエラー：指定された管理計画のデータに不備があります。致命的なエラーです")
      plan = generalPlan[0]
    }

    // console.log('generalPlan',generalPlan)
    // console.log('plan',plan)

    // detail   指定された日付の管理記録情報。新規の場合は空
    // plan     指定された日付の管理記録で参照された管理計画情報。
    // currentGeneralCtrl ログイン時の日付及びログイン時の最新管理計画情報等

    const hygienic_ctrl_work = (item_id,detail) => {
      switch (item_id){
        case '1': return detail.hygienic_ctrl_work_1;
        case '2': return detail.hygienic_ctrl_work_2;
        case '3': return detail.hygienic_ctrl_work_3;
        case '4': return detail.hygienic_ctrl_work_4;
        case '5': return detail.hygienic_ctrl_work_5;
        case '6': return detail.hygienic_ctrl_work_6;
        case '7': return detail.hygienic_ctrl_work_7;
        default: return ""
      }
    }

    const hygienic_ctrl_work_val = (item_id,detail) => {
      switch (item_id){
        case '1': return detail.hygienic_ctrl_work_1_val;
        case '2': return detail.hygienic_ctrl_work_2_val;
        case '3': return detail.hygienic_ctrl_work_3_val;
        case '4': return detail.hygienic_ctrl_work_4_val;
        case '5': return detail.hygienic_ctrl_work_5_val;
        case '6': return detail.hygienic_ctrl_work_6_val;
        case '7': return detail.hygienic_ctrl_work_7_val;
        default: return ""
      }
    }

    const block_data = (
      <Block>
        <input type="hidden" name="id" value={detail.id} />
        {/* <input type="hidden" name="using_temp_version" value={detail.temp_version} /> */}

        <input type="hidden" name="general_hygienic_ctrl_plan_id" value={plan.id} />
        <input type="hidden" name="shop_id" value={plan.shop_id} />
        <input type="hidden" name="department_id" value={plan.department_id} />
        <input type="hidden" name="plan_version" value={plan.plan_version} />
        <input type="hidden" name="temp_version" value={plan.temp_version} />

        <input type="hidden" name="work_year" value={currentGeneralCtrl.work_year} />
        <input type="hidden" name="work_month" value={currentGeneralCtrl.work_month} />
        <input type="hidden" name="work_day" value={currentGeneralCtrl.work_day} />

        {/* 後からチェック！ 未使用なら削除 */}
        <input type="hidden" name="general_hygienic_ctrl_id" value={general_hygienic_ctrl.id} />

        <List>
          <Swiper pagination scrollbar>
            {plan.details.map((item, idx) =>
              <SwiperSlide>
                <div width="300px" class="overflow-wrap" style = {{fontSize: "18px", margin: "0px 0px 10px 0px"}}>
                  {/* <Icon f7="doc_checkmark" size="21px" color="blue"></Icon>{item.item_no + item.item_name} */}
                  <Icon f7="doc_checkmark" size="21px" color="blue"></Icon>{item.item_name}
                </div>
                <List noHairlinesMd>
                  <input type="hidden" name={"hygienic_ctrl_work_" + item.item_id} value = {hygienic_ctrl_work(item.item_id, detail)}/>

                  <ListButton
                    className="daily-create-button"
                    onClick={() => {
                      openGeneralCtrlTodayCreate(item, {
                        hygienic_ctrl_work: "hygienic_ctrl_work_" + item.item_id,
                      })
                      f7.dialog.open("#haccp-popup")
                    }}
                  >

                    <ListInput
                      className="daily-create-window"
                      type="text"
                      defaultValue="一日の結果"
                      readonly={true}
                      style = {{fontSize: "18px"}}
                    />
                    <input type="hidden" name={"hygienic_ctrl_work_" + item.item_id} value = {hygienic_ctrl_work(item.item_id, detail)}/>
                    <ListInput
                      className="daily-create-window"
                      name={"hygienic_ctrl_work_" + item.item_id + "_val"}
                      placeholder="未記入・未確認"
                      type="text"
                      defaultValue = {hygienic_ctrl_work_val(item.item_id, detail)}
                      readonly={true}
                      inputStyle = { item.item_id == "0" ? {color: "Block"} : hygienic_ctrl_work(item.item_id, detail) == "1" ? {color: "blue"} : hygienic_ctrl_work(item.item_id, detail) == "2" ? {color: "red"} : {color: "Block"}}
                    />


                    {/* <ListInput
                      className="daily-create-window"
                      label="一日の結果　　　　　　"
                      name={"hygienic_ctrl_work_" + item.item_id + "_val"}
                      placeholder="未記入・未確認"
                      type="text"
                      defaultValue = {hygienic_ctrl_work_val(item.item_id, detail)}
                      readonly={true}
                      inputStyle = { item.item_id == "0" ? {color: "Block"} : hygienic_ctrl_work(item.item_id, detail) == "1" ? {color: "blue"} : hygienic_ctrl_work(item.item_id, detail) == "2" ? {color: "red"} : {color: "Block"}}
                    /> */}
                  </ListButton>



                </List>
                <div width="300px" class="overflow-wrap" style = {{fontSize: "18px", margin: "25px 0px 25px 0px"}}>
                  <Icon f7="doc_text" size="21px" color="blue"></Icon>管理計画
                </div>
                <List noHairlinesMd>
                  <ListButton onClick={() => {
                    openGeneralPlanDetail(item, {
                      work_time: "work_time_" + item.id,
                      method_element: "method_element_" + item.id,
                      problem_occured: "problem_occured_" + item.id,
                    })
                    f7.dialog.open("#haccp-popup")
                  }}
                  >
                    <ListInput
                      name={"work_time_" + item.id}
                      label="いつ　　　　　　"
                      // floatingLabel
                      type="textarea"
                      defaultValue={item.work_time}
                      readonly={true}
                    />
                    <ListInput
                      name={"method_element_" + item.id}
                      label="どのように　　　　"
                      // floatingLabel
                      type="textarea"
                      defaultValue={item.method_element}
                      readonly={true}
                    />
                    <ListInput
                      name={"problem_occured_" + item.id}
                      label="問題があったとき"
                      // floatingLabel
                      type="textarea"
                      defaultValue={item.problem_occured}
                      readonly={true}
                    />
                  </ListButton>
                </List>
              </SwiperSlide>
            )}
            <SwiperSlide>
              <List noHairlinesMd>
                <ListInput
                  label="日々チェック"
                  type="text"
                  name="daily_check"
                  placeholder="日々チェック"
                  defaultValue={detail.daily_check}
                  maxlength="40"
                >
                </ListInput>
                <ListInput
                  label="特記事項"
                  type="textarea"
                  name="memo"
                  placeholder="特記事項"
                  defaultValue={detail.memo}
                  maxlength="200"
                >
                </ListInput>
                <ListInput
                  label="確認者"
                  type="text"
                  name="admin_memo"
                  placeholder="確認者"
                  defaultValue={detail.admin_memo}
                  maxlength="200"
                >
                </ListInput>
              </List>
              <Block strong>
                <Row>
                  <Col>
                    <Button color="blue" onClick={(e) => {
                      if(!haccpUtils.validateForm($$(e.target).closest("form"), e.target)) return false;
                      storeData(
                        () => {
                          haccpUtils.modifyImportantCtrlDate(currentGeneralCtrl.shop_id, currentGeneralCtrl.work_year, currentGeneralCtrl.work_month, currentGeneralCtrl.work_day)
                          f7.views.get(".view-main").router.navigate({ name: 'TodayImportantCtrl' });
                        }
                      );
                    }} style = {{fontSize: "21px"}}>保存して重要記録へ進む</Button>
                  </Col>
                </Row>
              </Block>
            </SwiperSlide>
          </Swiper>
        </List>
      </Block>
    )

    haccpUtils.lazyRender("#page_一般記録.page-current form", block_data)
  }

  const popupClose = (params) => {
    console.log(params)

    return () => {
      var storedData = f7.form.convertToData('form');
      if (storedData) {
        var databaseData = storage.getItemObject(storage_key, defaultData)
        databaseData[params.id] = storedData
        storage.setItem(storage_key, databaseData)
        f7.popup.close("#haccp-popup");
      } else {
        alert('Yet there is no stored data for this form. Please try to change any field')
      }
    }
  }


  // 一般管理計画内容_項目別
  const openGeneralPlanDetail = (item, elems) => {
    const onClick = () => {
      var data = f7.form.convertToData("#haccp-popup form");
      for(let name in elems) {
        $$(".page-current [name='" + elems[name] + "']").val(data[name])
      }
      var sendData = data;
      sendData.id = item.id;
      console.log('sendData',sendData)
      storePlanData(sendData)
    }
    var view = (
      <View>
        <Page>
          {/* <Navbar title={年月日 + " " + item.item_no + item.item_name}> */}
          <Navbar title={年月日 + " " + item.item_name}>
            <NavRight>
              <Link popupClose iconF7="xmark"></Link>
            </NavRight>
          </Navbar>
          <form></form>
        </Page>
      </View>
    )
    ReactDOM.render(view, document.getElementById("haccp-popup"))
    var form = $$("#haccp-popup form")
    var values = {}
    for(let name in elems) {
      values[name] = $$(".page-current [name='" + elems[name] + "']").val()
    }
    ReactDOM.render(blockGeneralPlanDetail(values, onClick), form[form.length-1])
  }

  const blockGeneralPlanDetail = (item, onclick) => {
    return (
      <Block>
        <List noHairlinesMd>
          <div style = {{fontSize: "14px", color: "red", margin: "5px 10px 15px 10px"}}>こちらは、管理計画の編集画面となります。この計画で記録を行った過去の計画も更新されますのでご注意下さい。新規で計画を作成する際は、一度キャンセルにてポップアップ画面を閉じ、画面左上のメニューボタンにてメニューを開いて一般衛生管理計画画面へと進み新規作成を行って下さい。</div>
        </List>
        <List noHairlinesMd>
          <ListInput
            label="いつ"
            // floatingLabel
            type="textarea"
            name="work_time"
            defaultValue={item.work_time}
            placeholder
            maxlength="400"
            >
          </ListInput>
          <ListInput
            label="どのように"
            // floatingLabel
            type="textarea"
            name="method_element"
            defaultValue={item.method_element}
            placeholder
            maxlength="400"
          >
          </ListInput>
          <ListInput
            label="問題があったとき"
            // floatingLabel
            type="textarea"
            name="problem_occured"
            defaultValue={item.problem_occured}
            placeholder
            maxlength="400"
          >
          </ListInput>
        </List>
        <Block strong>
          <Row noGap>
            <Col><Button onClick = {onclick} popupClose style = {{fontSize: "21px"}}>編集を保存</Button></Col>
            <Col><Button popupClose style = {{fontSize: "21px"}}>キャンセル</Button></Col>
          </Row>
        </Block>
      </Block>
    )
  }

  // 一般衛生管理実施当日記録_項目別
  const openGeneralCtrlTodayCreate = (item, elems) => {
    const onClick = () => {
      var data = f7.form.convertToData("#haccp-popup form");
      for(let name in elems) {
        $$(".page-current [name='" + elems[name] + "']").val(data[name])
        $$(".page-current [name='" + elems[name] + "_val']").val((item.item_id == 0) ? data[name] : (data[name] == 1) ? "良" : (data[name] == 2) ? "否" : "")
        $$(".page-current [name='" + elems[name] + "_val']").prop("style", (item.item_id == 0) ? "color: black" : (data[name] == 1) ? "color: blue" : (data[name] == 2) ? "color: red" : "color: black")
      }
    }
    var view = (
      <View>
        <Page>
          {/* <Navbar title={年月日 + " " + item.item_no + item.item_name}> */}
          <Navbar title={年月日 + " " + item.item_name}>
            <NavRight>
              <Link popupClose iconF7="xmark"></Link>
            </NavRight>
          </Navbar>
          <form></form>
        </Page>
      </View>
    )
    ReactDOM.render(view, document.getElementById("haccp-popup"))
    var form = $$("#haccp-popup form")
    var values = {item_id: item.item_id}
    for(let name in elems) {
      values[name] = $$(".page-current [name='" + elems[name] + "']").val()
    }
    ReactDOM.render(blockGeneralCtrlTodayCreate(values, onClick), form[form.length-1])
  }
  const blockGeneralCtrlTodayCreate = (item, onclick) => {
    return (
      <Block>
        {(() => {
          if (item.item_id == 0) {
            return (
              <List>
                <ListInput
                  label="(°C)"
                  type="text"
                  name="hygienic_ctrl_work"
                  defaultValue={item.hygienic_ctrl_work}
                  maxlength="20"
                >
                </ListInput>
              </List>
            )
          } else {
            return (
              <List>
                <ListItem
                  radio
                  radioIcon="start"
                  title="良"
                  value="1"
                  defaultChecked={item.hygienic_ctrl_work == 1}
                  name="hygienic_ctrl_work"
                  style = {{color: "blue", fontSize: "21px"}}
                ></ListItem>
                <ListItem
                  radio
                  radioIcon="start"
                  title="否"
                  value="2"
                  defaultChecked={item.hygienic_ctrl_work == 2}
                  name="hygienic_ctrl_work"
                  style = {{color: "red", fontSize: "21px"}}
                ></ListItem>
              </List>
            )
          }
        })()}
        <Block strong>
          <Row noGap>
            <Col><Button onClick={onclick} popupClose style = {{fontSize: "21px"}}>記録</Button></Col>
            <Col><Button popupClose style = {{fontSize: "21px"}}>キャンセル</Button></Col>
          </Row>
        </Block>
      </Block>
    )
  }

  const storeData = (complete) => {
    var storedData = f7.form.convertToData("#page_一般記録.page-current form")
    console.log('storedData',storedData)
    
    var plan = [];
    var id = 0;
    var idx = 0;
    var name = "";

    // // 選ばないで、すべて対象
    // for (let key in storedData) {
    //   if(key.indexOf("hygienic_ctrl_work_") === 0) {
    //     currentGeneralCtrl[key] = storedData[key]
    //   }
    //     ["id","shop_id","department_id","plan_version","general_hygienic_ctrl_id","general_hygienic_ctrl_plan_id","daily_check", "admin_memo", "memo"].forEach(name => {
    //     if(name == key) {
    //       currentGeneralCtrl[key] = storedData[key]
    //     }
    //   });
    // }
    currentGeneralCtrl = storedData

    // TODO Plan を差し込む必要がある？
    //currentGeneralCtrl["details"] = Object.values(plan)
    // ["work_time", "method_element", "problem_occured"].forEach(name => {
    //     if(key.indexOf(name + "_") === 0) {
    //       id = parseInt(key.split(name + "_")[1])
    //       idx = "_" + id
    //       if (plan[idx] == undefined) plan[idx] = {}
  
    //       plan[idx]["id"] = id
    //       plan[idx][name] = storedData[key]
    //     }
    //   })
    // console.log("plan",plan)

    // console.log("currentGeneralCtrl",currentGeneralCtrl)

    haccpUtils.updateGeneralCtrlDate(f7, currentGeneralCtrl, complete)
  }

  const storePlanData = (planItem) => {
    var storePlanData = f7.form.convertToData("#page_一般記録.page-current form")
    console.log('storePlanData',storePlanData)
    // console.log('planItem',planItem)
    
    var plan = [];
    var id = 0;
    var idx = 0;
    var name = "";

    updateGeneralPlanDate({plan: planItem})
  }

  const updateGeneralPlanDate = (storeData) => {
    haccpUtils.request(f7, {
      url: '/general_plan_update',
      method: "POST",
      contentType: "application/json",
      crossDomain: true,
      headers: {Authorization: "Bearer ..."},
      data: storeData, // TODO 変更中 (変更済) OK
      dataType: "json",
      success: function (body, status, xhr){
        console.log("success...");
        storage.setItem(storage_key, storeData["plan"])
      },
      error: function (xhr, status, message) {
        console.log("error...");
      },      
      complete: function (xhr, status) {
        console.log(xhr, status);
      },
      statusCode: {
        401: function(xhr) {
          console.log(xhr)
        }
      },
    });
  }

  return (
    <Page id="page_一般記録" onPageBeforeRemove={onPageBeforeRemove} onPageInit={onPageInit}>
      <Fab position="right-top" slot="fixed" color="white"
        onClick={() => {
          const account = haccpUtils.getLoginAccount()
          var targetDay = new Date();
          targetDay.setDate(targetDay.getDate());
          // console.log("targetDay", targetDay)
          var calendar = f7.calendar.create({
            locale: "en-US",
            monthNames: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月' , '9月' , '10月', '11月', '12月'],
            monthNamesShort: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月' , '9月' , '10月', '11月', '12月'],
            dayNames: ['日', '月', '火', '水', '木', '金', '土'],
            dayNamesShort: ['日', '月', '火', '水', '木', '金', '土'],
            firstDay: 0,
            maxDate: targetDay,
            //2015年10月1日から2015年12月31日までのすべての日付を無効にする
            // minDate: "2021-08-31T15:00:00Z",
            //maxDate: "2021-11-30T15:00:00Z",
            /*disabled: {
                from: new Date(2021, 9, 1),
                to: new Date(2021, 11, 31)
            },*/
            closeOnSelect: true,
            on: {
              opened: function () {
                console.log('Calendar opened')
              },
              closed: function () {
                console.log('Calendar closed')
                if(calendar.value) {
                  var d = new Date(Date.parse(calendar.value[0]))
                  var shopid = account["shop_id"]
                  var year = d.getFullYear()
                  var month = d.getMonth() + 1
                  var day = d.getDate()
                  haccpUtils.modifyGeneralCtrlDate(shopid, year, month ,day)
                  f7.views.get(".view-main").router.navigate({name: 'CalendarGeneralCtrl',params: {targetDate: [year, month ,day].join("-")}})
                }
              },
            }
          });
          calendar.open()
        }}
      >
        <Icon f7="calendar_badge_plus" size="22px" color="teal"></Icon>
      </Fab>

      <Navbar className="general">
        <NavLeft>
          <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" />
        </NavLeft>
        <NavTitle style = {{fontSize: "20px"}}>{年月日 + " 一般記録"}</NavTitle>
        <NavRight>
          <Link href="/home/" view=".view-main" panelClose iconF7="house"></Link>
        </NavRight>
      </Navbar>
      <form></form>
    </Page>
  );
};
export default TodayGeneralCtrl;
