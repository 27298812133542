import React,{ useRef , useState } from 'react';
import {
  AccordionContent,
  Block,
  BlockTitle,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardFooter,
  Col,
  f7,
  Fab,
  Icon,
  Link,
  List,
  ListItem,
  NavLeft,
  NavRight,
  NavTitle,
  Navbar,
  Page,
  Row,
  Segmented,
  View,
} from 'framework7-react';

import Dom7 from 'dom7';
import ReactDOM from 'react-dom';

import '../css/grid.css'

import haccpUtils from '../js/haccp-utils.js';

const ImportantHygienicCtrl = () => {
  var $$ = Dom7
  const storage_key = "#USER#important-hygienic-ctrl"
  const storage_key_list_select_id = "#USER#important-list-select-id"
  const storage_key_clear_target_id = "#USER#important-clear-target-id"
  const storage = haccpUtils.getStorage();
  const actions = useRef(null);
  const onPageBeforeRemove = () => {
    actions.current.destroy();
  };
  const onPageInit = () => {
    actions.current = f7.actions.create({});
    haccpUtils.request(f7, {
      url: '/important_hygienic_ctrl',
      method: "GET",
      contentType: "application/json",
      crossDomain: true,
      headers: {Authorization: "Bearer ..."},
      data: {},
      dataType: "json",
      success: function (body, status, xhr){
        console.log("getdatadatadata",body.data.importantCtrl);
        storage.setItem(storage_key, body.data.importantCtrl)
      },
      error: function (xhr, status, message) {
        console.log("error...");
      },      
      complete: function (xhr, status) {
        console.log(xhr, status);
        renderList()
      },
      statusCode: {
        401: function(xhr) {
          console.log(xhr)
        }
      },
    });
  };

  const renderList = () => {
    const importantCtrl = storage.getItemObject(storage_key)
    const block_data = (
      <Block className="no-padding">
      {importantCtrl.map(item =>
        <Block strong className="no-padding">
          <input type="hidden" name="shop_name" value={item.shop_name} />
          <input type="hidden" name="department_name" value={item.department_name} />

          {item.children.sort((o1, o2) => (o1.work_year !== o2.work_year) ? (o1.work_year - o2.work_year > 0 ? 1 : -1) : (o1.work_month - o2.work_month > 0 ? 1 : -1)).map(child => 
            <List>
              <ListItem
                accordionItem
                header={"作成月"}
                title={child.work_year + "年" + child.work_month + "月"} iconF7="folder_fill"
              >
                <AccordionContent className='accordion-list'>
                  <List>
                    <ListItem
                      header="日別"
                      title="実施記録一覧"
                      href="#"
                      onClick={popupOpenEdit({child})}
                      popupOpen="#haccp-popup"
                      iconF7="doc_checkmark"
                    >
                    </ListItem>
                    {/* システム管理者のみ表示の記録一覧 */}
                    <ListItem
                      className="haccp-role-invisible haccp-role-system"
                      header={"日別 id=" + child.id}
                      link="/Important-Hygienic-Ctrl-List/" view=".view-main" panelClose title="実施記録月別一覧"
                      onClick={() => listSelectId({child})}
                    />
                    <ListItem
                      header="PDF"
                      title="実施記録出力"
                      href="#"
                      onClick={() => haccpUtils.downloadPDF(f7, "report", "important-report", child.id)}
                      iconF7="doc_text_fill"
                    >
                    </ListItem>
                    {child.details.map(detail => detail.important_hygienic_ctrl_plan_id).filter(function(elem, index, self) {return self.indexOf(elem) === index}).map(plan_id =>
                      <ListItem
                        header="PDF"
                        title={"管理計画出力" + child.plan_version}
                        href="#"
                        onClick={() => haccpUtils.downloadPDF(f7, "report", "important-plan", plan_id)}
                        iconF7="doc_text_fill"
                      >
                      </ListItem>
                    )}
                  </List>
                </AccordionContent>
              </ListItem>
            </List>
          )}
        </Block>
      )}
      </Block>
    )
    haccpUtils.lazyRender("#page重要衛生管理記録実施.page-current form", block_data)
  }

  const popupOpen = (popupElements, block, params) => {
    var popup = $$("#haccp-popup")
    const view = (
      <View>
        <Page>
          <Navbar title={popupElements.title}>
            <NavRight>
              <Link popupClose iconF7="xmark"></Link>
            </NavRight>
          </Navbar>
          <form></form>
        </Page>
      </View>
    )
    ReactDOM.render(view, popup[0])
    var form = $$("#haccp-popup form")
    ReactDOM.render(block, form[0])
  }  

  const openDataClearImportantCtrl = (detail) => {
    return ()=>{
      storage.setItem(storage_key_clear_target_id, detail)
      f7.views.get(".view-main").router.navigate({name: 'DataClearImportantCtrl'});
    }
  }

  const popupOpenEdit = (params) => {
    console.log("[popupOpenEdit]")
    console.log(params)
    const openImportantCtrl = (shop_id, workYear, workMonth, workDay) => {
      return ()=>{
        haccpUtils.modifyImportantCtrlDate(shop_id, workYear, workMonth, workDay)
        f7.views.get(".view-main").router.navigate({name: 'CalendarImportantCtrl', params: {targetDate: [workYear, workMonth, workDay].join("-")}});
      }
    }
    return () => {

      const hyw_word = (code) => {return code ==  "1" ? "良" : code ==  "2" ? "否" : "未"};
      const hyw_color = (code) => {return code ==  "1" ? "blue" : code ==  "2" ? "red" : "black"};

      const hyw_color_sp1 = (code) => {return code ==  "1" ? "blue-circle" : "transparent-circle"};
      const hyw_color_sp2 = (code) => {return code ==  "2" ? "red-circle" : "transparent-circle"};

      const clear_button_style = (code1, code2, code3, code4, code5, code6, code7) => {return (code1 != "1" && code1 != "2") && (code2 !=  "1" && code2 != "2") && (code3 !=  "1" && code3 != "2") && (code4 !=  "1" && code4 != "2") && (code5 !=  "1" && code5 != "2") && (code6 !=  "1" && code6 != "2") && (code7 !=  "1" && code7 != "2") ? "button_hidden" : "button_disp"};
      const clear_button_disabled = (code1, code2, code3, code4, code5, code6, code7) => {return (code1 != "1" && code1 != "2") && (code2 !=  "1" && code2 != "2") && (code3 !=  "1" && code3 != "2") && (code4 !=  "1" && code4 != "2") && (code5 !=  "1" && code5 != "2") && (code6 !=  "1" && code6 != "2") && (code7 !=  "1" && code7 != "2") ? "disabled" : ""};

      const lists = params.child;
      const title = lists.work_year + "年" + lists.work_month + "月実施記録"
      const block_edit = (
        // <div class="card data-table">
        //   <table>
        //     <thead>
        //       <tr>
        //         <th>分類</th>
        //         <th class="category-title">{lists.header_1_1}</th>
        //         <th class="category-title">{lists.header_1_2}</th>
        //         <th class="category-title">{lists.header_1_3}</th>
        //         <th class="category-title">{lists.header_1_4}</th>
        //         <th class="category-title">{lists.header_1_5}</th>
        //         <th class="table-daily_check" rowspan="2">日々<br />チェック</th>
        //         <th class="table-memo" rowspan="2">特記事項</th>
        //         <th class="table-admin-memo" rowspan="2">確認者</th>
        //       </tr>
        //       <tr>
        //         <th class="menu-title"><span>メニュー</span></th>
        //         <th class="menu">{lists.header_2_1}</th>
        //         <th class="menu">{lists.header_2_2}</th>
        //         <th class="menu">{lists.header_2_3}</th>
        //         <th class="menu">{lists.header_2_4}</th>
        //         <th class="menu">{lists.header_2_5}</th>
        //       </tr>
        //     </thead>
        //     <tbody>
        //       {lists.details.sort((o1, o2) => (o1.work_day - o2.work_day > 0 ? 1 : -1)).map(detail=>
        //         <tr>
        //           <td>
        //             <Link href="#important-hygienic-ctrl-daily-update" onClick={openImportantCtrl(lists.shop_id, lists.work_year,lists.work_month,detail.work_day)} popupClose>{detail.work_day}日</Link>
        //           </td>
        //           <td style = {{color: hyw_color(detail.hygienic_ctrl_work_1)}}>{hyw_word(detail.hygienic_ctrl_work_1)}</td>
        //           <td style = {{color: hyw_color(detail.hygienic_ctrl_work_2)}}>{hyw_word(detail.hygienic_ctrl_work_2)}</td>
        //           <td style = {{color: hyw_color(detail.hygienic_ctrl_work_3)}}>{hyw_word(detail.hygienic_ctrl_work_3)}</td>
        //           <td style = {{color: hyw_color(detail.hygienic_ctrl_work_4)}}>{hyw_word(detail.hygienic_ctrl_work_4)}</td>
        //           <td style = {{color: hyw_color(detail.hygienic_ctrl_work_5)}}>{hyw_word(detail.hygienic_ctrl_work_5)}</td>
        //           <td>{detail.daily_check}</td>
        //           <td>{detail.memo}</td>
        //           <td>{detail.admin_memo}</td>
        //         </tr>
        //       )}
        //     </tbody>
        //   </table>
        // </div>

        <div class="card data-table">
          {lists.details.sort((o1, o2) => (o1.work_day - o2.work_day > 0 ? 1 : -1)).map(detail=>
            <Card className="ctrl-list" title={detail.work_day + " 日"}>
              <CardContent>
                <Row><Col className="hc-list-bk-g" width="80">分類　　:{lists.header_1_1}<br/>メニュー:{lists.header_2_1}</Col><Col width="20"><span class={hyw_color_sp1(detail.hygienic_ctrl_work_1)}>良</span>・<span class={hyw_color_sp2(detail.hygienic_ctrl_work_1)}>否</span></Col></Row>
                <Row><Col className="hc-list-bk-g" width="80">分類　　:{lists.header_1_2}<br/>メニュー:{lists.header_2_2}</Col><Col width="20"><span class={hyw_color_sp1(detail.hygienic_ctrl_work_2)}>良</span>・<span class={hyw_color_sp2(detail.hygienic_ctrl_work_2)}>否</span></Col></Row>
                <Row><Col className="hc-list-bk-g" width="80">分類　　:{lists.header_1_3}<br/>メニュー:{lists.header_2_3}</Col><Col width="20"><span class={hyw_color_sp1(detail.hygienic_ctrl_work_3)}>良</span>・<span class={hyw_color_sp2(detail.hygienic_ctrl_work_3)}>否</span></Col></Row>
                <Row><Col className="hc-list-bk-g" width="80">分類　　:{lists.header_1_4}<br/>メニュー:{lists.header_2_4}</Col><Col width="20"><span class={hyw_color_sp1(detail.hygienic_ctrl_work_4)}>良</span>・<span class={hyw_color_sp2(detail.hygienic_ctrl_work_4)}>否</span></Col></Row>
                <Row><Col className="hc-list-bk-g" width="80">分類　　:{lists.header_1_5}<br/>メニュー:{lists.header_2_5}</Col><Col width="20"><span class={hyw_color_sp1(detail.hygienic_ctrl_work_5)}>良</span>・<span class={hyw_color_sp2(detail.hygienic_ctrl_work_5)}>否</span></Col></Row>
                <Row><Col className="hc-list-bk-g" width="80">日々チェック</Col><Col width="20">{detail.daily_check}</Col></Row>
                <Row><Col className="hc-list-bk-g" width="20">特記事項</Col><Col width="80">{detail.memo}</Col></Row>
                <Row><Col className="hc-list-bk-g" width="80">確認者</Col><Col width="20">{detail.admin_memo}</Col></Row>
              </CardContent>
              <Segmented raised tag="p">
                <Button className="button_disp" onClick={openImportantCtrl(lists.shop_id, lists.work_year,lists.work_month,detail.work_day)} popupClose>編集</Button>

                {/* {console.log("btn-style",clear_button_style(detail.hygienic_ctrl_work_1, detail.hygienic_ctrl_work_2, detail.hygienic_ctrl_work_3, detail.hygienic_ctrl_work_4, detail.hygienic_ctrl_work_5))}
                {console.log("11111",detail.hygienic_ctrl_work_1)}
                {console.log("22222",detail.hygienic_ctrl_work_2)}
                {console.log("33333",detail.hygienic_ctrl_work_3)}
                {console.log("44444",detail.hygienic_ctrl_work_4)}
                {console.log("55555",detail.hygienic_ctrl_work_5)} */}

                <Button
                  disabled={clear_button_disabled(detail.hygienic_ctrl_work_1, detail.hygienic_ctrl_work_2, detail.hygienic_ctrl_work_3, detail.hygienic_ctrl_work_4, detail.hygienic_ctrl_work_5)}
                  className={clear_button_style(detail.hygienic_ctrl_work_1, detail.hygienic_ctrl_work_2, detail.hygienic_ctrl_work_3, detail.hygienic_ctrl_work_4, detail.hygienic_ctrl_work_5)}
                  color="red"
                  onClick={openDataClearImportantCtrl(detail)}
                  popupClose
                >記録を削除</Button>
              </Segmented>
              {/* お試しで一般は、ポップアップ画面（openImportantCtrl2）を作成したが重要は作成していない */}
              {/* <Button
                className="haccp-role-invisible haccp-role-system"
                onClick={openImportantCtrl2(lists.shop_id, lists.work_year,lists.work_month,detail.work_day)}
                popupClose
              >編集（縦スクロールバージョン）
              </Button> */}
            </Card>
          )}
        </div>
      )
      return popupOpen({
        title: title,
      }, block_edit, params)
    }
  }

  const listSelectId = (child)  => {
    storage.setItem(storage_key_list_select_id, child)    
  }

  return (
    <Page id="page重要衛生管理記録実施" onPageBeforeRemove={onPageBeforeRemove} onPageInit={onPageInit} >
      <Fab position="right-top" slot="fixed" color="white"
        onClick={() => {
          const account = haccpUtils.getLoginAccount()
          var targetDay = new Date();
          targetDay.setDate(targetDay.getDate());
          // console.log("targetDay", targetDay)
          var calendar = f7.calendar.create({
            locale: "en-US",
            monthNames: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月' , '9月' , '10月', '11月', '12月'],
            monthNamesShort: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月' , '9月' , '10月', '11月', '12月'],
            dayNames: ['日', '月', '火', '水', '木', '金', '土'],
            dayNamesShort: ['日', '月', '火', '水', '木', '金', '土'],
            firstDay: 0,
            maxDate: targetDay,
            //2015年10月1日から2015年12月31日までのすべての日付を無効にする
            //minDate: "2021-08-31T15:00:00Z",
            //maxDate: "2021-11-30T15:00:00Z",
            /*disabled: {
                from: new Date(2021, 9, 1),
                to: new Date(2021, 11, 31)
            },*/
            closeOnSelect: true,
            on: {
              opened: function () {
                console.log('Calendar opened')
              },
              closed: function () {
                console.log('Calendar closed')
                if(calendar.value) {
                  var d = new Date(Date.parse(calendar.value[0]))
                  var shopid = account["shop_id"]
                  var year = d.getFullYear()
                  var month = d.getMonth() + 1
                  var day = d.getDate()
                  haccpUtils.modifyImportantCtrlDate(shopid, year, month ,day)
                  f7.views.get(".view-main").router.navigate({name: 'CalendarImportantCtrl', params: {targetDate: [year, month, day].join("-")}})
                }
              },
            }
          });
          calendar.open()
        }}
      >
        <Icon f7="calendar_badge_plus" size="22px" color="teal"></Icon>
      </Fab>

      <Navbar className="important">
        <NavLeft>
          <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" />
        </NavLeft>
        <NavTitle style = {{fontSize: "20px"}}>重要衛生管理実施記録</NavTitle>
        <NavRight>
          <Link href="/home/" view=".view-main" panelClose iconF7="house"></Link>
        </NavRight>
      </Navbar>
      <BlockTitle>一覧</BlockTitle>
      <form></form>
    </Page>
  );
};
export default ImportantHygienicCtrl;