import React,{ useRef } from 'react';
import {
  Page,
  Navbar,
  Block,
  BlockTitle,
  List,
  f7,
  Row,
  Col,
  Button,
  ListInput,
  ListButton,
  Icon,
  Fab,
  NavLeft,
  NavRight,
  NavTitle,
  Link,
  View,
} from 'framework7-react';

import Dom7 from 'dom7';
import ReactDOM from 'react-dom';

import '../css/sortable.css';
import '../css/grid.css'

import haccpUtils from '../js/haccp-utils.js';

const TopPageInformation = () => {
  var $$ = Dom7

  const storage = haccpUtils.getStorage();
  const actions = useRef(null);

  const storage_key = "#USER#top-page-information"
  const onPageBeforeRemove = () => {
    actions.current.destroy();
  };
  const onPageInit = () => {
    actions.current = f7.actions.create({});
    haccpUtils.request(f7, {
      url: '/top_page_information',
      method: "GET",
      contentType: "application/json",
      crossDomain: true,
      headers: {Authorization: "Bearer ..."},
      data: {},
      dataType: "json",
      success: function (body, status, xhr){
        renderInit(body.data.topPageInformation)
        storage.setItem(storage_key, body.data.topPageInformation)
      },
      error: function (xhr, status, message) {
        console.log("error...");
      },      
      complete: function (xhr, status) {
        console.log(xhr, status);
      },
      statusCode: {
        401: function(xhr) {
          console.log(xhr)
        }
      },
    });

    actions.current = f7.actions.create({});
  };

  const renderInit = (topPageInformation) => {
    const block = (
      <Block strong className="no-padding">
        {topPageInformation.map(information =>
          <List noHairlinesMd>
            <input type="hidden" name="id" value={information.id} />
            <ListInput
              outline
              label="情報1"
              type="textarea"
              name="message1"
              placeholder="情報1"
              defaultValue={information.message1}
            ></ListInput>
            <ListInput
              outline
              label="情報2"
              type="textarea"
              name="message2"
              placeholder="情報2"
              defaultValue={information.message2}
            ></ListInput>
            <ListInput
              outline
              label="情報3"
              type="textarea"
              name="message3"
              placeholder="情報3"
              defaultValue={information.message3}
            ></ListInput>
            <ListInput
              outline
              label="バナーURL1"
              type="textarea"
              name="banner1"
              placeholder="バナーURL1"
              defaultValue={information.banner1}
            ></ListInput>
            <ListInput
              outline
              label="バナーリンク1"
              type="textarea"
              name="banner_link1"
              placeholder="バナーリンク1"
              defaultValue={information.banner_link1}
            ></ListInput>
            <ListInput
              outline
              label="バナーURL2"
              type="textarea"
              name="banner2"
              placeholder="バナーURL2"
              defaultValue={information.banner2}
            ></ListInput>
            <ListInput
              outline
              label="バナーリンク2"
              type="textarea"
              name="banner_link2"
              placeholder="バナーリンク2"
              defaultValue={information.banner_link2}
            ></ListInput>
            <ListInput
              outline
              label="バナーURL3"
              type="textarea"
              name="banner3"
              placeholder="バナーURL3"
              defaultValue={information.banner3}
            ></ListInput>
            <ListInput
              outline
              label="バナーリンク3"
              type="textarea"
              name="banner_link3"
              placeholder="バナーリンク3"
              defaultValue={information.banner_link3}
            ></ListInput>
            <ListInput
              outline
              label="バナーURL4"
              type="textarea"
              name="banner4"
              placeholder="バナーURL4"
              defaultValue={information.banner4}
            ></ListInput>
            <ListInput
              outline
              label="バナーリンク4"
              type="textarea"
              name="banner_link4"
              placeholder="バナーリンク4"
              defaultValue={information.banner_link4}
            ></ListInput>
            <ListInput
              outline
              label="バナーURL5"
              type="textarea"
              name="banner5"
              placeholder="バナーURL5"
              defaultValue={information.banner5}
            ></ListInput>
            <ListInput
              outline
              label="バナーリンク5"
              type="textarea"
              name="banner_link5"
              placeholder="バナーリンク5"
              defaultValue={information.banner_link5}
            ></ListInput>
            <ListInput
              outline
              label="備考"
              type="textarea"
              name="remark"
              placeholder="備考"
              defaultValue={information.remark}
            ></ListInput>
            <ListButton
              title="更新する"
              onClick={popupClose}
            />
          </List>
        )}
      </Block>
    )
    var form = $$("#topPageInformation form")
    ReactDOM.render(block, form[0])
  }

  const popupClose = () => {
    var storedData = f7.form.convertToData('#topPageInformation form');
    if(storedData) {
      var databaseData = storage.getItemObject(storage_key)
      console.log(storedData)

      var isNew = true
      for(let idx in databaseData) {
        if(databaseData[idx].id == storedData.id) {
          databaseData[idx] = storedData
          isNew = false
        }
      }
      if(isNew) {
        databaseData.push(storedData)
      }

      haccpUtils.request(f7, {
        url: '/top_page_information',
        method: "POST",
        contentType: "application/json",
        crossDomain: true,
        headers: {Authorization: "Bearer ..."},
        data: {topPageInformation: storedData},
        dataType: "json",
        success: function (body, status, xhr){
          console.log("success...");
          renderInit(body.data.result)
          storage.setItem(storage_key, body.data.result)
        },
        error: function (xhr, status, message) {
          console.log("error...");
        },      
        complete: function (xhr, status) {
          console.log(xhr, status);
        },
        statusCode: {
          401: function(xhr) {
            console.log(xhr)
          }
        },
      });

      storage.setItem(storage_key, databaseData)
      f7.popup.close("#haccp-popup");
    } else {
        alert('Yet there is no stored data for this form. Please try to change any field')
    }
  }

  return (
    <Page onPageBeforeRemove={onPageBeforeRemove} onPageInit={onPageInit} >
      <Navbar className="mst">
        <NavLeft>
          <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" />
        </NavLeft>
        <NavTitle style = {{fontSize: "20px"}}>トップページ情報</NavTitle>
      </Navbar>
      <Block id="topPageInformation" className="mst-list">
        <form></form>
      </Block>
    </Page>
  );
};
export default TopPageInformation;