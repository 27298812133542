import React, { useRef } from 'react';
import {
  Block,
  BlockTitle,
  Button,
  Col,
  f7,
  Fab,
  Icon,
  Link,
  List,
  ListItem,
  ListInput,
  Navbar,
  NavLeft,
  NavRight,
  NavTitle,
  Page,
  Row,
  View,
} from 'framework7-react';

import Dom7 from 'dom7';
import ReactDOM from 'react-dom';

import '../css/sortable.css';
import '../css/grid.css'

import haccpUtils from '../js/haccp-utils.js';

const DepartmentMst = () => {
  var $$ = Dom7

  const storage = haccpUtils.getStorage();
  const actions = useRef(null);

  const storage_key = "#USER#department-mst"
  const onPageBeforeRemove = () => {
    actions.current.destroy();
  };
  const onPageInit = () => {
    actions.current = f7.actions.create({});
    haccpUtils.request(f7, {
      url: '/department_mst',
      method: "GET",
      contentType: "application/json",
      crossDomain: true,
      headers: {Authorization: "Bearer ..."},
      data: {},
      dataType: "json",
      success: function (body, status, xhr){
        renderInit(body.data.departmentMst)
        storage.setItem(storage_key, body.data.departmentMst)
      },
      error: function (xhr, status, message) {
        console.log("error...");
      },      
      complete: function (xhr, status) {
        console.log(xhr, status);
      },
      statusCode: {
        401: function(xhr) {
          console.log(xhr)
        }
      },
    });

    actions.current = f7.actions.create({});
  };

  const renderInit = (departmentMst) => {
    const block_data = (
      <Block strong className="no-padding">
        <List>
          {departmentMst.map(department =>
            <ListItem
              className="mst-list-item"
              link="#"
              header={"ID:" + department.id}
              title={department.name}
              onClick={popupOpen({
                title: "部門マスタを編集する",
                message: "部門の修正を完了します。",
              }, department)}
              popupOpen="#haccp-popup"
              iconF7="doc_text"
              // after="詳細"
            >
            </ListItem>
          )}
        </List>
      </Block>
    )
    var block = $$("#departmentMstList")
    ReactDOM.render(block_data, block[0])
  }

  const popupClose = () => {
    var storedData = f7.form.convertToData('#haccp-popup form');
    if(storedData) {
      var databaseData = storage.getItemObject(storage_key)
      console.log(storedData)

      var isNew = true
      for(let idx in databaseData) {
        if(databaseData[idx].id == storedData.id) {
          databaseData[idx] = storedData
          isNew = false
        }
      }
      if(isNew) {
        databaseData.push(storedData)
      }

      haccpUtils.request(f7, {
        url: '/department_mst',
        method: "POST",
        contentType: "application/json",
        crossDomain: true,
        headers: {Authorization: "Bearer ..."},
        data: {departmentMst: storedData},
        dataType: "json",
        success: function (body, status, xhr){
          console.log("success...");
          renderInit(body.data.result)
          storage.setItem(storage_key, body.data.result)
        },
        error: function (xhr, status, message) {
          console.log("error...");
        },      
        complete: function (xhr, status) {
          console.log(xhr, status);
        },
        statusCode: {
          401: function(xhr) {
            console.log(xhr)
          }
        },
      });

      storage.setItem(storage_key, databaseData)
      f7.popup.close("#haccp-popup");
    } else {
        alert('Yet there is no stored data for this form. Please try to change any field')
    }
  }

  const popupOpen = (popupElements, department) => {
    return () => {
      var view = (
        <View>
          <Page>
            <Navbar title={popupElements.title}>
              <NavRight>
                <Link popupClose iconF7="xmark"></Link>
              </NavRight>
            </Navbar>
            <form></form>
          </Page>
        </View>
      )

      var block = (
        <Block>
          <List noHairlinesMd>
            <ListInput
              outline
              label="部門ID"
              type="text"
              name="id"
              defaultValue={department.id}
              disabled
            ></ListInput>
            <ListInput
              outline
              label="部門名"
              type="textarea"
              name="name"
              defaultValue={department.name}
              placeholder="部門名"
              maxlength="200"
            ></ListInput>      
            <ListInput
              outline
              label="備考"
              type="textarea"
              name="remark"
              defaultValue={department.remark}
              placeholder="備考"
              maxlength="200"
            ></ListInput>     
            <ListInput
              outline
              label="削除フラグ"
              type="select"
              name="del_flg"
              defaultValue={department.del_flg}
              placeholder="Please choose..."
            >
              <option value="0">有効</option>
              <option value="1">削除</option>
            </ListInput>
          </List>
          <p>{popupElements.message}</p>
          <Row>
            <Col>
              <Button onClick={popupClose} fill>保存</Button>
            </Col>
          </Row>
        </Block>
      )
      ReactDOM.render(view, document.getElementById("haccp-popup"))
      var form = $$("#haccp-popup form")
      ReactDOM.render(block, form[0])
      return null
    }
  }

  return (
    <Page onPageBeforeRemove={onPageBeforeRemove} onPageInit={onPageInit} >
      <Fab position="right-top" slot="fixed" color="teal">
        <Button fill popupOpen="#haccp-popup" onClick={popupOpen({
          title: "部門マスタを登録する",
          message: "部門の新規登録を完了します。",
        }, {
          id: "",
          name: "",
          remark: "",
          del_flg: 0,
        })}>
          <Icon ios="f7:plus_square_on_square" aurora="f7:fiber_new" md="material:fiber_new" />
        </Button>
      </Fab>
      <Navbar className="mst">
        <NavLeft>
          <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" />
        </NavLeft>
        <NavTitle style = {{fontSize: "20px"}}>部門マスタ</NavTitle>
        {/* <NavRight>
          <Link href="/home/" view=".view-main" panelClose iconF7="house"></Link>
        </NavRight> */}
      </Navbar>
      <BlockTitle>一覧</BlockTitle>
      <Block id="departmentMstList" className="mst-list"></Block>
    </Page>
  );
};
export default DepartmentMst;