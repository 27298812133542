import React,{ useRef } from 'react';
import {
  Page,
  Navbar,
  NavLeft,
  NavRight,
  NavTitle,
  Block,
  BlockTitle,
  List,
  ListItem,
  f7,
  Row,
  Col,
  AccordionContent, 
  Button,
  ListInput,
  Icon,
  Fab,
  Link,
  Treeview,
  TreeviewItem,
  View,
} from 'framework7-react';

import Dom7 from 'dom7';
import ReactDOM from 'react-dom';
import haccpUtils from '../js/haccp-utils.js';

import '../css/sortable.css';
import '../css/grid.css'


const DefaultImportantTemplate = () => {
  var $$ = Dom7
  const storage = haccpUtils.getStorage()
  const storage_key = "#USER#default-important-template"

  const actions = useRef(null);
  const onPageBeforeRemove = () => {
    actions.current.destroy();
  };
  const onPageInit = () => {
    actions.current = f7.actions.create({});
    haccpUtils.request(f7, {
      url: '/default_important_temp',
      method: "GET",
      contentType: "application/json",
      crossDomain: true,
      headers: {Authorization: "Bearer ..."},
      data: {},
      dataType: "json",
      success: function (body, status, xhr){
        console.log("success...");
        storage.setItem(storage_key, body.data.defaultImportantTemp)
      },
      error: function (xhr, status, message) {
        console.log("error...");
      },      
      complete: function (xhr, status) {
        console.log(xhr, status);
        renderList()
      },
      statusCode: {
        401: function(xhr) {
          console.log(xhr)
        }
      },      
    });
    actions.current = f7.actions.create({});
  };

// -----------------------------------------------------------------
// 今回は、baseDefaultImportantTemplateは、固定とする（未来に変動可能となった場合このマップは不要となり、画面にて動的に追加削除を可能とする予定）
// 本番までに設定ファイルを準備して転記を予定
  const baseDefaultImportantTemplate = [
    {ditd_id: 1, category_id: 1, menu_id : "13,14", check_method: "野菜を十分に洗浄してから提供する。調理後すぐに提供しない場合は冷蔵庫で保管しておくこと。生肉や生魚をさわった手で盛り付けないように注意する。\n\n仕込んだ当日のみ提供し、翌日は必ず破棄する。盛り付けるごとに冷蔵庫から取り出し、常温の所には放置しない。盛り付けの際は仕込んだ日付と合わせて、匂いや色を確認する。", disp_seq: 1},
    {ditd_id: 2, category_id: 2, menu_id : "15", check_method: "豚肉の厚さや一度に調理する量によって、火の強さや炒める時間を加減する。（１人前あたり２分を目安とする）肉汁や見た目に生の部分がないかを十分に確認する", disp_seq: 2},
    {ditd_id: 3, category_id: 3, menu_id : "8,16", check_method: "触感や匂い、色が黄ばんでいないかを確認する。\n\n匂い、見た目を確認する。薬味ネギを入れる際は傷んでいないか、匂いや色を確認する。", disp_seq: 3},
    {ditd_id: 4, category_id: 4, menu_id : "12", check_method: "調理が終わったら速やかに冷却、キレイに洗った小鉢に入れ、個々にラップがけをして冷蔵庫で保存する。２日を超えたものは提供してはいけない。提供する前に匂い、色を確認する。", disp_seq: 4},
    {ditd_id: 5, category_id: 5, menu_id : "11", check_method: "調理が終わったら速やかに冷却、盛り付ける時に冷蔵庫から取り出し常温のところに放置しない。盛り付けの際に清潔", disp_seq: 5},
  ]
// -----------------------------------------------------------------

  const renderList = () => {
    const defaultImportantTemp = storage.getItemObject(storage_key)
    console.log(defaultImportantTemp)
    const block_data = (
      <Block className="no-padding">
        <List>
          {defaultImportantTemp.map(impTemp =>
            <ListItem
              link="#"
              header={impTemp.u_last_nm + impTemp.u_first_nm + ">" + impTemp.create_dttm}
              title={"テンプレVer " + impTemp.id}
              onClick={popupOpenEdit({id: impTemp.id, details: impTemp.details})}
              popupOpen="#haccp-popup"
              iconF7="doc_text"
              after="詳細"
            >
            </ListItem>
          )}
        </List>
      </Block>
    )

    haccpUtils.lazyRender("#pageデフォルト重要テンプレ.page-current form", block_data)
  }

  const storeList = (storeData) => {
    haccpUtils.request(f7, {
      url: '/default_important_temp',
      method: "POST",
      contentType: "application/json",
      crossDomain: true,
      headers: {Authorization: "Bearer ..."},
      data: storeData, // TODO 変更中 (変更済) OK
      dataType: "json",
      success: function (body, status, xhr){
        console.log("success...");
        storage.setItem(storage_key, body.data.defaultImportantTemp)
      },
      error: function (xhr, status, message) {
        console.log("error...");
      },      
      complete: function (xhr, status) {
        console.log(xhr, status);
        renderList()
      },
      statusCode: {
        401: function(xhr) {
          console.log(xhr)
        }
      },
    });
  }

  const popupUpdate = (params) => {
    return () => {
      var storedData = haccpUtils.convertFormToData(f7, '#haccp-popup form');
      console.log("storedData")
      console.log(storedData)
      if (storedData) {
        var children = storage.getItemObject(storage_key)
        var databaseData = [{children: children}]
        var isNew = true

        databaseData[0].children.forEach((child, index) => {
          if(child.id == params.id) {
            isNew = false
            databaseData[0].children[index].details = storedData.details
          }
        });

        if(isNew) {
          databaseData[0].children.push(storedData)
        }
        storeList({"template": databaseData[0]})
        storage.setItem(storage_key, databaseData[0].children)

        f7.popup.close("#haccp-popup");
      } else {
        alert('Yet there is no stored data for this form. Please try to change any field')
      }
    }
  }

  const popupOpen = (popupElements, params) => {
    return () => {
      var popup = $$("#haccp-popup")
      const view = (
        <View>
          <Page>
            <Navbar title={popupElements.title}>
              <NavRight>
                <Link popupClose iconF7="xmark"></Link>
              </NavRight>
            </Navbar>
            <form></form>
          </Page>
        </View>
      )
      ReactDOM.render(view, popup[0])

      var form = $$("#haccp-popup form")

      console.log(params.details)
      const categoryOptions = storage.getItemObject("#USER#categoryOptions");
      const menuOptions = storage.getItemObject("#USER#menuOptions");
      var block = (
        <Block>
          <List sortable sortableOpposite>
            <input type="hidden" name="id" value={params.id} />
            {params.details.map((detail, idx)=>
              <Block>
                <input type="hidden" name={"details["+idx+"].ditd_id"} value={detail.ditd_id} />
                {/* disp_seqは要検討 */}
                <input type="hidden" name={"details["+idx+"].disp_seq"} value={detail.disp_seq} />
                <List noHairlinesMd>
                  <ListItem
                    title={"分類" + (idx+1)}
                    smartSelect
                    smartSelectParams={{
                      openIn: 'popup',
                      popupCloseLinkText: '❌',
                    }}
                  >
                    <select name={"details["+idx+"].category_id"} defaultValue={detail.category_id}>
                      {categoryOptions.map(option=>
                      <option value={option.value}>{option.text}</option>
                      )}
                    </select>
                  </ListItem>
                  <ListItem accordionItem title={"分類" + (idx+1) + "詳細"}>
                    <AccordionContent>
                      <ListItem
                        title="メニュー"
                        smartSelect
                        smartSelectParams={{
                          openIn: 'popup',
                          searchbar: true,
                          searchbarPlaceholder: '検索キー',
                          popupCloseLinkText: '❌',
                        }}
                      >
                        <select name={"details["+idx+"].menu_id"} multiple defaultValue={detail.menu_id.split(",")}>
                          {menuOptions.map(option=> 
                            <option value={option.value}>{option.text}</option>
                          )}
                        </select>
                      </ListItem>
                      <ListInput
                        outline
                        label="チェック方法"
                        type="textarea"
                        name={"details["+idx+"].check_method"}
                        placeholder=""
                        defaultValue={detail.check_method}
                        maxlength="400"
                        required
                      >
                      </ListInput>
                    </AccordionContent>
                  </ListItem>
                </List>
              </Block>
            )}
            <Block>
              <p>{popupElements.message}</p>
            </Block>
            <Row>
              <Col>
                <Button onClick={popupElements.submit()} fill>{popupElements.button}</Button>
                {/* <Button onClick={popupElements.submit(params)} fill>{popupElements.button}</Button> */}
              </Col>
            </Row>
          </List>
        </Block>
      )

      console.log(form[0])
      ReactDOM.render(block, form[0])

    }
  }

  const popupOpenEdit = (params) => {
    const popupElements = {
      title: "重要管理計画デフォルト共通テンプレートを編集する",
      message: "上記の内容で重要管理計画デフォルト共通テンプレートの修正を完了します。",
      button: "修正を完了する",
      submit: () => {
        return popupUpdate(params)
      }
    }
    return popupOpen(popupElements, params)
  }

  const popupOpenNew = (params) => {
    console.log("popupOpenNew")
    return popupOpen(
      {
        title: "重要管理計画デフォルト共通テンプレートを作成する",
        message: "上記の内容で重要管理計画デフォルト共通テンプレートを保存します。",
        button: "登録",
        submit: () => {
          return popupUpdate(params)
        },
      }, params
    )
  }

  return (
    <Page id="pageデフォルト重要テンプレ" onPageBeforeRemove={onPageBeforeRemove} onPageInit={onPageInit} >
      <Fab position="right-top" slot="fixed" color="teal">
        <Button fill popupOpen="#haccp-popup" onClick={popupOpenNew({id: "?", details: baseDefaultImportantTemplate})}>
          <Icon ios="f7:plus_square_on_square" aurora="f7:fiber_new" md="material:fiber_new" />
        </Button>
      </Fab>
      <Navbar className="important"> 
        <NavLeft>
          <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" />
        </NavLeft>
        <NavTitle style = {{fontSize: "20px"}}>ﾃﾞﾌｫﾙﾄ重要ﾃﾝﾌﾟﾚｰﾄ</NavTitle>
        <NavRight>
          <Link href="/home/" view=".view-main" panelClose iconF7="house" color="red"></Link>
        </NavRight>
      </Navbar>
      <BlockTitle>一覧</BlockTitle>
      <form></form>
    </Page>
  );
};
export default DefaultImportantTemplate;
