
import HomePage from '../pages/home.jsx';
import AboutPage from '../pages/about.jsx';
import FormPage from '../pages/form.jsx';

import LeftPage1 from '../pages/left-page-1.jsx';
import LeftPage2 from '../pages/left-page-2.jsx';
import DynamicRoutePage from '../pages/dynamic-route.jsx';
import RequestAndLoad from '../pages/request-and-load.jsx';
import NotFoundPage from '../pages/404.jsx';

// 一般衛生管理計画
import GeneralHygienicCtrlPlan from '../pages/general-hygienic-ctrl-plan.jsx';
// 重要衛生管理計画
import ImportantHygienicCtrlPlan from '../pages/important-hygienic-ctrl-plan.jsx';
// 一般衛生管理記録
import GeneralHygienicCtrl from '../pages/general-hygienic-ctrl.jsx';
// 重要衛生管理記録
import ImportantHygienicCtrl from '../pages/important-hygienic-ctrl.jsx';
// 一般衛生管理記録一覧
import GeneralHygienicCtrlList from '../pages/general-hygienic-ctrl-list.jsx';
// 重要衛生管理記録一覧
import ImportantHygienicCtrlList from '../pages/important-hygienic-ctrl-list.jsx';
// 一般衛生管理記録カレンダー表示
import GeneralHygienicCtrlCalendar from '../pages/general-hygienic-ctrl-calendar.jsx';
// 重要衛生管理記録カレンダー表示
import ImportantHygienicCtrlCalendar from '../pages/important-hygienic-ctrl-calendar.jsx';
// デフォルト一般テンプレート
import DefaultGeneralTemplate from '../pages/default-general-template.jsx';
// デフォルト重要テンプレート
import DefaultImportantTemplate from '../pages/default-important-template.jsx';
// 一般管理計画テンプレ一覧
import GeneralCtrlPlanTemplateList from '../pages/general-ctrl-plan-template-list.jsx';
// 重要管理計画テンプレ一覧
import ImportantCtrlPlanTemplateList from '../pages/important-ctrl-plan-template-list.jsx';
// 項目マスタ
import ItemMst from '../pages/item-mst.jsx';
// 時間要素マスタ
import TimeElementMst from '../pages/time-element-mst.jsx';
// 分類マスタ
import CategoryMst from '../pages/category-mst.jsx';
// メニューマスタ
import MenuMst from '../pages/menu-mst.jsx';
// 店舗マスタ
import ShopMst from '../pages/shop-mst.jsx';
// 部門マスタ
import DepartmentMst from '../pages/department-mst.jsx';
// ユーザマスタ
import UserMst from '../pages/user-mst.jsx';
// アカウントマスタ
import AccountMst from '../pages/account-mst.jsx';
// CSVダウンロード
import CsvDownload from '../pages/csv-download.jsx';
// ユーザマスタメンテナンス
import UserMstMaintenance from '../pages/user-mst-maintenance.jsx';
// 店舗マスタメンテナンス
import ShopMstMaintenance from '../pages/shop-mst-maintenance.jsx';
// 登録ユーザ数
import UserCountHistory from '../pages/user-count-history.jsx';
// 記録履歴
import RecordHistory from '../pages/record-history.jsx';
// トップページ
import TopPage from '../pages/top-page.jsx';
// ホーム
import Home from '../pages/home.jsx';
// お知らせ
import Information from '../pages/information.jsx';
// トップページ情報
import TopPageInformation from '../pages/top-page-information.jsx';
// 本日一般管理記録
import TodayGeneralCtrl from '../pages/today-general-ctrl.jsx';
// 本日重要管理記録
import TodayImportantCtrl from '../pages/today-important-ctrl.jsx';
// 本日一般管理記録
import NewGeneralCtrl from '../pages/new-general-ctrl.jsx';
// 一般管理記録データクリア
import DataClearGeneralCtrl from '../pages/data-clear-general-ctrl.jsx';
// 重要管理記録データクリア
import DataClearImportantCtrl from '../pages/data-clear-important-ctrl.jsx';
// マイページ
import myPage from '../pages/my-page.jsx';
// 利用規約
import termsService from '../pages/terms-service.jsx';

var routes = [
  {
    name: 'home',
    path: '/',
    component: HomePage,
  },
  {
    path: '/about/',
    component: AboutPage,
  },
  {
    path: '/form/',
    component: FormPage,
  },

  {
    path: '/left-page-1/',
    component: LeftPage1,
  },
  {
    path: '/left-page-2/',
    component: LeftPage2,
  },
  {
    path: '/dynamic-route/blog/:blogId/post/:postId/',
    component: DynamicRoutePage,
  },
  // 一般衛生管理計画
  {
    path: '/general-hygienic-ctrl-plan/',
    component: GeneralHygienicCtrlPlan,
  },
  // 重要衛生管理計画
  {
    path: '/important-hygienic-ctrl-plan/',
    component: ImportantHygienicCtrlPlan,
  },
  // 一般衛生管理記録
  {
    name: "GeneralHygienicCtrl",
    path: '/general-hygienic-ctrl/',
    component: GeneralHygienicCtrl,
  },
  // 重要衛生管理記録
  {
    name: "ImportantHygienicCtrl",
    path: '/important-hygienic-ctrl/',
    component: ImportantHygienicCtrl,
  },
  // 一般衛生管理記録一覧
  {
    name: "GeneralHygienicCtrlList",
    path: '/general-hygienic-ctrl-list/',
    component: GeneralHygienicCtrlList,
  },
  // 重要衛生管理記録一覧
  {
    name: "ImportantHygienicCtrlList",
    path: '/important-hygienic-ctrl-list/',
    component: ImportantHygienicCtrlList,
  },
  // 一般衛生管理記録カレンダー表示
  {
    path: '/general-hygienic-ctrl-calendar/',
    component: GeneralHygienicCtrlCalendar,
  },
  // 重要衛生管理記録カレンダー表示
  {
    path: '/important-hygienic-ctrl-calendar/',
    component: ImportantHygienicCtrlCalendar,
  },
  // デフォルト一般テンプレート
  {
    path: '/default-general-template/',
    component: DefaultGeneralTemplate,
  },
  // デフォルト重要テンプレート
  {
    path: '/default-important-template/',
    component: DefaultImportantTemplate,
  },
  // 一般管理計画テンプレ一覧
  {
    path: '/general-ctrl-plan-template-list/',
    component: GeneralCtrlPlanTemplateList,
  },
  // 重要管理計画テンプレ一覧
  {
    path: '/important-ctrl-plan-template-list/',
    component: ImportantCtrlPlanTemplateList,
  },
  // 項目マスタ
  {
    path: '/item-mst/',
    component: ItemMst,
  },
  // 時間要素マスタ
  {
    path: '/time-element-mst/',
    component: TimeElementMst,
  },
  // 分類マスタ
  {
    path: '/category-mst/',
    component: CategoryMst,
  },
  // メニューマスタ
  {
    path: '/menu-mst/',
    component: MenuMst,
  },
  // 店舗マスタ
  {
    path: '/shop-mst/',
    component: ShopMst,
  },
  // 部門マスタ
  {
    path: '/department-mst/',
    component: DepartmentMst,
  },
  // 登録ユーザ数
  {
    path: '/user-count-history/',
    component: UserCountHistory,
  },
  // 記録履歴
  {
    path: '/record-history/',
    component: RecordHistory,
  },
  // ユーザマスタ
  {
    path: '/user-mst/',
    component: UserMst,
  },
  // アカウントマスタ
  {
    path: '/account-mst/',
    component: AccountMst,
  },
  // CSVダウンロード
  {
    path: '/csv-download/',
    component: CsvDownload,
  },
  // ユーザマスタメンテナンス
  {
    path: '/user-mst-maintenance/',
    component: UserMstMaintenance,
  },
  // 店舗マスタメンテナンス
  {
    path: '/shop-mst-maintenance/',
    component: ShopMstMaintenance,
  },
  // トップページ
  {
    name: "TopPage",
    path: '/top-page/',
    component: TopPage,
  },
  // ホーム
  {
    name: "Home",
    path: '/home/',
    component: Home,
  },
  // トップページ
  {
    name: "Information",
    path: '/information/',
    component: Information,
  },

  // トップページ情報
  {
    name: "TopPageInformation",
    path: '/top-page-information/',
    component: TopPageInformation,
  },
  // 当日一般管理記録
  {
    name: "TodayGeneralCtrl",
    path: '/today-general-ctrl/',
    component: TodayGeneralCtrl,
  },
  // 当日重要管理記録
  {
    name: "TodayImportantCtrl",
    path: '/today-important-ctrl/',
    component: TodayImportantCtrl,
  },

  // 一般管理記録データクリア
  {
    name: "DataClearGeneralCtrl",
    path: '/data-clear-general-ctrl/',
    component: DataClearGeneralCtrl,
  },
  // 重要管理記録データクリア
  {
    name: "DataClearImportantCtrl",
    path: '/data-clear-important-ctrl/',
    component: DataClearImportantCtrl,
  },

  // 指定日の一般管理記録
  {
    name: "CalendarGeneralCtrl",
    path: '/calendar-general-ctrl/:targetDate',
    component: TodayGeneralCtrl,
  },
  // 指定日の重要管理記録
  {
    name: "CalendarImportantCtrl",
    path: '/calendar-important-ctrl/:targetDate',
    component: TodayImportantCtrl,
  },
  // 指定日の一般管理記録(縦スクロールバージョン)
  {
    name: "NewGeneralCtrl",
    path: '/new-general-ctrl/:targetDate',
    component: NewGeneralCtrl,
  },
  // マイページ
  {
    path: '/my-page/',
    component: myPage,
  },
  // 利用規約
  {
    path: '/terms-service/',
    component: termsService,
  },


  {
    path: '/request-and-load/user/:userId/',
    async: function ({ router, to, resolve }) {
      // App instance
      var app = router.app;

      // Show Preloader
      app.preloader.show();

      // User ID from request
      var userId = to.params.userId;

      // Simulate Ajax Request
      setTimeout(function () {
        // We got user data from request
        var user = {
          firstName: 'Vladimir',
          lastName: 'Kharlampidi',
          about: 'Hello, i am creator of Framework7! Hope you like it!',
          links: [
            {
              title: 'Framework7 Website',
              url: 'http://framework7.io',
            },
            {
              title: 'Framework7 Forum',
              url: 'http://forum.framework7.io',
            },
          ]
        };
        // Hide Preloader
        app.preloader.hide();

        // Resolve route to load page
        resolve(
          {
            component: RequestAndLoad,
          },
          {
            props: {
              user: user,
            }
          }
        );
      }, 1000);
    },
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
