import React,{ useRef } from 'react';
import {
  Page,
  Navbar,
  NavLeft,
  NavTitle,
  NavTitleLarge,
  NavRight,
  Link,
  Toolbar,
  Block,
  BlockTitle,
  f7,
} from 'framework7-react';

import Dom7 from 'dom7';
import ReactDOM from 'react-dom';
import '../css/sortable.css';
import '../css/grid.css'
import haccpUtils from '../js/haccp-utils.js';

const TopPage = () => {
  var $$ = Dom7
  var account = haccpUtils.getLoginAccount()
  var name = "ゲスト"
  if(account && account.user) name = account.user.last_name

  const storage = haccpUtils.getStorage();
  const actions = useRef(null);

  const storage_key = "#USER#top-page-information"
  const onPageBeforeRemove = () => {
    actions.current.destroy();
  };
  const onPageInit = () => {
    actions.current = f7.actions.create({});
    haccpUtils.request(f7, {
      url: '/top_page_information',
      method: "GET",
      contentType: "application/json",
      crossDomain: true,
      headers: {Authorization: "Bearer ..."},
      data: {},
      dataType: "json",
      success: function (body, status, xhr){
        renderInit(body.data.topPageInformation)
        storage.setItem(storage_key, body.data.topPageInformation)
      },
      error: function (xhr, status, message) {
        console.log("error...");
      },      
      complete: function (xhr, status) {
        console.log(xhr, status);
      },
      statusCode: {
        401: function(xhr) {
          console.log(xhr)
        }
      },
    });

    actions.current = f7.actions.create({});
  };

  const renderInit = (topPageInformation) => {
    const block_data = (
      <Block strong className="no-padding">
          {topPageInformation.map(information => 
            <div class="resizeimage">
              <input type="hidden" value="top-page"/>
              <input type="hidden" name="id" value={information.id} />
              <input type="hidden" name="remark" value={information.remark} />

              <p>{information.message1}</p>
              <p>{information.message2}</p>
              <p>{information.message3}</p>
              <BlockTitle></BlockTitle>
              {(() => {
                if (!information.banner1) {
                  return (
                    <a></a>
                  )
                } else {
                  if (!information.banner_link1) {
                    return (
                      <a><img src={information.banner1} alt="" border="0"/></a>
                    )
                  } else {
                    return (
                      <a class="link external" href={information.banner_link1} target="_blank"><img src={information.banner1} alt="" border="0"/></a>
                    )
                  }
                }
              })()}
              {(() => {
                if (!information.banner2) {
                  return (
                    <a></a>
                  )
                } else {
                  if (!information.banner_link2) {
                    return (
                      <a><img src={information.banner2} alt="" border="0"/></a>
                    )
                  } else {
                    return (
                      <a class="link external" href={information.banner_link2} target="_blank"><img src={information.banner2} alt="" border="0"/></a>
                    )
                  }
                }
              })()}
              {(() => {
                if (!information.banner3) {
                  return (
                    <a></a>
                  )
                } else {
                  if (!information.banner_link3) {
                    return (
                      <a><img src={information.banner3} alt="" border="0"/></a>
                    )
                  } else {
                    return (
                      <a class="link external" href={information.banner_link3} target="_blank"><img src={information.banner3} alt="" border="0"/></a>
                    )
                  }
                }
              })()}
              {(() => {
                if (!information.banner4) {
                  return (
                    <a></a>
                  )
                } else {
                  if (!information.banner_link4) {
                    return (
                      <a><img src={information.banner4} alt="" border="0"/></a>
                    )
                  } else {
                    return (
                      <a class="link external" href={information.banner_link4} target="_blank"><img src={information.banner4} alt="" border="0"/></a>
                    )
                  }
                }
              })()}
              {(() => {
                if (!information.banner5) {
                  return (
                    <a></a>
                  )
                } else {
                  if (!information.banner_link5) {
                    return (
                      <a><img src={information.banner5} alt="" border="0"/></a>
                    )
                  } else {
                    return (
                      <a class="link external" href={information.banner_link5} target="_blank"><img src={information.banner5} alt="" border="0"/></a>
                    )
                  }
                }
              })()}
            </div>
          )}
      </Block>
    )
    var block = $$("#topPageInformation")
    ReactDOM.render(block_data, block[0])
  }

  return (
    <Page onPageBeforeRemove={onPageBeforeRemove} onPageReinit={onPageInit} onPageInit={onPageInit} >
    {/* <Page name="topPage"> */}
      {/* Top Navbar */}
      <Navbar large sliding={false}>
        <NavLeft>
          <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" />
        </NavLeft>
        <NavTitle sliding>簡単ＨＡＣＣＰ</NavTitle>
        <NavRight>
          <Link className="profile_button"
              style={{
                backgroundColor: "lightblue",
                padding: 0,
                borderRadius: "50px",
                borderWidth: 1,
                borderColor: "lightblue",
                overflow: "hidden",
              }}
              href="/my-page/"
            >
              <div style={{
                color: "blue",
                width: "100%",
                textAlign: "center",
                fontSize: "80%",
              }}>{name}</div>
          </Link>
        </NavRight>
        <NavTitleLarge style = {{textAlign: "center"}}>簡単ＨＡＣＣＰ</NavTitleLarge>
      </Navbar>
      {/* Toolbar */}
      <Toolbar bottom>
        {/* <Link>Left Link</Link>
        <Link>Right Link</Link> */}
      </Toolbar>
      {/* Page content */}
      {/* <BlockTitle>メニュー</BlockTitle>
      <List>
        <ListItem link="/Today-General-Ctrl/" title="一般衛生管理実施記録"/>
        <ListItem link="/Today-Important-Ctrl/" title="重要衛生管理実施記録"/>
        <ListItem link="/General-Hygienic-Ctrl-Plan/" title="一般衛生管理計画の作成・修正"/>
        <ListItem link="/Important-Hygienic-Ctrl-Plan/" title="重要衛生管理計画の作成・修正"/>
      </List> */}
      <Block id="topPageInformation" className="mst-list"></Block>

    </Page>
  );

};
export default TopPage;